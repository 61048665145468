import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=65ac799f&"
import script from "./ChangePassword.vue?vue&type=script&lang=js&"
export * from "./ChangePassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CInput: require('@chakra-ui/vue').CInput, CFlex: require('@chakra-ui/vue').CFlex, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal, CBox: require('@chakra-ui/vue').CBox})
