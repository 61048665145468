<template>
  <swipe-modal v-model="showModal">
    <template v-slot:header>
      <div
        :style="{
          background: colorMode === 'dark' ? 'black' : 'white',
        }"
      >
        <c-heading
          p="15px"
          class="heading"
          mb="0 !important"
          :style="{ color: colorMode === 'dark' ? 'white' : 'black' }"
        >
          View Options
        </c-heading>
      </div>
    </template>
    <template v-slot:body>
      <c-box :style="{ background: colorMode === 'dark' ? 'black' : 'white' }">
        <c-flex
          direction="column"
          pb="10vw"
          px="4vw"
          :style="{ background: colorMode === 'dark' ? 'black' : 'white' }"
        >
          <c-flex direction="column" v-if="verse !== 'all'" pt="7.5vw">
            <c-flex align="center">
              <c-text class="filter-label">View Options</c-text>
              <c-icon name="chevron-down" size="5vw" color="black" />
            </c-flex>
            <dropdown-menu
              :withDropdownCloser="true"
              :class="[
                colorMode == 'dark' ? 'box-dark' : '',
                'filter-container custom-select',
              ]"
              @closed="isOpenViewOptions = false"
              @opened="isOpenViewOptions = true"
            >
              <button slot="trigger" style="text-transform: capitalize">
                {{ view }}
              </button>
              <ul slot="body" v-scroll-lock="isOpenViewOptions">
                <li
                  v-for="item in ['paragraph', 'parallel', 'full chapter']"
                  :key="item"
                  :class="view === item ? 'golden-bg' : ''"
                >
                  <c-box
                    dropdown-closer
                    class="filter-value"
                    @click="setView(item)"
                    style="text-transform: capitalize"
                  >
                    {{ item }}
                  </c-box>
                </li>
              </ul>
            </dropdown-menu>
          </c-flex>
          <c-flex direction="column" pt="7.5vw">
            <c-flex align="center">
              <c-text class="filter-label">Font Size</c-text>
              <c-icon name="chevron-down" size="5vw" color="black" />
            </c-flex>

            <dropdown-menu
              :withDropdownCloser="true"
              :class="[
                colorMode == 'dark' ? 'box-dark' : '',
                'filter-container custom-select',
              ]"
              @closed="isOpenFontSizeOptions = false"
              @opened="isOpenFontSizeOptions = true"
            >
              <button slot="trigger">
                {{ fontSize }}
              </button>
              <ul slot="body" v-scroll-lock="isOpenFontSizeOptions">
                <li
                  v-for="(item, index) in fontSizes"
                  :key="`${item.value}-${index}`"
                  :class="fontSize === item.value ? 'golden-bg' : ''"
                >
                  <c-box
                    dropdown-closer
                    class="filter-value"
                    @click="setFontSize(item.value)"
                  >
                    {{ item.value }}
                  </c-box>
                </li>
              </ul>
            </dropdown-menu>
          </c-flex>
          <c-flex direction="column" pt="7.5vw">
            <c-flex align="center">
              <c-text class="filter-label">Language</c-text>
              <c-icon name="chevron-down" size="5vw" color="black" />
            </c-flex>
            <dropdown-menu
              :withDropdownCloser="true"
              :class="[
                colorMode == 'dark' ? 'box-dark' : '',
                'filter-container custom-select',
              ]"
              :dropup="true"
              @closed="isOpenLanguageOptions = false"
              @opened="isOpenLanguageOptions = true"
            >
              <button slot="trigger" style="text-transform: uppercase">
                {{ language }}
              </button>
              <ul slot="body" v-scroll-lock="isOpenLanguageOptions">
                <li
                  v-for="(i, index) in Object.keys(languages)"
                  :key="`${i}-${index}`"
                  :class="language === i ? 'golden-bg' : ''"
                >
                  <c-box
                    style="text-transform: uppercase"
                    dropdown-closer
                    class="filter-value"
                    @click="setLanguage(i)"
                  >
                    {{ i }}
                  </c-box>
                </li>
              </ul>
            </dropdown-menu>
          </c-flex>
        </c-flex>
      </c-box>
    </template>
    <template v-slot:footer>
      <c-flex
        h="17.5vw"
        p="3.5vw"
        :style="{
          background: colorMode === 'dark' ? 'black' : 'white',
          borderTop: '1px solid #cdcdcd',
        }"
      >
        <custom-button text="Close" :click="close" />
      </c-flex>
    </template>
  </swipe-modal>
</template>

<script>
import SwipeModal from "swipe-modal-2";
import CustomButton from "../../CustomComponents/CustomButton.vue";
import ShareDownloadBookmark from "../../CustomComponents/ShareDownloadBookmark.vue";

export default {
  name: "ViewOptions",
  props: [
    "languages",
    "language",
    "fontSizes",
    "fontSize",
    "view",
    "verse",
    "show",
    "close",
    "colorMode",
    "setFontSize",
    "chapter",
    "setLanguage",
    "setView",
  ],
  data: function () {
    return {
      isOpenViewOptions: false,
      isOpenLanguageOptions: false,
      isOpenFontSizeOptions: false,
    };
  },

  components: { SwipeModal, CustomButton, ShareDownloadBookmark },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(newShow) {
        newShow === "closed" && this.close();
        return newShow;
      },
    },
    font: {
      get() {
        return this.fontSize;
      },
      set(newSize) {
        this.setFontSize(newSize);
      },
    },
  },
};
</script>
