var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',{attrs:{"w":"100%","bg":"palettes.gold","id":"footer-container"}},[_c('mq-layout',{staticStyle:{"width":"100%"},attrs:{"mq":"desktop"}},[_c('c-flex',{attrs:{"bg":"palettes.gold","color":"white","align":"center","p":"3vw","w":"100%"}},[_c('c-flex',{attrs:{"direction":"column","w":"75%"}},[_c('c-heading',{attrs:{"fontWeight":"bold","fontSize":"3vw"}},[_vm._v("Logo")]),_c('c-text',{attrs:{"my":"2"}},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum lorem pretium convallis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum lorem pretium convallis. ")])],1),_c('c-flex',{attrs:{"direction":"column","justify":"center","px":"4vw","w":"25%"}},[_c('c-text',{staticClass:"footer-link-heading"},[_vm._v("Quick Links")]),_vm._l(([
            { title: 'Home', route: '/', show: true },
            {
              title: 'Read Quran',
              route: ("/chapters/" + (_vm.chapterId || '1') + "/verse/" + _vm.savedGroupId),
              show: true,
            },
            { title: 'Media', route: '/media', show: true },
            { title: 'Profile', route: '/profile', show: _vm.isUserLoggedIn } ]),function(i,index){return _c('c-link',{key:index,class:("footer-link " + (_vm.currentRouteName.includes(i.title) ? 'active-link' : '')),staticStyle:{"width":"max-content","padding-top":"0 !important"},attrs:{"as":"router-link","to":i.route}},[(i.show)?_c('span',[_vm._v(" "+_vm._s(i.title))]):_vm._e()])})],2)],1)],1),_c('mq-layout',{attrs:{"mq":"mobile"}},[_c('c-flex',{attrs:{"direction":"column","bg":"palettes.gold","color":"white","align":"center","justify":"space-evenly","bottom":"0","p":"3vw"}},[_c('c-flex',{attrs:{"direction":"column","align":"center"}},[_c('c-flex',{attrs:{"direction":"column","align":"center"}},[_c('c-heading',{attrs:{"fontWeight":"bold","fontSize":"5vw"}},[_vm._v("Logo")]),_c('c-text',{attrs:{"fontSize":"2.5vw","textAlign":"center","my":"5vw"}},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum lorem pretium convallis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum lorem pretium convallis. ")])],1),_c('c-simple-grid',{attrs:{"columns":1}},[_c('c-flex',{attrs:{"direction":"column","justify":"center"}},[_c('c-text',{staticClass:"footer-link-heading"},[_vm._v("Quick Links")]),_vm._l(([
                { title: 'Home', route: '/', show: true },
                {
                  title: 'Read Quran',
                  route: ("/chapters/" + (_vm.chapterId || '1') + "/verse/" + _vm.savedGroupId),
                  show: true,
                },
                { title: 'Media', route: '/media', show: true },
                { title: 'Profile', route: '/profile', show: _vm.isUserLoggedIn } ]),function(i,index){return _c('c-link',{key:index,class:("footer-link " + (_vm.currentRouteName.includes(i.title) ? 'active-link' : '')),staticStyle:{"width":"max-content","padding-top":"0 !important","margin-left":"auto","margin-right":"auto"},attrs:{"as":"router-link","to":i.route}},[(i.show)?_c('span',[_vm._v(" "+_vm._s(i.title))]):_vm._e()])})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }