var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swipe-modal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({
        background: _vm.colorMode === 'dark' ? 'black' : 'white',
      })},[_c('c-heading',{staticClass:"heading",style:({ color: _vm.colorMode === 'dark' ? 'white' : 'black' }),attrs:{"p":"15px","mb":"0 !important"}},[_vm._v(" View Options ")])],1)]},proxy:true},{key:"body",fn:function(){return [_c('c-box',{style:({ background: _vm.colorMode === 'dark' ? 'black' : 'white' })},[_c('c-flex',{style:({ background: _vm.colorMode === 'dark' ? 'black' : 'white' }),attrs:{"direction":"column","pb":"10vw","px":"4vw"}},[(_vm.verse !== 'all')?_c('c-flex',{attrs:{"direction":"column","pt":"7.5vw"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("View Options")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"5vw","color":"black"}})],1),_c('dropdown-menu',{class:[
              _vm.colorMode == 'dark' ? 'box-dark' : '',
              'filter-container custom-select' ],attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.isOpenViewOptions = false},"opened":function($event){_vm.isOpenViewOptions = true}}},[_c('button',{staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.view)+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isOpenViewOptions),expression:"isOpenViewOptions"}],attrs:{"slot":"body"},slot:"body"},_vm._l((['paragraph', 'parallel', 'full chapter']),function(item){return _c('li',{key:item,class:_vm.view === item ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",staticStyle:{"text-transform":"capitalize"},attrs:{"dropdown-closer":""},on:{"click":function($event){return _vm.setView(item)}}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),0)])],1):_vm._e(),_c('c-flex',{attrs:{"direction":"column","pt":"7.5vw"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Font Size")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"5vw","color":"black"}})],1),_c('dropdown-menu',{class:[
              _vm.colorMode == 'dark' ? 'box-dark' : '',
              'filter-container custom-select' ],attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.isOpenFontSizeOptions = false},"opened":function($event){_vm.isOpenFontSizeOptions = true}}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.fontSize)+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isOpenFontSizeOptions),expression:"isOpenFontSizeOptions"}],attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.fontSizes),function(item,index){return _c('li',{key:((item.value) + "-" + index),class:_vm.fontSize === item.value ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function($event){return _vm.setFontSize(item.value)}}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)}),0)])],1),_c('c-flex',{attrs:{"direction":"column","pt":"7.5vw"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Language")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"5vw","color":"black"}})],1),_c('dropdown-menu',{class:[
              _vm.colorMode == 'dark' ? 'box-dark' : '',
              'filter-container custom-select' ],attrs:{"withDropdownCloser":true,"dropup":true},on:{"closed":function($event){_vm.isOpenLanguageOptions = false},"opened":function($event){_vm.isOpenLanguageOptions = true}}},[_c('button',{staticStyle:{"text-transform":"uppercase"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.language)+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isOpenLanguageOptions),expression:"isOpenLanguageOptions"}],attrs:{"slot":"body"},slot:"body"},_vm._l((Object.keys(_vm.languages)),function(i,index){return _c('li',{key:(i + "-" + index),class:_vm.language === i ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",staticStyle:{"text-transform":"uppercase"},attrs:{"dropdown-closer":""},on:{"click":function($event){return _vm.setLanguage(i)}}},[_vm._v(" "+_vm._s(i)+" ")])],1)}),0)])],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('c-flex',{style:({
        background: _vm.colorMode === 'dark' ? 'black' : 'white',
        borderTop: '1px solid #cdcdcd',
      }),attrs:{"h":"17.5vw","p":"3.5vw"}},[_c('custom-button',{attrs:{"text":"Close","click":_vm.close}})],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})}
var staticRenderFns = []

export { render, staticRenderFns }