<template>
  <c-box zIndex="10000 !important">
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      :closeOnOverlayClick="false"
      zIndex="10000 !important"
      size="xl"
      is-centered
    >
      <c-modal-content>
        <c-modal-header>Change Password</c-modal-header>
        <c-modal-close-button />
        <c-modal-body mb="2vw" px="2vw">
          <c-input
            v-model="oldPassword"
            :placeholder="'Old Password*'"
            type="password"
            variant="flushed"
            class="auth-input"
            :is-invalid="!isOldPasswordValid"
            error-border-color="red.300"
          />
          <error
            :error="'Please enter a valid old password'"
            :show="!isOldPasswordValid"
          />
          <c-input
            v-model="password"
            :placeholder="'Password*'"
            type="password"
            variant="flushed"
            class="auth-input"
            :is-invalid="!isPasswordValid"
            error-border-color="red.300"
          />
          <error
            :error="'Please enter a valid new password'"
            :show="!isPasswordValid"
          />
          <c-input
            v-model="confirmPassword"
            :placeholder="'Confirm Password*'"
            type="password"
            variant="flushed"
            class="auth-input"
            :is-invalid="!isConfirmPasswordValid"
            error-border-color="red.300"
          />
          <error
            :error="'Please enter a re-enter the new password entered above'"
            :show="!isConfirmPasswordValid"
          />
          <c-flex w="max-content" m="auto" :pt="isMobile ? '10vw' : '2vw'">
            <custom-button
              text="Change"
              :click="handleChangePassword"
              :disabled="!isFormValid"
              :isLoading="changingPassword"
            />
          </c-flex>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>
<script>
import { CButton, CImage } from "@chakra-ui/vue";
import Error from "../Error.vue";
import CustomButton from "../CustomButton.vue";
import { changePassword } from "../../../apis";
import { showToast } from "../../../utils";
import { isMobile } from "mobile-device-detect";

export default {
  name: "ChangePassword",
  props: ["isOpen", "close"],
  data: function () {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
      changingPassword: false,
      isMobile,
    };
  },
  methods: {
    handleChangePassword() {
      this.changingPassword = true;
      changePassword(this.oldPassword, this.password)
        .then((result) => {
          console.log("Result here: ", result);
          this.close();
          showToast(this, "Change Password", "Password changed successfully");
          this.logout();
        })
        .catch((error) => {
          console.log("Error here: ", error);
        })
        .finally(() => (this.changingPassword = false));
    },
    logout() {
      this.$store.dispatch("logout");
      this.close();
    },
  },
  computed: {
    isOldPasswordValid() {
      return this.oldPassword.length > 0;
    },
    isPasswordValid() {
      return this.password.length > 0;
    },
    isConfirmPasswordValid() {
      return (
        this.confirmPassword.length > 0 &&
        this.confirmPassword === this.password
      );
    },
    isFormValid() {
      return (
        this.oldPassword && this.isPasswordValid && this.isConfirmPasswordValid
      );
    },
  },
  components: {
    CButton,
    CImage,
    Error,
    CustomButton,
  },
};
</script>
