<template>
  <c-flex w="100%" h="100%" justify="center" align="center">
    <c-spinner
      speed="0.65s"
      empty-color="white"
      color="palettes.gold"
      :thickness="thickness || '4px'"
      :size="size || 'xl'"
    />
  </c-flex>
</template>

<script>
import { CSpinner } from "@chakra-ui/vue";

export default {
  name: "Loading",
  props: ["size", "thickness"],
  components: {
    CSpinner,
  },
};
</script>
