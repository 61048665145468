<template>
  <c-flex
    direction="column"
    pb="0.5vw"
    :pr="pr || '0'"
    :pl="pl || '0'"
    w="100%"
  >
    <c-flex
      align="center"
      justify="space-between"
      :mt="isMobile ? '0.65vw' : '0.5vw'"
      :mb="isMobile ? '1.5vw' : '0.5vw'"
    >
      <c-text
        fontWeight="semibold"
        py="0.5vw"
        :direction="direction"
        v-if="isMobile && verse !== 'all'"
      >
        {{ verse !== "all" ? `Verse ${verse}` : "" }}
      </c-text>
      <c-text as="u" fontWeight="semibold" textTransform="uppercase">
        {{ this.languageLabel }}
      </c-text>
    </c-flex>
    <c-text
      v-if="!verseGroupList || verseGroupList.length === 0"
      :direction="direction"
      textAlign="justify"
      textJustify="inter-word"
      :lineHeight="`${
        Number(fontSize.substring(0, 2) + (isMobile ? 1 : 1)) *
        (isMobile ? 1.15 : 1.25)
      }%`"
    >
      <span
        v-for="(verse, index) in this.verses"
        :key="index + verse[key]"
        :direction="direction"
      >
        <sup
          v-if="direction !== 'rtl'"
          style="margin-right: 0.1vw"
          :style="{
            'font-size': '90%',
          }"
        >
          {{ verse.id }}
        </sup>
        <span
          :class="languageLabel"
          :style="{
            'font-size':
              languageKey === 'parallel_text' || languageLabel !== 'en'
                ? `${Number(fontSize.substring(0, 2)) + 7}px`
                : fontSize,
          }"
        >
          {{ verse[key] }}
        </span>
        <sup
          v-if="direction === 'rtl'"
          style="margin-left: 0.1vw"
          :style="{
            'font-size': '90%',
          }"
        >
          {{ verse.id }}
        </sup>
        <div
          v-if="(verseGroupList || []).includes(index)"
          style="margin-bottom: 1vw"
        />
      </span>
    </c-text>
    <c-box v-else>
      <c-box
        v-for="(verseGroup, index) in verseGroupList"
        :key="verseGroup"
        :mb="index === verseGroupList.length - 1 ? 0 : '45px'"
        style="position: relative"
        :class="
          mouseOver === index && verseCommentary
            ? `verse-group-content-hover`
            : ''
        "
        @mouseover="onMouseOver(index)"
        @mouseout="onMouseOut()"
        @click="onClick(index)"
      >
        <c-flex
          v-if="mouseOver === index && verseCommentary"
          position="absolute"
          right="0"
          color="white"
          background="linear-gradient(#348dcf, #27367b)"
          :top="isMobile ? '-5.5vw' : '-2vw'"
          py="5px"
          px="1vw"
          :fontSize="isMobile ? '75%' : '85%'"
          style="border-top-left-radius: 1vw; border-top-right-radius: 1vw"
          align="center"
          :h="isMobile ? '5.5vw' : '2vw'"
        >
          <c-image
            :src="require('@/assets/icons/commentary-hover.png')"
            :w="isMobile ? '2.75vw' : '1.25vw'"
            :h="isMobile ? '2.75vw' : '1.25vw'"
            :mr="isMobile ? '2vw' : '1vw'"
            objectFit="cover"
          />
          Read Commentary
        </c-flex>
        <c-text
          textAlign="justify"
          :lineHeight="`${
            Number(fontSize.substring(0, 2) + (isMobile ? 1 : 1)) *
            (isMobile ? 1.15 : 1.25)
          }%`"
          :direction="direction"
          v-html="
            verses
              .slice(
                index === 0 ? 0 : verseGroupList[index - 1],
                verseGroupList[index]
              )
              .map(
                (el) =>
                  `<sup style='font-size:85%;margin-right:0.15vw;margin-left:0.15vw;'>${
                    el.id
                  }</sup><span class='${languageLabel}' style='font-size:${
                    languageLabel !== 'en'
                      ? `${Number(fontSize.substring(0, 2)) + 7}px`
                      : fontSize
                  }'> ${el.name}</span>`
              )
              .join(' ')
          "
        >
        </c-text>
      </c-box>
    </c-box>
    <chapter-commentary
      :close="hideCommentaryInFullChapter"
      :isOpen="isChapterCommentaryOpen"
      :commentary="verseCommentary"
      :lineHeight="`${
        Number(fontSize.substring(0, 2) + (isMobile ? 1 : 1)) *
        (isMobile ? 1.15 : 1.25)
      }%`"
    />
  </c-flex>
</template>

<script>
import CustomButton from "@/components/CustomComponents/CustomButton.vue";
import ChapterCommentary from "../Modals/ChapterCommentary.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "VerseContent",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  components: {
    CustomButton,
    ChapterCommentary,
    isMobile,
  },
  data() {
    return { mouseOver: undefined, isChapterCommentaryOpen: false, isMobile };
  },
  props: [
    "verses",
    "fontSize",
    "languageKey",
    "languageLabel",
    "verseGroupList",
    "pr",
    "pl",
    "direction",
    "commentary",
    "verse",
  ],
  computed: {
    key: function () {
      return this.languageKey;
    },
    verseCommentary() {
      const commentaryValues =
        this.commentary && Object.values(this.commentary);
      return commentaryValues ? commentaryValues[this.mouseOver] : undefined;
    },
  },
  methods: {
    showCommentaryInFullChapter() {
      this.isChapterCommentaryOpen = true;
    },
    hideCommentaryInFullChapter() {
      this.mouseOver >= 0 && (this.mouseOver = undefined);
      this.isChapterCommentaryOpen = false;
    },
    onMouseOut() {
      this.isChapterCommentaryOpen === false && (this.mouseOver = undefined);
    },
    onMouseOver(value) {
      !this.isMobile && (this.mouseOver = value);
    },
    onClick(index) {
      console.log("clicked: ", index);
      console.log("Mouse over: ", this.mouseOver);
      this.mouseOver > -1 && this.mouseOver === index
        ? this.showCommentaryInFullChapter()
        : (this.mouseOver = index);
    },
  },
};
</script>
