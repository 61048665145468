<template>
  <c-box v-if="media" zIndex="10000 !important">
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      :closeOnOverlayClick="false"
      zIndex="10000 !important"
      :size="fullScreen ? 'full' : 'xl'"
      is-centered
    >
      <c-modal-content
        class="audio-video-modal-content"
        p="0vw !important"
        :minHeight="fullScreen ? '100%' : 'max-content'"
        :m="fullScreen ? '0 !important' : ''"
        v-scroll-lock="isMobile && isOpen"
      >
        <c-modal-header>
          <c-flex justify="space-between">
            {{ media.title }}
            <c-box @click="goFullScreen">
              <c-image
                cursor="pointer"
                :src="
                  require(`@/assets/icons/${
                    fullScreen ? 'exit-fullscreen' : 'go-fullscreen'
                  }.svg`)
                "
                :mr="isMobile ? '8vw' : '3vw'"
              />
            </c-box>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-flex
            pb="1vw"
            :pt="isMobile ? '2vw' : ''"
            v-if="media.references && media.references.length > 0"
          >
            <c-text
              :fontSize="isMobile ? '3vw' : '1vw'"
              color="palettes.labelLightGrey"
            >
              <span
                v-for="(i, index) in media.references"
                :key="index"
                mr="1vw"
              >
                <c-link
                  :fontSize="isMobile ? '3vw' : '1vw'"
                  fontWeight="bold"
                  as="router-link"
                  mr="0.5vw"
                  color="blue !important"
                  :to="`/chapters/${i.chapter_id}/verse/${
                    i.group.split(' - ')[1]
                  }`"
                >
                  {{ i.chapter_id }}:{{ i.verse_no }}
                </c-link>
                {{
                  `${i.verse}${
                    index !== media.references.length - 1 ? ", " : ""
                  }`
                }}
              </span>
            </c-text>
          </c-flex>
          <c-text as="u" :my="isMobile ? '4vw' : '0'">
            {{ media.sub_title }}
          </c-text>
          <vue-plyr
            :style="{
              height: isMobile ? '55vw' : '35vw',
              width: isMobile ? '100%' : '90%',
              margin: 'auto',
            }"
          >
            <c-box
              class="plyr__video-embed"
              v-if="media.type.toLowerCase() === 'video'"
            >
              <iframe
                height="100%"
                width="100%"
                :src="video"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              />
            </c-box>
          </vue-plyr>
          <c-text v-html="media.description" :my="isMobile ? '4vw' : '0'" />
          <av-circle
            style="margin-top: 2vw"
            v-if="media.type.toLowerCase() === 'audio'"
            :outline-width="0"
            :progress-width="5"
            :outline-meter-space="5"
            :playtime="true"
            :cors-anonym="true"
            :audio-src="media.media_audio"
          />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<style>
.audio-video-modal-content > section {
  min-width: 90vw;
}

audio {
  width: 100%;
}
audio,
canvas {
  margin: auto;
}
.plyr__video-embed {
  height: 100% !important;
  padding: 0 !important;
}
</style>
<script>
import { CButton, CImage } from "@chakra-ui/vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AudioVideo",
  props: ["isOpen", "close", "media"],
  data() {
    return {
      video: this.media ? this.media.media_video : undefined,
      fullScreen: false,
      isMobile,
    };
  },
  components: {
    CButton,
    CImage,
  },
  watch: {
    media(newVal, oldVal) {
      console.log("New val here: ", newVal);
      if (this.media && this.media.type.toLowerCase() === "video") {
        this.video = this.media.media_video;
      }
    },
  },
  methods: {
    goFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
  },
};
</script>
