<template>
  <c-modal
    :is-open="isOpen"
    :on-close="!isDownloading && close"
    :closeOnOverlayClick="false"
    size="xl"
  >
    <c-modal-content>
      <c-modal-header>DOWNLOAD</c-modal-header>
      <c-modal-close-button />
      <c-modal-body>
        <c-text textAlign="center" :mt="isMobile ? '5vw' : '1vw'">
          Chapter: <strong> {{ chapterName.name }} </strong>
        </c-text>
        <c-text textAlign="center" :mt="isMobile ? '5vw' : '1vw'">
          Verse:
          <strong style="text-transform: capitalize">
            {{ verseGroup }}
          </strong>
        </c-text>
        <c-text textAlign="center" :mt="isMobile ? '5vw' : '1vw'">
          Language:
          <strong style="text-transform: uppercase">{{ language }}</strong>
        </c-text>
        <c-flex
          :pt="isMobile ? '7.5vw' : '2vw'"
          :pb="isMobile ? '5vw' : '1vw'"
          :w="isMobile ? '50%' : '35%'"
          m="auto"
        >
          <custom-button
            :height="isMobile ? '10vw' : '4vw'"
            text="Download"
            :click="handleDownload"
            :isLoading="isDownloading"
          />
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import { CButton, CImage } from "@chakra-ui/vue";
import { download } from "../../../apis";
import CustomButton from "../CustomButton.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Download",
  inject: ["$chakraColorMode"],
  props: [
    "isOpen",
    "close",
    "chapterName",
    "verseGroup",
    "language",
    "textDirection",
  ],
  data() {
    return { isDownloading: false, isMobile };
  },
  components: {
    CButton,
    CImage,
    CustomButton,
  },

  methods: {
    async handleDownload() {
      this.isDownloading = true;
      await download(
        this.chapterName.chapter_id,
        this.verseGroup === "all" ? "all" : this.verseGroup.split(" - ")[1],
        this.language,
        this.textDirection,
        window.location.href
      )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `QFY-${this.chapterName.chapter_id}.${this.chapterName.name}:${this.verseGroup}-${this.language}.pdf`;
          link.click();
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
