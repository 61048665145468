<template>
  <c-flex id="verse-quick-actions-container" w="100%">
    <c-box @click="goToPreviousVerse" class="previous">
      <c-image
        v-if="!isFirstChapter"
        :src="require(`@/assets/icons/right-double-arrow.svg`)"
        @click="goToPreviousVerse"
        :filter="iconColor"
      />
      <c-box>
        <c-text v-if="!isFirstChapter && !isMobile">
          {{
            isFirstVerseGroup || verse === "all"
              ? previousChapterName
              : previousVerseGroup.split(" - ")[0] ===
                previousVerseGroup.split(" - ")[1]
              ? previousVerseGroup.split(" - ")[0]
              : previousVerseGroup
          }}
        </c-text>
        <c-text v-if="!isFirstChapter">
          {{
            `Previous ${
              isFirstVerseGroup || verse === "all" ? "Chapter" : "Verse"
            }`
          }}
        </c-text>
      </c-box>
    </c-box>
    <!-- <c-flex
      h="100%"
      w="30%"
      align="center"
      justify="space-between"
      v-if="!isFirstVerseGroup"
    >
      <c-flex w="100%" h="100%">
        <button-outline
          text="Beginning Of Chapter"
          :click="this.goToBeginningOfSurah"
          :colorMode="this.colorMode"
        />
      </c-flex>
    </c-flex> -->
    <c-box display="flex" class="previous" @click="goToNextVerse">
      <c-box>
        <c-text v-if="!isLastChapter && !isMobile">
          {{
            isLastVerseGroup || verse === "all"
              ? nextChapterName
              : nextVerseGroup.split(" - ")[0] ===
                nextVerseGroup.split(" - ")[1]
              ? nextVerseGroup.split(" - ")[0]
              : nextVerseGroup
          }}
        </c-text>
        <c-text>
          {{
            `Next ${isLastVerseGroup || verse === "all" ? "Chapter" : "Verse"}`
          }}
        </c-text>
      </c-box>
      <c-image
        :src="require(`@/assets/icons/right-double-arrow.svg`)"
        :filter="iconColor"
      />
    </c-box>
  </c-flex>
</template>
<style>
.previous {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.previous > img {
  object-fit: contain;
  cursor: pointer;
}

.previous > div > p {
  font-weight: bold;
  text-align: center;
}
.previous > div > p:nth-child(1) {
  color: #c1a885;
}
.previous > div > p:nth-child(2) {
  color: #999999;
}
#verse-quick-actions-container {
  justify-content: space-between;
  align-items: center;
}
#verse-quick-actions-container > .previous:nth-child(1) > img {
  transform: rotate(180deg);
}
@media only screen and (max-width: 600px) {
  #verse-quick-actions-container {
    height: 10vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
  .previous {
    height: 75%;
  }
  #verse-quick-actions-container > .previous:nth-child(1) > div {
    margin-left: 2.5vw;
  }
  #verse-quick-actions-container > .previous:nth-child(2) > div {
    margin-right: 2.5vw;
  }
  .previous > div > p:nth-child(2) {
    font-size: 1.5vw;
  }
  .previous > div > p:nth-child(1) {
    font-size: 3vw;
  }
}

@media only screen and (min-width: 601px) {
  #verse-quick-actions-container {
    height: 4vw;
    margin-top: 0.5vw;
  }
  .previous {
    height: 50%;
  }
  #verse-quick-actions-container > .previous:nth-child(1) > div {
    margin-left: 1vw;
  }
  #verse-quick-actions-container > .previous:nth-child(2) > div {
    margin-right: 1vw;
  }

  .previous > div > p:nth-child(1) {
    font-size: 1vw;
  }
  .previous > div > p:nth-child(2) {
    font-size: 0.85vw;
  }
}
</style>

<script>
import ButtonOutline from "./ButtonOutline.vue";
import { isMobile } from "mobile-device-detect";

export default {
  inject: ["$chakraColorMode"],
  components: { ButtonOutline },
  data: function () {
    return { isMobile };
  },
  name: "VerseQuickActions",
  props: [
    "goToBeginningOfSurah",
    "goToNextVerse",
    "goToPreviousVerse",
    "isLastVerseGroup",
    "isFirstVerseGroup",
    "isFirstChapter",
    "isLastChapter",
    "nextChapterName",
    "previousChapterName",
    "nextVerseGroup",
    "previousVerseGroup",
    "verse",
  ],
  computed: {
    iconColor() {
      return this.colorMode === "dark"
        ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(357deg) brightness(104%) contrast(104%)"
        : "invert(0%) sepia(6%) saturate(7500%) hue-rotate(328deg) brightness(94%) contrast(106%);";
    },
    colorMode() {
      return this.$chakraColorMode();
    },
  },
};
</script>
