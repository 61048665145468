<template>
  <CColorModeProvider>
    <router-view />
  </CColorModeProvider>
</template>

<style>
@import "./App.css";
</style>

<script>
import { CThemeProvider, CReset, CColorModeProvider } from "@chakra-ui/vue";
import { mapGetters } from "vuex";
import { isAccesTokenExpired } from "./helper";
import Loading from "./components/CustomComponents/Loading.vue";
import smoothscroll from "smoothscroll-polyfill";
import { showToast } from "./utils";

// kick off the polyfill!
smoothscroll.polyfill();

export default {
  name: "App",
  computed: {
    ...mapGetters(["user", "chapters", "isUserLoggedIn", "savedLanguage"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    CThemeProvider,
    CReset,
    CColorModeProvider,
    Loading,
  },
  created() {
    if (!this.chapters) {
      this.$store.dispatch("loadChapters", [
        undefined,
        undefined,
        this.savedLanguage,
      ]);
    }
    if (this.isUserLoggedIn) {
      this.$store.dispatch("fetchBookmarks");
      this.$store.dispatch("getProfileDetails");
    }
  },
  data() {
    return {
      showModal: false,
    };
  },

  watch: {
    user(newVal, oldVal) {
      if (
        !oldVal &&
        newVal &&
        newVal.access_token &&
        !isAccesTokenExpired(newVal.access_token)
      ) {
        showToast(this, "Login", `Logged in successfully.`);
      } else if (oldVal.access_token && !newVal) {
        if (this.currentRouteName === "Profile") {
          this.$router.push({ name: "Home" });
        }
        showToast(this, "Logout", `Logged out successfully.`);
      }
    },
  },
};
</script>
