<template>
  <c-box h="1vw">
    <c-text class="error" v-if="show">
      {{ error }}
    </c-text>
  </c-box>
</template>
<script>
export default {
  name: "Error",
  props: ["error", "show"],
};
</script>
