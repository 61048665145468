<template>
  <c-box>
    <Header />
    <c-box class="container" mt="1vw">
      <mq-layout mq="desktop">
        <c-box>
          <c-heading class="heading">Settings</c-heading>
          <c-box style="min-height: 70vh">
            <c-flex>
              <c-box mx="3vw" mb="3vw" w="17.5%">
                <c-box
                  v-for="(i, index) in [
                    { title: 'Account' },
                    { title: 'Bookmarks' },
                  ]"
                  :key="index"
                  my="2vw"
                  py="1vw"
                  textAlign="center"
                  :class="`settings-tab${
                    activeTab === i.title ? ' active-settings-tabs' : ''
                  }`"
                  @click="handleActiveTab(i.title)"
                  borderRadius="3vw"
                >
                  {{ i.title }}
                </c-box>
                <!-- <c-flex justify="flex-end">
              <button-outline
                text="Logout"
                :padding="'1.5vw'"
                :borderRadius="'3vw'"
                :click="openLogoutAlert"
              />
            </c-flex> -->
              </c-box>
              <c-box w="82.5%">
                <profile-details v-if="activeTab === 'Account'" />
                <bookmarks v-else-if="activeTab === 'Bookmarks'" />
              </c-box>
            </c-flex>
          </c-box>
        </c-box>
      </mq-layout>
      <mq-layout mq="mobile">
        <c-box>
          <c-tabs is-fitted>
            <c-tab-list>
              <c-tab>Profile</c-tab>
              <c-tab>Bookmarks</c-tab>
            </c-tab-list>
            <c-tab-panels>
              <c-tab-panel>
                <profile-details />
              </c-tab-panel>
              <c-tab-panel>
                <bookmarks />
              </c-tab-panel>
            </c-tab-panels>
          </c-tabs>
        </c-box>
      </mq-layout>
    </c-box>
    <alert
      heading="Logout"
      cancelText="Cancel"
      :closeDialog="closeLogoutAlert"
      :isOpen="isLogoutAlertOpen"
      :onCancel="closeLogoutAlert"
      description="Are you sure you want to logout?"
      :onSubmit="logout"
      submitText="Logout"
    />
    <Footer />
  </c-box>
</template>

<script>
import { CTabs, CTabList, CTabPanels, CTab, CTabPanel } from "@chakra-ui/vue";
import ButtonOutline from "../components/CustomComponents/ButtonOutline.vue";
import CustomButton from "../components/CustomComponents/CustomButton.vue";
import Header from "../components/CustomComponents/Header.vue";
import ProfileDetails from "../components/CustomComponents/ProfileDetails.vue";
import Bookmarks from "../components/CustomComponents/Bookmarks.vue";
import Footer from "../components/CustomComponents/Footer.vue";
import Alert from "../components/CustomComponents/Alert.vue";
export default {
  components: {
    Header,
    ProfileDetails,
    CustomButton,
    ButtonOutline,
    Bookmarks,
    Footer,
    CTabs,
    CTabList,
    CTabPanels,
    CTab,
    CTabPanel,
    Alert,
  },
  name: "Profile",
  data: function () {
    return { activeTab: "Account", isLogoutAlertOpen: false };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.closeLogoutAlert();
    },
    handleActiveTab(item) {
      this.activeTab = item;
    },
    openLogoutAlert() {
      this.isLogoutAlertOpen = true;
    },
    closeLogoutAlert() {
      this.isLogoutAlertOpen = false;
    },
  },
};
</script>
