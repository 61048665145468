<template>
  <c-flex
    :class="slider ? 'slider-tile-container' : 'tile-container'"
    justify="start"
    direction="column"
  >
    <c-box position="relative" pt="0.25vw">
      <c-image class="tile-image" :src="media.media_image" />
      <c-text class="tile-language">
        {{ media.language }}
      </c-text>
    </c-box>
    <c-box @click="handleReadMore" class="tile-description">
      <c-box id="tile-content-container">
        <c-text class="tile-heading" mt="0.35vw">
          {{ media.title }}
        </c-text>
        <c-text class="tile-subheading">
          {{ (media.sub_title || media.title).trim() }}
        </c-text>
        <c-box class="tile-content" v-html="media.description" />
      </c-box>
      <mq-layout mq="desktop">
        <c-flex h="2vw" w="max-content">
          <custom-button
            :text="buttonText"
            fontSize="90% !important"
            padding="0 2vw !important"
            :click="handleClick"
          />
        </c-flex>
      </mq-layout>
      <mq-layout mq="mobile">
        <c-flex w="max-content">
          <custom-button
            :text="buttonText"
            fontSize="2vw !important"
            :click="handleClick"
          />
        </c-flex>
      </mq-layout>
    </c-box>
  </c-flex>
</template>

<style>
.tile-language {
  width: max-content;
  position: absolute;
  top: 0;
  z-index: 10000;
  background: #c1a885;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  border-radius: 9999px;
  text-transform: uppercase;
}
.tile-language,
.tile-image {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.tile-image {
  object-fit: cover;
  width: 100%;
  border-radius: 1vw;
}

.tile-heading {
  font-weight: bold;
}

.tile-content {
  width: 100%;
}
.tile-subheading {
  text-decoration: underline;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tile-content {
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.tile-description {
  transition: 0.75s;
}
.tile-description:hover {
  color: #c1a885;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .slider-tile-container:first-child {
    padding-left: 0.5vw;
    width: 55% !important;
  }
  .slider-tile-container:not(:first-child) {
    padding-left: 7.5vw;
    width: 65% !important;
  }
  #tile-content-container {
    min-height: 22vw;
  }
  .tile-heading {
    font-size: 3vw;
    margin-top: 1vw;
  }
  .tile-subheading {
    font-size: 2.5vw;
  }
  .tile-content {
    font-size: 2.25vw;
    margin-top: 0.5vw;
  }
  .tile-image {
    height: 25vw;
  }
  .tile-language {
    right: -10px;
    padding: 1.5vw;
    font-size: 80%;
  }
  .tile-content {
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
  }
}

@media only screen and (min-width: 601px) {
  .tile-content {
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    font-size: 0.85vw;
  }
  .slider-tile-container:not(:first-child):not(:last-child) {
    margin-right: 1vw;
    padding-left: 1vw;
  }
  .slider-tile-container:first-child {
    margin-right: 1vw;
  }
  .slider-tile-container {
    width: 32.5% !important;
  }
  #tile-content-container {
    min-height: 10vw;
  }
  .tile-heading {
    font-size: 1.15vw;
  }
  .tile-subheading {
    font-size: 1vw;
    margin-bottom: 0.35vw;
    margin-top: 0.15vw;
  }

  .tile-image {
    height: 15vw;
  }
  .tile-language {
    right: -10px;
    padding: 0.5vw;
  }
}
</style>

<script>
import { CButton, CImage } from "@chakra-ui/vue";
import CustomButton from "./CustomButton.vue";

export default {
  name: "Tile",
  props: ["media", "handleClick", "slider"],
  methods: {
    handleReadMore() {
      this.handleClick(this.media);
    },
  },
  computed: {
    isAudio() {
      return this.media.type.toLowerCase() === "audio";
    },
    isVideo() {
      return this.media.type.toLowerCase() === "video";
    },
    isArticle() {
      return this.media.type.toLowerCase() === "article";
    },
    buttonText() {
      return this.media.type.toLowerCase() === "audio"
        ? "Listen"
        : this.media.type.toLowerCase() === "video"
        ? "Watch"
        : "Read";
    },
  },
  components: {
    CButton,
    CImage,
    CustomButton,
  },
};
</script>
