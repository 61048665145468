<template>
  <c-flex align="center" justify="center" h="100%" w="100%">
    <c-flex direction="column" w="100%">
      <c-heading class="heading">Login</c-heading>
      <c-text mb="1vw">
        New here?
        <c-link color="palettes.primary" as="router-link" to="/signup">
          Create an Account
        </c-link>
      </c-text>
      <c-flex
        justify="space-between"
        w="100%"
        :my="isMobile ? '2vw' : '0.15vw'"
      >
        <c-flex w="48.5%">
          <social-button link="google" />
        </c-flex>
        <c-flex w="48.5%">
          <social-button link="facebook" />
        </c-flex>
      </c-flex>
      <c-flex direction="column" class="auth-form-container">
        <c-input
          v-model="email"
          placeholder="Email Address*"
          type="email"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isEmailValid"
          error-border-color="red.300"
        />
        <error :error="'Please enter a valid email'" :show="!isEmailValid" />
        <c-input
          v-model="password"
          placeholder="Password*"
          type="password"
          variant="flushed"
          class="auth-input"
        />
        <error
          :error="'Please enter a valid password'"
          :show="!isPasswordValid"
        />
        <c-text m="auto" :mt="isMobile ? '3vw' : ''">
          <c-link
            color="palettes.primary"
            as="router-link"
            to="/forgotpassword"
          >
            Forgot Password?
          </c-link>
        </c-text>
      </c-flex>
      <c-flex class="auth-button-container">
        <custom-button
          text="Login"
          :click="handleLogin"
          :isLoading="isLoggingIn"
          :disabled="!formDataIsValid"
        />
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import { CFlex, CHeading } from "@chakra-ui/vue";
import CustomButton from "./CustomButton.vue";
import { mapGetters } from "vuex";
import { validateEmail } from "../../helper";
import Error from "./Error.vue";
import { showToast } from "../../utils";
import { isMobile } from "mobile-device-detect";
import SocialButton from "./SocialButton.vue";

export default {
  name: "LoginForm",
  data: function () {
    return { email: "", password: "", isMobile };
  },
  computed: {
    ...mapGetters(["isLoggingIn", "loginError"]),
    error() {
      return this.loginError;
    },
    isEmailValid() {
      return (
        this.email === "" ||
        (this.email.length > 0 && validateEmail(this.email))
      );
    },
    isPasswordValid() {
      return this.password === "" || this.password.length > 0;
    },
    formDataIsValid() {
      return this.isEmailValid && this.isPasswordValid;
    },
  },
  components: {
    CFlex,
    CHeading,
    CustomButton,
    Error,
    SocialButton,
  },
  watch: {
    error(newValue, oldValue) {
      newValue && showToast(this, "Signup", newValue, true);
    },
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("handleLogin", [this.email, this.password]);
    },
  },
};
</script>

CustomButton
