<template>
  <c-box :h="isMobile ? '8.5%' : '10vh'" mb="0.25rem">
    <mq-layout mq="desktop" style="height: 100%; width: 100%">
      <c-flex
        align="center"
        bg="palettes.background"
        boxShadow="md"
        width="100%"
        h="100%"
        justify="space-between"
        px="5"
      >
        <c-flex align="center">
          <c-heading fontWeight="bold" mr="2vw">Logo</c-heading>
          <c-box
            mx="2vw"
            v-for="(i, index) in [
              { title: 'Home', route: '/' },
              {
                title: 'Read Quran',
                route: `/chapters/${chapterId || '1'}/verse/${savedGroupId}`,
              },
              { title: 'Media', route: '/media' },
            ]"
            :key="index"
          >
            <c-link
              :class="`link ${
                currentRouteName.includes(i.title) ? 'active-link' : ''
              }`"
              as="router-link"
              :to="i.route"
              textDecoration="none !important"
            >
              {{ i.title }}
            </c-link>
          </c-box>
        </c-flex>
        <c-flex align="center">
          <c-box @click="showSearch = !showSearch" mr="2vw">
            <c-image
              :src="require('@/assets/search-header.png')"
              m="auto"
              h="2vw"
              w="2vw"
              cursor="pointer"
            />
          </c-box>
          <c-link mr="2vw" @click="routeToProfile" v-if="isUserLoggedIn">
            <c-avatar
              :name="`${user.user_detail.first_name} ${user.user_detail.last_name}`"
              :src="image"
              size="lg"
            />
          </c-link>
          <custom-button
            :fontSize="'1.25vw !important'"
            :click="!isUserLoggedIn ? handleGoToLogin : openLogoutAlert"
            :text="!isUserLoggedIn ? 'Login' : 'Logout'"
            :width="'12vw !important'"
            :height="'3vw !important'"
            :background="'#c1a885'"
          />
        </c-flex>
      </c-flex>
    </mq-layout>
    <mq-layout mq="mobile" style="height: 100%">
      <c-flex
        bg="palettes.background"
        boxShadow="md"
        w="100%"
        h="100%"
        mb="1"
        justify="space-between"
        align="center"
        px="3"
      >
        <c-flex align="center">
          <c-box size="50%" mr="3vw" @click="drawerOpen = true">
            <c-image :src="require(`@/assets/hamburger.png`)" />
          </c-box>
          <c-heading fontWeight="400">Logo</c-heading>
        </c-flex>
        <c-flex>
          <c-box @click="showSearch = !showSearch" mr="2vw">
            <c-image
              :src="require('@/assets/search-header.png')"
              m="auto"
              h="7vw"
              w="7vw"
              cursor="pointer"
            />
          </c-box>
          <!-- <custom-button
            fontSize="2.5vw !important"
            :click="!isUserLoggedIn ? handleGoToLogin : openLogoutAlert"
            :text="!isUserLoggedIn ? 'Login' : 'Logout'"
            :width="'20vw !important'"
            :height="'7vw !important'"
            :background="'#c1a885'"
          /> -->
        </c-flex>
        <drawer
          :close="closeDrawer"
          :open="drawerOpen"
          :handleGoToLogin="handleGoToLogin"
          :openLogoutAlert="openLogoutAlert"
        />
      </c-flex>
    </mq-layout>
    <c-drawer
      :isOpen="showSearch"
      placement="top"
      :on-close="() => (showSearch = false)"
    >
      <c-drawer-overlay />
      <c-drawer-close-button />

      <c-drawer-content id="search-drawer-content-container">
        <c-flex w="100%">
          <c-box id="search-container">
            <search
              :close="
                () => {
                  this.showSearch = false;
                }
              "
            />
          </c-box>
          <c-box id="search-close-container">
            <c-drawer-close-button
              :position="isMobile ? 'inherit' : ''"
              :bottom="isMobile ? '10px' : ''"
              :top="isMobile ? '' : ''"
              :right="isMobile ? '46.5vw' : ''"
            />
          </c-box>
        </c-flex>
      </c-drawer-content>
    </c-drawer>
    <alert
      heading="Logout"
      cancelText="Cancel"
      :closeDialog="closeLogoutAlert"
      :isOpen="isLogoutAlertOpen"
      :onCancel="closeLogoutAlert"
      description="Are you sure you want to logout?"
      :onSubmit="logout"
      submitText="Logout"
    />
  </c-box>
</template>

<style>
#search-drawer-content-container,
#search-container {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
#search-drawer-content-container {
  width: 100%;
  background: #c1a885;
  border-bottom: 1px solid #cdcdcd;
  border-top: 1px solid cdcdcd;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
#search-container {
  border: 1px solid #cdcdcd;
  margin: auto;
}
#search-close-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#search-close-container > img {
  border-radius: 1vw;
}
@media only screen and (max-width: 600px) {
  #search-close-container > img {
    width: 12.5vw;
    height: 12.5vw;
  }
  #search-container {
    width: 100%;
    height: 10vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    border-radius: 5vw;
    border-radius: 5vw;
    margin-left: 2.5vw !important;
    margin-right: 2.5vw !important;
  }
  #search-close-container {
    position: fixed;
    bottom: 10px;
    left: 43.75%;
  }
}
@media only screen and (min-width: 601px) {
  #search-close-container > img {
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;
  }
  #search-container {
    width: 92.5%;
    height: 3.25vw;
    border-radius: 1vw;
    margin-left: 5vw !important;
  }
  #search-close-container {
    width: 7.5%;
  }
}
</style>

<script>
import { CFlex, CHeading, CImage } from "@chakra-ui/vue";
import { mapGetters } from "vuex";
import CustomButton from "./CustomButton.vue";
import Search from "./Search/Search.vue";
import Drawer from "../Layout/MobileView/Drawer.vue";
import Alert from "./Alert.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Header",
  components: {
    CFlex,
    CHeading,
    CustomButton,
    CImage,
    Search,
    Drawer,
    Alert,
  },
  data() {
    return {
      showModal: false,
      isLogoutAlertOpen: false,
      showSearch: false,
      drawerOpen: false,
      image: undefined,
      isMobile,
    };
  },
  computed: {
    ...mapGetters(["isUserLoggedIn", "chapterId", "user", "savedGroupId"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.isUserLoggedIn && (this.image = this.user.user_detail.user_image);
  },
  watch: {
    isUserLoggedIn(newVal, oldVal) {
      if (newVal) {
        console.log("Image here: ", this.user.user_detail.user_image);
      }
      newVal && (this.image = this.user.user_detail.user_image);
    },
    user(newVal, oldVal) {
      this.isUserLoggedIn && (this.image = newVal.user_detail.user_image);
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.closeLogoutAlert();
    },
    closeDrawer() {
      this.drawerOpen = false;
    },
    closeLogoutAlert() {
      this.isLogoutAlertOpen = false;
    },
    openLogoutAlert() {
      this.isLogoutAlertOpen = true;
    },
    routeToProfile() {
      this.$router.push({
        name: "Profile",
      });
    },
    handleGoToLogin() {
      this.drawerOpen = true && (this.drawerOpen = false);
      if (this.currentRouteName !== "LoginPage") {
        console.log("Current route name: ", this.currentRouteName);
        this.$router.push({
          name: "LoginPage",
        });
      }
    },
  },
};
</script>
