<template>
  <c-box class="profile-details-container">
    <c-heading class="heading">Profile Details</c-heading>
    <c-box>
      <c-flex :mt="isMobile ? '5vw' : '2vw'" align="center">
        <c-avatar
          :name="`${user.user_detail.first_name} ${user.user_detail.last_name}`"
          :src="image"
          size="xl"
        />
        <c-flex
          :ml="isMobile ? '4vw' : '1vw'"
          id="change-password-button-container"
        >
          <custom-button
            :text="image ? 'Edit' : 'Upload Profile Image'"
            :width="
              image ? '8vw !important' : `${isMobile ? 50 : 13}vw !important`
            "
            :click="handleSelectImage"
          />

          <c-box ml="1vw" id="change-password-button-container" v-if="image">
            <custom-button
              :text="'Remove'"
              :click="openRemoveProfileImageAlert"
            />
          </c-box>
        </c-flex>
      </c-flex>
      <c-flex mt="2vw" :direction="isMobile ? 'column' : 'row'">
        <c-input
          v-model="firstName"
          type="text"
          variant="flushed"
          placeholder="First Name"
          class="auth-input"
          mr="1vw"
        />
        <c-input
          v-model="lastName"
          type="text"
          variant="flushed"
          placeholder="Last Name"
          class="auth-input"
          ml="1vw"
        />
      </c-flex>
      <c-flex mt="1vw" :direction="isMobile ? 'column' : 'row'">
        <c-input
          v-model="email"
          type="email"
          variant="flushed"
          placeholder="Email Address"
          class="auth-input"
          mr="1vw"
        />
        <c-input
          v-model="phone"
          type="number"
          variant="flushed"
          placeholder="Phone Number"
          class="auth-input"
          ml="1vw"
        />
      </c-flex>
      <c-divider :my="isMobile ? '6vw' : '2vw'" />
      <c-flex
        :mt="isMobile ? '4vw' : '1vw'"
        :mb="isMobile ? '4vw' : '0'"
        align="center"
        justify="space-between"
      >
        <c-text fontWeight="900">Password</c-text>
        <c-box id="change-password-button-container">
          <custom-button text="Change" :click="showOpenPassword" />
        </c-box>
      </c-flex>
      <c-divider :my="isMobile ? '6vw' : '2vw'" />
      <c-flex justify="center">
        <c-flex id="update-profile-button-container">
          <custom-button
            text="UPDATE"
            :click="handleUpdate"
            :isLoading="isUpdating"
            :disabled="formHasNotChanged"
          />
        </c-flex>
      </c-flex>
    </c-box>
    <change-password :close="closeOpenPassword" :isOpen="openChangePassword" />
    <image-uploader :close="closeImageUploader" :isOpen="openImageUploader" />
    <alert
      cancelText="Cancel"
      :closeDialog="closeRemoveProfileImageAlert"
      description="Are you sure you want to remove your profile image? This cannot be undone."
      heading="Remove Profile Image"
      :isOpen="removeProfileImageAlertOpen"
      :onCancel="closeRemoveProfileImageAlert"
      :onSubmit="handleRemoveImage"
      submitText="Confirm"
    />
  </c-box>
</template>
<style>
@media only screen and (max-width: 600px) {
  #profile-details-profile-image {
    width: 20vw;
    height: 20vw;
  }
  #change-password-button-container {
    height: 8vw;
  }
  #update-profile-button-container {
    width: 40%;
  }
}
@media only screen and (min-width: 601px) {
  #profile-details-profile-image {
    width: 6.5vw;
    height: 6.5vw;
  }
  #change-password-button-container {
    height: 2.5vw;
  }
  #change-password-button-container > button {
    width: 9vw;
    font-size: 80% !important;
  }
  #update-profile-button-container {
    width: 20%;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import { updateUser } from "../../apis";
import CustomButton from "./CustomButton.vue";
import Loading from "./Loading.vue";
import ChangePassword from "./Modals/ChangePassword.vue";
import { isMobile } from "mobile-device-detect";
import { showToast } from "../../utils";
import ImageUploader from "./ImageUploader.vue";
import Alert from "./Alert.vue";

export default {
  name: "ProfileDetails",
  components: { CustomButton, Loading, ChangePassword, ImageUploader, Alert },
  data: function () {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      isUpdating: false,
      openChangePassword: false,
      image: undefined,
      isMobile,
      openImageUploader: false,
      removeProfileImageAlertOpen: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    formHasNotChanged() {
      const {
        user_detail: {
          first_name,
          last_name,
          user_email,
          user_phone,
          user_image,
        },
      } = this.user;
      return (
        this.firstName === first_name &&
        this.lastName === last_name &&
        this.email === user_email &&
        this.phone === user_phone &&
        this.image === user_image
      );
    },
  },
  created() {
    const {
      user_detail: {
        first_name,
        last_name,
        user_email,
        user_phone,
        user_image,
      },
    } = this.user;
    this.firstName = first_name;
    this.lastName = last_name;
    this.email = user_email;
    this.phone = user_phone;
    this.image = user_image;
  },
  methods: {
    openRemoveProfileImageAlert() {
      this.removeProfileImageAlertOpen = true;
    },
    closeRemoveProfileImageAlert() {
      this.removeProfileImageAlertOpen = false;
    },
    closeImageUploader() {
      this.openImageUploader = false;
    },
    handleEditProfileImage() {},
    showOpenPassword() {
      this.openChangePassword = true;
    },
    closeOpenPassword() {
      this.openChangePassword = false;
    },

    handleRemoveImage() {
      this.closeRemoveProfileImageAlert();
      this.image = undefined;
    },
    handleSelectImage() {
      this.openImageUploader = true;
    },
    handleUpdate() {
      this.isUpdating = true;
      updateUser({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
      })
        .then(() => {
          this.$store.dispatch("getProfileDetails");
        })
        .then(() => showToast(this, "Profile", "Profile updated successfully"))
        .catch((error) => {
          console.log("Error here: ", error);
        })
        .finally(() => (this.isUpdating = false));
    },
  },
};
</script>
