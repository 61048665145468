<template>
  <c-button
    class="custom-button"
    :p="padding || ''"
    :background="background || 'linear-gradient(#348dcf, #27367b)'"
    :borderRadius="borderRadius"
    :h="height || '100%'"
    :w="width || '100%'"
    :isLoading="isLoading"
    @click="click"
    :fontSize="fontSize"
    :disabled="disabled"
  >
    <c-image
      :src="require(`@/assets/${link}.png`)"
      size="30px"
      mx="2"
      v-if="setIcon"
    />

    {{ text }}
  </c-button>
</template>

<style>
.custom-button {
  color: white;
}

@media only screen and (max-width: 600px) {
  .custom-button {
    font-size: 3vw;
    padding: 2vw 7.5vw !important;
    border-radius: 5vw !important;
  }
}

@media only screen and (min-width: 601px) {
  .custom-button {
    font-size: 1.2vw;
    padding: 1vw 3vw;
    border-radius: 3vw !important;
  }
}
</style>
<script>
import { CButton } from "@chakra-ui/vue";

export default {
  name: "CustomButton",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  props: [
    "text",
    "borderRadius",
    "height",
    "width",
    "padding",
    "fontSize",
    "link",
    "setIcon",
    "size",
    "click",
    "isLoading",
    "disabled",
    "background",
  ],
  components: {
    CButton,
  },
};
</script>
