<template>
  <c-flex w="100%" justify="space-evenly">
    <c-flex align="center" justify="center" class="toggle-color-mode" mr="1vw">
      <c-flex height="27.5" width="57.5" style="position: relative">
        <VueToggles
          height="27.5"
          width="57.5"
          :dotColor="colorMode === 'dark' ? '#fafafa' : '#fafafa'"
          checkedBg="#4d4d4d"
          uncheckedBg="#4d4d4d"
          :value="colorMode === 'dark'"
          @click="toggleColorMode"
        />
        <span
          v-if="colorMode === 'dark'"
          style="
            position: absolute;
            left: 6px;
            height: 100%;
            align-items: center;
            display: flex;
          "
        >
          🌞
        </span>
        <span
          v-if="colorMode !== 'dark'"
          style="
            position: absolute;
            right: 5px;
            height: 100%;
            align-items: center;
            display: flex;
          "
        >
          🌜
        </span>
      </c-flex>
    </c-flex>
    <c-flex class="sbd-container">
      <c-link @click="shareOpen">Share</c-link>
      <share :isOpen="isShareOpen" :close="shareClose" />
      <c-image
        :src="require(`@/assets/share.svg`)"
        class="icons-sbd"
        :filter="iconColor"
      />
    </c-flex>
    <c-flex
      class="sbd-container"
      v-if="
        !isBookmarkingCurrentProps &&
        !isRemovingBookmarkCurrentProps &&
        !isBookmarked
      "
    >
      <c-link @click="handleBookmark('add')">Bookmark</c-link>
      <c-image
        :src="require(`@/assets/bookmark.svg`)"
        class="icons-sbd"
        :filter="iconColor"
      />
    </c-flex>
    <c-flex
      class="sbd-container"
      v-else-if="isBookmarkingCurrentProps || isRemovingBookmarkCurrentProps"
    >
      <c-text>Bookmark</c-text>
      <c-spinner
        thickness="2px"
        speed="0.5s"
        empty-color="green.200"
        color="vue.500"
        ml="0.25vw"
        size="sm"
      />
    </c-flex>
    <c-flex class="sbd-container" v-else-if="isBookmarked">
      <c-link @click="handleBookmark('remove')">Bookmarked</c-link>
      <c-image
        :src="require(`@/assets/icons/bookmarked.svg`)"
        class="icons-sbd"
        :filter="iconColor"
      />
    </c-flex>

    <c-flex class="sbd-container">
      <c-link @click="downloadOpen">Download</c-link>
      <download
        :isOpen="isDownloadOpen"
        :close="downloadClose"
        :chapterName="chapter"
        :language="language"
        :verseGroup="verseGroup"
        :textDirection="textDirection"
      />
      <c-image
        :src="require(`@/assets/icons/download.svg`)"
        class="icons-sbd"
        :filter="iconColor"
      />
    </c-flex>

    <!-- <mq-layout mq="mobile">
      <c-flex align="center" justify="center">
        <c-flex class="sbd-container">
          <c-link @click="shareOpen">
            <c-image
              :src="require(`@/assets/share.svg`)"
              class="icons-sbd"
              :filter="iconColor"
            />
          </c-link>
          <share :isOpen="isShareOpen" :close="shareClose" />
        </c-flex>
        <c-flex class="sbd-container">
          <c-link>
            <c-image
              :src="require(`@/assets/bookmark.svg`)"
              class="icons-sbd"
              :filter="iconColor"
            />
          </c-link>
        </c-flex>
        <c-flex class="sbd-container">
          <c-link @click="downloadOpen">
            <c-image
              :src="require(`@/assets/download.png`)"
              class="icons-sbd"
            />
          </c-link>
          <download
            :isOpen="isDownloadOpen"
            :close="downloadClose"
            :chapter="chapter"
            :verseGroup="verseGroup"
          />
        </c-flex>
      </c-flex>
    </mq-layout> -->
  </c-flex>
</template>
<style>
.dot:hover {
  box-shadow: 0 0 10px 5px #c1a885;
  border-radius: 99999px;
}
</style>

<script>
import Download from "./Modals/Download.vue";
import Share from "./Modals/Share.vue";
import VueToggles from "vue-toggles";
import { mapGetters } from "vuex";
import { showToast } from "../../utils";

export default {
  components: { Share, Download, VueToggles },
  name: "ShareDownloadBookmark",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  props: ["chapter", "verseGroup", "language", "textDirection"],
  computed: {
    ...mapGetters([
      "addingObjectToBookmarks",
      "bookmarks",
      "removingObjectFromBookmarks",
      "isUserLoggedIn",
    ]),
    colorMode() {
      return this.$chakraColorMode();
    },
    toggleColorMode() {
      return this.$toggleColorMode;
    },
    iconColor() {
      return this.colorMode === "dark"
        ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(357deg) brightness(104%) contrast(104%)"
        : "invert(0%) sepia(6%) saturate(7500%) hue-rotate(328deg) brightness(94%) contrast(106%);";
    },
    isBookmarkingCurrentProps() {
      return this.chapter &&
        this.chapter.chapter_id &&
        this.addingObjectToBookmarks
        ? this.addingObjectToBookmarks.chapter_id === this.chapter.chapter_id &&
          this.language === this.addingObjectToBookmarks.language &&
          this.verseGroup === "all"
          ? "all" === this.addingObjectToBookmarks.group_id
          : Number(this.verseGroup.split(" - ")[1]) ===
            this.addingObjectToBookmarks.group_id
        : false;
    },
    isRemovingBookmarkCurrentProps() {
      return this.chapter &&
        this.chapter.chapter_id &&
        this.removingObjectFromBookmarks
        ? this.removingObjectFromBookmarks.chapter_id ===
            this.chapter.chapter_id &&
          this.language === this.removingObjectFromBookmarks.language &&
          this.verseGroup === "all"
          ? "all" === this.removingObjectFromBookmarks.group_id
          : Number(this.verseGroup.split(" - ")[1]) ===
            this.removingObjectFromBookmarks.group_id
        : false;
    },
    isBookmarked() {
      if (!this.isUserLoggedIn) {
        return false;
      }
      for (var i = 0; i < (this.bookmarks || []).length; i++) {
        if (
          this.bookmarks[i].chapter_id === this.chapter.chapter_id &&
          this.bookmarks[i].group_id ===
            (this.verseGroup === "all"
              ? "all"
              : Number(this.verseGroup.split(" - ")[1])) &&
          this.bookmarks[i].language === this.language
        ) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    isRemovingBookmarkCurrentProps(newVal, oldVal) {
      console.log("New val: ", newVal);
    },
  },
  data: function () {
    return { isShareOpen: false, isDownloadOpen: false };
  },
  methods: {
    handleBookmark(operation) {
      if (!this.isUserLoggedIn) {
        showToast(
          this,
          "Bookmarks",
          "Please login to manage your bookmarks.",
          true
        );
      } else {
        this.chapter &&
          this.chapter.chapter_id &&
          this.$store.dispatch(
            operation === "add" ? "addToBookmarks" : "removeFromBookmarks",
            [
              this.chapter.chapter_id,
              this.verseGroup === "all"
                ? "all"
                : Number(this.verseGroup.split(" - ")[1]),
              this.language,
            ]
          );
      }
    },
    shareOpen() {
      this.isShareOpen = true;
    },
    shareClose() {
      this.isShareOpen = false;
    },
    downloadOpen() {
      this.isDownloadOpen = true;
    },
    downloadClose() {
      this.isDownloadOpen = false;
    },
  },
};
</script>
