<template>
  <c-box h="90%">
    <c-box h="100%">
      <Header />
      <loading v-if="isLoadingArticles" />

      <c-box class="container" v-else-if="!isLoadingArticles">
        <c-box v-for="(i, index) in Object.keys(articles)" :key="index">
          <c-box v-if="articles[i]">
            <related-media
              :relatedMedia="articles[i]"
              :type="i"
              :title="Object.keys(articles)[index]"
              :seeAllRoute="() => handleRouteToSubPage(i)"
              :showSeeAll="true"
            />
            <mq-layout mq="desktop">
              <hr
                style="
                  border-width: 2px;
                  z-index: 100;
                  border-color: #c1a885;
                  margin-top: 4vw;
                  margin-bottom: 4vw;
                "
              />
            </mq-layout>
            <mq-layout mq="mobile">
              <hr
                style="
                  border-width: 2px;
                  z-index: 100;
                  border-color: #c1a885;
                  margin-top: 5vw;
                  margin-bottom: 5vw;
                "
              />
            </mq-layout>
          </c-box>
        </c-box>
      </c-box>
      <Footer />
    </c-box>
  </c-box>
</template>
<script>
import { getRelatedMediaAll } from "../apis";
import Footer from "../components/CustomComponents/Footer.vue";
import Header from "../components/CustomComponents/Header.vue";
import Loading from "../components/CustomComponents/Loading.vue";
import RelatedMedia from "../components/CustomComponents/RelatedMedia.vue";

export default {
  components: { Loading, RelatedMedia, Header, Footer },
  name: "Media",
  data() {
    return {
      isLoadingArticles: true,
      chapterId: undefined,
      groupId: undefined,
      articles: undefined,
      total_article: undefined,
      total_audio: undefined,
      total_video: undefined,
    };
  },
  watch: {
    mediaType(newVal, oldVal) {
      this.getArticles(this.chapterId, this.groupId, this.mediaType);
    },
  },
  methods: {
    handleRouteToSubPage(mediaType) {
      this.$router.push({
        name: "MediaSubPage",
        params: {
          chapterId: this.chapterId,
          groupId: this.groupId,
          mediaType,
        },
      });
    },
    getArticles() {
      this.isLoadingArticles === false && (this.isLoadingArticles = true);
      getRelatedMediaAll(this.chapterId, this.groupId, "all")
        .then((result) => {
          const {
            total_article,
            total_audio,
            total_video,
            audio,
            video,
            article,
          } = result.data;
          this.articles = { article, audio, video };
          this.totalArticles = total_article;
          this.totalAudio = total_audio;
          this.totalVideo = total_video;
        })
        .catch((error) =>
          console.log("error while getting all related media: ", error)
        )
        .finally(() => (this.isLoadingArticles = false));
    },
  },
  created() {
    this.$route.params.chapterId &&
      (this.chapterId = this.$route.params.chapterId);
    this.$route.params.groupId && (this.groupId = this.$route.params.groupId);
    this.getArticles();
  },
};
</script>
