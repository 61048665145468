<template>
  <c-box
    zIndex="10000 !important"
    v-if="commentary && (commentary.audio || commentary.text)"
  >
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      :closeOnOverlayClick="false"
      zIndex="10000 !important"
      :size="fullScreen ? 'full' : 'xl'"
    >
      <c-modal-content
        class="audio-video-modal-content"
        v-scroll-lock="isMobile && isOpen"
        p="0vw !important"
        :minHeight="fullScreen ? '100%' : 'max-content'"
        :m="fullScreen ? '0 !important' : ''"
      >
        <c-modal-header>
          <c-flex justify="space-between">
            Commentary
            <c-box @click="goFullScreen">
              <c-image
                cursor="pointer"
                :src="
                  require(`@/assets/icons/${
                    fullScreen ? 'exit-fullscreen' : 'go-fullscreen'
                  }.svg`)
                "
                :mr="isMobile ? '8vw' : '3vw'"
              />
            </c-box>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <c-modal-body mt="1vw" mb="2vw" px="2vw">
          <av-circle
            style="margin-bottom: 1vw"
            v-if="commentary.audio"
            :outline-width="0"
            :progress-width="5"
            :outline-meter-space="5"
            :playtime="true"
            :cors-anonym="true"
            :audio-src="commentary.audio"
            :canv-class="'commentary-visual'"
          />
          <c-text
            v-html="commentary.text"
            textAlign="justify"
            lineHeight="200%"
          />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>
<script>
import { CButton, CImage } from "@chakra-ui/vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "ChapterCommentary",
  props: ["isOpen", "close", "commentary", "lineHeight"],
  data: function () {
    return { isMobile, fullScreen: false };
  },
  components: {
    CButton,
    CImage,
  },
  methods: {
    goFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
  },
};
</script>
