import axios from "./axios";
import store from "./store";

export const getChapters = async(chapter, groupId, language = "en") => {
    const query = new URLSearchParams();
    chapter && query.append("chapter", chapter);
    groupId && query.append("group_id", groupId);
    language && query.append("language", language);
    return await axios.get(`/chapter-all${"?" + query}`);
};

export const getRelatedMedia = async(chapter, groupId) =>
    await axios.get(`/related-media?chapter=${chapter}&group=${groupId}`);

export const search = async(search, pageNo = 0, perPage = 20) =>
    await axios.post(`/search`, {
        search,
        type: "All",
        page_no: pageNo,
        per_page: perPage,
    });

export const login = async(username, password) =>
    await axios.post("users/login", { email: username, password });

export const signup = async(
        email,
        password,
        firstName,
        lastName,
        phone,
        type = "reg"
    ) =>
    await axios.post("users/signup", {
        email,
        password,
        phone,
        first_name: firstName,
        last_name: lastName,
        type,
    });

export const forgotPassword = async(email) =>
    await axios.post("users/forgot-pass", { email });

export const getRelatedMediaAll = async(
    chapter,
    groupId,
    type,
    pageNo = undefined
) => {
    const query = new URLSearchParams();
    chapter && query.append("chapter", chapter);
    groupId && query.append("group", groupId);
    type && query.append("type", type);
    pageNo && query.append("page_no", pageNo);
    console.log("Query here: ", query);
    return await axios.get(`/related-media-all?${query}`);
};
export const updateUser = async(obj) => {
    var form_data = new FormData();
    for (var key in obj) {
        form_data.append(key, obj[key]);
    }
    console.log("Req here: ", form_data);
    await axios.post(
        `/users/profile-update/${store.state.user.user_detail.user_id}`,
        form_data, {
            headers: { Authorization: `Bearer ${store.state.user.access_token}` },
        }
    );
};

export const updateProfileImage = async(file) => {
    const {
        state: {
            user: {
                user_detail: { first_name, last_name, email, phone },
            },
        },
    } = store;
    for (var key in {
            first_name,
            last_name,
            email,
            phone,
        }) {
        form_data.append(key, obj[key]);
    }
    var form_data = new FormData();
    form_data.append("fileimage", file);
    await axios.post(
        `/users/profile-update/${store.state.user.user_detail.user_id}`,
        form_data, {
            headers: { Authorization: `Bearer ${store.state.user.access_token}` },
        }
    );
};

export const getUser = async() =>
    await axios.get(`/users/profile/${store.state.user.user_detail.user_id}`, {
        headers: { Authorization: `Bearer ${store.state.user.access_token}` },
    });

export const changePassword = async(oldPassword, password) =>
    await axios.post(
        `/users/pass-update`, { "old-password": oldPassword, "new-password": password }, {
            headers: { Authorization: `Bearer ${store.state.user.access_token}` },
        }
    );

export const bookmark = async(chapter_id, group_id, language, update) =>
    await axios.post(
        `/bookmarks`, {
            chapter_id,
            group_id,
            language,
            update,
        }, {
            headers: { Authorization: `Bearer ${store.state.user.access_token}` },
        }
    );

export const getBookmarks = async() =>
    await axios.get(`/bookmarks/list`, {
        headers: { Authorization: `Bearer ${store.state.user.access_token}` },
    });

export const getArticle = async(id) =>
    await axios.get(`/media-detail-page/${id}`);

export const download = async(chapterId, groupId, language, direction, link) =>
    await axios.post(
        `/quran-pdf-link`, {
            chapter_id: chapterId,
            group_id: groupId,
            language,
            direction,
            link,
        }, { responseType: "blob" }
    );

export const updateForgotPassword = async(code, password) =>
    axios.post(`/users/pass-update`, { code, password });