<template>
  <c-box w="100%" bg="palettes.gold" id="footer-container">
    <mq-layout mq="desktop" style="width: 100%">
      <c-flex bg="palettes.gold" color="white" align="center" p="3vw" w="100%">
        <c-flex direction="column" w="75%">
          <c-heading fontWeight="bold" fontSize="3vw">Logo</c-heading>
          <c-text my="2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum
            lorem pretium convallis. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Sem ipsum lorem pretium convallis.
          </c-text>
        </c-flex>
        <c-flex direction="column" justify="center" px="4vw" w="25%">
          <c-text class="footer-link-heading">Quick Links</c-text>
          <c-link
            v-for="(i, index) in [
              { title: 'Home', route: '/', show: true },
              {
                title: 'Read Quran',
                route: `/chapters/${chapterId || '1'}/verse/${savedGroupId}`,
                show: true,
              },
              { title: 'Media', route: '/media', show: true },
              { title: 'Profile', route: '/profile', show: isUserLoggedIn },
            ]"
            :key="index"
            style="width: max-content; padding-top: 0 !important"
            :class="`footer-link ${
              currentRouteName.includes(i.title) ? 'active-link' : ''
            }`"
            as="router-link"
            :to="i.route"
          >
            <span v-if="i.show"> {{ i.title }}</span>
          </c-link>
        </c-flex>
      </c-flex>
    </mq-layout>
    <mq-layout mq="mobile">
      <c-flex
        direction="column"
        bg="palettes.gold"
        color="white"
        align="center"
        justify="space-evenly"
        bottom="0"
        p="3vw"
      >
        <c-flex direction="column" align="center">
          <c-flex direction="column" align="center">
            <c-heading fontWeight="bold" fontSize="5vw">Logo</c-heading>
            <c-text fontSize="2.5vw" textAlign="center" my="5vw">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem ipsum
              lorem pretium convallis. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sem ipsum lorem pretium convallis.
            </c-text>
          </c-flex>
          <c-simple-grid :columns="1">
            <c-flex direction="column" justify="center">
              <c-text class="footer-link-heading">Quick Links</c-text>
              <c-link
                v-for="(i, index) in [
                  { title: 'Home', route: '/', show: true },
                  {
                    title: 'Read Quran',
                    route: `/chapters/${
                      chapterId || '1'
                    }/verse/${savedGroupId}`,
                    show: true,
                  },
                  { title: 'Media', route: '/media', show: true },
                  { title: 'Profile', route: '/profile', show: isUserLoggedIn },
                ]"
                :key="index"
                style="
                  width: max-content;
                  padding-top: 0 !important;
                  margin-left: auto;
                  margin-right: auto;
                "
                :class="`footer-link ${
                  currentRouteName.includes(i.title) ? 'active-link' : ''
                }`"
                as="router-link"
                :to="i.route"
              >
                <span v-if="i.show"> {{ i.title }}</span>
              </c-link>
            </c-flex>
          </c-simple-grid>
        </c-flex>
      </c-flex>
    </mq-layout>
  </c-box>
</template>
<style>
.footer-link-heading {
  font-weight: bold;
}
#footer-container {
  margin-top: auto;
}
@media only screen and (max-width: 600px) {
  .footer-link {
    margin-bottom: 12.5%;
    font-size: 3vw !important;
  }
  .footer-link:hover {
    cursor: pointer;
  }
  .footer-link-heading {
    font-size: 4vw !important;
    margin-bottom: 12.5%;
  }
}

@media only screen and (min-width: 601px) {
  .footer-link {
    margin-top: 5%;
    font-size: 1vw !important;
  }
  .footer-link-heading {
    font-size: 1.5vw;
    margin-bottom: 1%;
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  props: [
    "text",
    "borderRadius",
    "height",
    "width",
    "padding",
    "fontSize",
    "isUserLoggedIn",
  ],

  computed: {
    ...mapGetters(["chapterId", "savedGroupId"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
