<template>
  <swipe-modal v-model="showModal">
    <template v-slot:header>
      <div
        :style="{
          background: colorMode === 'dark' ? 'black' : 'white',
        }"
      >
        <c-heading
          p="15px"
          class="heading"
          mb="0 !important"
          :style="{ color: colorMode === 'dark' ? 'white' : 'black' }"
        >
          Chapter Options
        </c-heading>
      </div>
    </template>
    <template v-slot:body>
      <c-flex
        direction="column"
        pb="10vw"
        px="4vw"
        :style="{ background: colorMode === 'dark' ? 'black' : 'white' }"
      >
        <c-flex w="100%" pt="7.5vw">
          <c-text class="filter-label">Chapters</c-text>
          <c-icon
            name="chevron-down"
            size="5vw"
            :color="colorMode === 'dark' ? 'white' : 'black'"
          />
        </c-flex>
        <dropdown-menu
          :withDropdownCloser="true"
          :class="[
            colorMode == 'dark' ? 'box-dark' : '',
            'filter-container custom-select',
          ]"
          @closed="chapterOpen = false"
          @opened="chapterOpen = true"
        >
          <button slot="trigger">
            {{ `${chapter.chapter_id}. ${chapter.name}` }}
          </button>
          <ul slot="body" v-scroll-lock="chapterOpen">
            <li
              v-for="(chapterFilter, index) in chapters"
              :key="`${chapterFilter.chapter_id}-${index}`"
              :class="
                chapter.chapter_id === chapterFilter.chapter_id
                  ? 'golden-bg'
                  : ''
              "
            >
              <c-box
                dropdown-closer
                class="filter-value"
                @click="
                  () => {
                    close();
                    setChapterId(String(chapterFilter.chapter_id));
                  }
                "
              >
                {{ `${chapterFilter.chapter_id}. ${chapterFilter.name}` }}
              </c-box>
            </li>
          </ul>
        </dropdown-menu>
        <c-flex align="center" pt="7.5vw">
          <c-text class="filter-label">Verse No</c-text>
          <c-icon
            name="chevron-down"
            size="5vw"
            :color="colorMode === 'dark' ? 'white' : 'black'"
          />
        </c-flex>
        <dropdown-menu
          :withDropdownCloser="true"
          :class="[
            colorMode == 'dark' ? 'box-dark' : '',
            'filter-container custom-select',
          ]"
          @closed="chapterOpen = false"
          @opened="chapterOpen = true"
        >
          <button slot="trigger" style="text-transform: capitalize">
            {{ verse }}
          </button>
          <ul slot="body" v-scroll-lock="chapterOpen">
            <li :class="verse === 'all' ? 'golden-bg' : ''">
              <c-box
                dropdown-closer
                class="filter-value"
                @click="
                  () => {
                    setVerse('all');
                  }
                "
              >
                All
              </c-box>
            </li>
            <li
              v-for="(item, index) in chapter.verses"
              :key="`verse-${item.id}-${index}`"
              :class="
                verse === getVerseGroupFromVerseId(item.id) ? 'golden-bg' : ''
              "
            >
              <c-box
                dropdown-closer
                class="filter-value"
                @click="
                  () => {
                    setVerse(getVerseGroupFromVerseId(item.id));
                  }
                "
              >
                {{ item.id }}
              </c-box>
            </li>
          </ul>
        </dropdown-menu>
        <c-link
          mt="10vw"
          fontSize="3vw !important"
          display="flex"
          @click="openReadIntroduction"
          :class="[
            colorMode === 'dark' ? 'box-dark' : '',
            'filter-container filter-value',
          ]"
        >
          Read Introduction
        </c-link>
      </c-flex>
    </template>
    <template v-slot:footer>
      <c-flex
        h="17.5vw"
        p="3.5vw"
        :style="{
          background: colorMode === 'dark' ? 'black' : 'white',
          borderTop: '1px solid #cdcdcd',
        }"
      >
        <custom-button text="Close" :click="close" />
      </c-flex>
    </template>
  </swipe-modal>
</template>

<script>
import SwipeModal from "swipe-modal-2";
import CustomButton from "../../CustomComponents/CustomButton.vue";

export default {
  name: "ChapterOptions",
  props: [
    "show",
    "close",
    "chapters",
    "chapterIdFilters",
    "setChapterId",
    "verse_group_list",
    "verse",
    "setVerse",
    "openReadIntroduction",
    "colorMode",
    "chapter",
    "getVerseGroupFromVerseId",
  ],
  data: function () {
    return { chapterOpen: false, verseOpen: false };
  },
  components: { SwipeModal, CustomButton },

  computed: {
    verseFilter: {
      get() {
        return this.verse;
      },
      set(newVerseId) {
        this.setVerse(newVerseId);
        return newVerseId;
      },
    },
    chapterId: {
      get() {
        return this.chapterIdFilters;
      },
      set(newChapterId) {
        this.close();
        // setTimeout(() => {
        //   this.setChapterId(newChapterId);
        // }, 500);
        // return newChapterId;
      },
    },
    showModal: {
      get() {
        return this.show;
      },
      set(newShow) {
        newShow === "closed" && this.close();
        return newShow;
      },
    },
  },
};
</script>
