var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isFetchingBookmarks)?_c('c-box',{staticClass:"profile-details-container bookmarks-container",attrs:{"h":"100%"}},[_c('c-heading',{staticClass:"heading"},[_vm._v("Bookmarks")]),_c('mq-layout',{style:({
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      height: _vm.bookmarks && _vm.bookmarks.length > 0 ? '' : '100%',
    }),attrs:{"mq":"desktop"}},[(_vm.bookmarks && _vm.bookmarks.length > 0)?_c('c-simple-grid',{attrs:{"columns":3,"my":"2vw","spacing":'1vw',"w":"100%"}},_vm._l((_vm.bookmarks),function(bookmark,index){return _c('c-box',{key:index,staticClass:"introduction-container bookmark",attrs:{"borderRadius":"1vw","py":"1.25vw","px":"0.75vw"},on:{"click":function($event){_vm.handleClickBookmark(
            bookmark.chapter_id,
            bookmark.group !== 'all'
              ? bookmark.group.split(' - ')[1]
              : bookmark.group
          )}}},[_vm._l(([
            {
              title: 'Chapter',
              value: ((bookmark.chapter_id) + ". " + (bookmark.chapter_name)),
            },
            {
              title: 'Verse',
              value: bookmark.group,
              textTransform: 'capitalize',
            },
            {
              title: 'Language',
              value: bookmark.language,
              textTransform: 'uppercase',
            } ]),function(i){return _c('c-flex',{key:i.tite,attrs:{"pt":"0.35vw"}},[_c('c-text',{attrs:{"w":"30%","fontWeight":"bold"}},[_vm._v(_vm._s(i.title))]),_c('c-text',{staticClass:"bookmark-value",attrs:{"w":"70%","textTransform":i.textTransform}},[_vm._v(" "+_vm._s(i.value)+" ")])],1)}),_c('c-flex',{attrs:{"justify":"space-between","align":"center","pt":"0.5vw"}},[_c('c-box',{on:{"click":function($event){return _vm.handleRemoveBookmark($event, bookmark)}}},[_c('c-image',{staticClass:"icons-sbd",attrs:{"src":require("@/assets/icons/garbage.png"),"id":"bookmark-action-icon"}})],1),_c('c-image',{staticClass:"icons-sbd",attrs:{"src":require("@/assets/icons/right-arrow.png"),"id":"bookmark-action-icon"}})],1)],2)}),1):_c('c-text',{attrs:{"color":"palettes.labelLightGrey"}},[_vm._v("No Bookmarks")])],1),_c('mq-layout',{staticStyle:{"height":"100%","display":"flex","justify-content":"center","align-items":"center"},attrs:{"mq":"mobile"}},[(_vm.bookmarks && _vm.bookmarks.length > 0)?_c('c-simple-grid',{attrs:{"columns":1,"my":"5vw","spacing":'2.5vw',"w":"100%"}},_vm._l((_vm.bookmarks),function(bookmark,index){return _c('c-box',{key:index,staticClass:"introduction-container bookmark",attrs:{"borderRadius":"1vw","py":"1vw","px":"2vw","w":"100%"},on:{"click":function($event){_vm.handleClickBookmark(
            bookmark.chapter_id,
            bookmark.group !== 'all'
              ? bookmark.group.split(' - ')[1]
              : bookmark.group
          )}}},[_vm._l(([
            {
              title: 'Chapter',
              value: ((bookmark.chapter_id) + ". " + (bookmark.chapter_name)),
            },
            {
              title: 'Verse',
              value: bookmark.group,
              textTransform: 'capitalize',
            },
            {
              title: 'Language',
              value: bookmark.language,
              textTransform: 'uppercase',
            } ]),function(i){return _c('c-flex',{key:i.tite},[_c('c-text',{attrs:{"w":"25%","fontWeight":"bold"}},[_vm._v(_vm._s(i.title))]),_c('c-text',{staticClass:"bookmark-value",attrs:{"w":"75%","textTransform":i.textTransform}},[_vm._v(_vm._s(i.value)+" ")])],1)}),_c('c-flex',{attrs:{"justify":"space-between","align":"center"}},[_c('c-box',{on:{"click":function($event){return _vm.handleRemoveBookmark($event, bookmark)}}},[_c('c-image',{staticClass:"icons-sbd",attrs:{"src":require("@/assets/icons/garbage.png"),"id":"bookmark-action-icon"}})],1),_c('c-image',{staticClass:"icons-sbd",attrs:{"src":require("@/assets/icons/right-arrow.png"),"id":"bookmark-action-icon"}})],1)],2)}),1):_c('c-text',{attrs:{"color":"palettes.labelLightGrey","h":"55vh","d":"flex","justifyContent":"center","alignItems":"center","fontSize":"3vw !important"}},[_vm._v(" No Bookmarks ")])],1)],1):_c('loading')}
var staticRenderFns = []

export { render, staticRenderFns }