var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',[_c('c-modal',{attrs:{"is-open":_vm.isOpen,"on-close":_vm.close,"closeOnOverlayClick":false,"size":"md"}},[_c('c-modal-content',[_c('c-modal-header',[_vm._v("SHARE NOW")]),_c('c-modal-close-button'),_c('c-modal-body',[_c('c-simple-grid',{attrs:{"columns":3,"spacing":10,"px":"2vw","py":_vm.isMobile ? '5vw' : '1vw'}},_vm._l(([
              'facebook',
              'whatsapp',
              'twitter',
              'linkedin',
              'pinterest',
              'email' ]),function(icon){return _c('ShareNetwork',{key:icon,staticClass:"share-icon",style:({
              'background-image': ("url(" + (require(("@/assets/share/" + icon + ".png"))) + ")"),
              'background-size': 'contain',
            }),attrs:{"network":icon,"url":_vm.url,"title":"QuranForYou","description":"Read Chapter","quote":"Read Quran","hashtags":"quran,quranforyou"}})}),1)],1)],1),_c('c-modal-overlay')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }