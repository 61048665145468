<template>
  <c-flex align="center" justify="center" h="100%" w="100%">
    <c-flex direction="column" w="100%">
      <c-heading class="heading">Forgot Password </c-heading>
      <c-text>
        Already have an account?
        <c-link color="palettes.primary" as="router-link" to="/login">
          Login
        </c-link>
      </c-text>
      <c-flex direction="column" class="auth-form-container">
        <c-input
          v-model="email"
          type="email"
          variant="flushed"
          placeholder="Email Address"
          class="auth-input"
          :is-invalid="email !== '' && !isEmailValid"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a valid email address'"
          :show="email !== '' && !isEmailValid"
        />
        <c-text opacity="0.5" :fontSize="isMobile ? '3vw' : '1vw'">
          Verification will be sent to the email address
        </c-text>
      </c-flex>
      <c-flex class="auth-button-container">
        <custom-button
          text="Submit"
          :click="handleForgotPassword"
          :isLoading="isForgettingPassword"
          :disabled="!isFormValid"
        />
      </c-flex>
      <one-time-verification
        :close="handleCloseOTP"
        :isOpen="isOTPOpen"
        :email="email"
        :handleSubmit="handleOTPSubmit"
        :isLoading="true"
        :handleResendOTP="handleForgotPassword"
      />
    </c-flex>
  </c-flex>
</template>

<script>
import { CFlex, CHeading } from "@chakra-ui/vue";
import SocialButton from "@/components/CustomComponents/SocialButton.vue";
import CustomButton from "./CustomButton.vue";
import { mapGetters } from "vuex";
import { validateEmail } from "../../helper";
import Error from "./Error.vue";
import OneTimeVerification from "./Modals/OneTimeVerification.vue";
import { updateForgotPassword } from "../../apis";
import { showToast } from "../../utils";
import { isMobile } from "mobile-device-detect";

export default {
  name: "ForgotPasswordForm",
  data: function () {
    return { email: "", isOTPOpen: false, isUpdatingPassword: false, isMobile };
  },
  components: {
    CFlex,
    CHeading,
    SocialButton,
    CustomButton,
    Error,
    OneTimeVerification,
  },
  watch: {
    forgotPasswordSuccess(newVal, oldVal) {
      if (oldVal !== true && newVal === true) {
        showToast(
          this,
          "Forgot Password",
          "OTP sent to the email address successfully"
        );
        this.handleOpenOTP();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isForgettingPassword",
      "forgettingPasswordError",
      "forgotPasswordSuccess",
    ]),
    error() {
      return this.forgettingPasswordError;
    },
    isEmailValid() {
      return this.email.length > 0 && validateEmail(this.email);
    },
    isFormValid() {
      return this.isEmailValid;
    },
  },
  methods: {
    async handleOTPSubmit(otp, password) {
      this.isUpdatingPassword = true;
      await updateForgotPassword(otp, password)
        .then((result) => {
          result.status === 200 &&
            showToast(this, "Forgot Password", "Password Changed Successfully");
        })
        .catch((error) => {
          console.log("Error here: ", error);
          showToast(
            this,
            "Forgot Password",
            "Something went wrong please try again."
          );
        });
    },
    handleResendOTP() {
      this.handleForgotPassword();
    },
    handleForgotPassword() {
      this.$store.dispatch("handleForgotPassword", [this.email]);
    },
    handleOpenOTP() {
      this.isOTPOpen = true;
    },
    handleCloseOTP() {
      this.isOTPOpen = false;
    },
  },
};
</script>
