<template>
  <c-box w="100%" h="100%">
    <Header />
    <c-box
      :h="
        isMobile ? '91.5%' : isLoadingChapters || isLoadingChapter ? '90vh' : ''
      "
    >
      <loading v-if="isLoadingChapters || isLoadingChapter" />
      <verse
        v-else
        :chapter="chapter"
        :getChapter="this.getChapter"
        :groupId="this.groupId"
        :language="this.language"
        :setLanguage="this.setLanguage"
        :isLoadingChapter="isLoadingChapter"
      />
    </c-box>
  </c-box>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/CustomComponents/Footer.vue";
import { getChapters } from "../apis";
import Header from "../components/CustomComponents/Header.vue";
import Loading from "../components/CustomComponents/Loading.vue";
import Verse from "../components/Layout/Desktopview/Verse/index.vue";
import { isMobile } from "mobile-device-detect";
import { mapGetters } from "vuex";

export default {
  name: "ReadVerse",
  data() {
    return {
      isLoadingChapter: true,
      chapter: null,
      language: "en",
      isMobile,
    };
  },
  methods: {
    setLanguage(value) {
      this.language = value;
    },
    getChapter(id, group, langauge) {
      if (
        this.savedChapter &&
        this.savedChapter.chapter_id == id &&
        langauge !== this.savedLanguage
      ) {
        this.chapter = this.savedChapter;
        this.isLoadingChapter === true && (this.isLoadingChapter = false);
      } else {
        this.isLoadingChapter === false && (this.isLoadingChapter = true);
        getChapters(id, group, langauge)
          .then((result) => (this.chapter = result.data.chapters[0]))
          .catch((error) => {
            console.log("error while getting chapter: ", error);
          })
          .finally(() => (this.isLoadingChapter = false));
      }
    },
  },
  computed: {
    ...mapGetters(["isLoadingChapters", "savedChapter", "savedLanguage"]),
    groupId: {
      get() {
        return this.$route.params.groupId;
      },
      set(newId) {
        return newId;
      },
    },
    chapterId() {
      this.$route.params.chapterId;
    },
  },
  watch: {
    $route(to, from) {
      if (
        Number(to.params.chapterId) !== Number(from.params.chapterId) &&
        this.chapterId !== to.params.chapterId
      ) {
        this.getChapter(to.params.chapterId, undefined, this.savedLanguage);
        if (to.params.groupId !== from.params.groupId) {
          this.groupId = to.params.groupId;
        }
      }
      if (to.params.groupId !== from.params.groupId) {
        this.groupId = to.params.groupId;
      }
    },
  },

  created() {
    this.language = this.savedLanguage || "en";
    this.getChapter(this.$route.params.chapterId, undefined, this.savedChapter);
  },
  components: {
    Verse,
    Footer,
    Header,
    Loading,
  },
};
</script>
