<template>
  <c-box h="100%" :minHeight="!isMobile ? '90vh' : ''">
    <c-box
      v-bind="mainStyles[colorMode]"
      :h="isMobile ? '90%' : '100%'"
      :overflow="isMobile ? 'scroll' : ''"
      id="content-scroll"
      :minHeight="!isMobile ? '90vh' : ''"
    >
      <mq-layout mq="mobile" style="padding-top: 2vw">
        <share-download-bookmark
          :chapter="chapter"
          :language="language"
          :verseGroup="verse"
          :textDirection="textDirection"
        />
      </mq-layout>
      <c-box class="container">
        <c-flex justify="space-between" align="center">
          <c-heading :class="`heading`">
            {{ `${chapter.chapter_id}. ` }}
            <span :class="languageFilter">{{ `${chapter.name}` }}</span>
          </c-heading>
          <mq-layout mq="desktop">
            <share-download-bookmark
              :chapter="chapter"
              :language="languageFilter"
              :verseGroup="verse"
              :textDirection="textDirection"
            />
          </mq-layout>
        </c-flex>
        <read-introduction
          :close="closeReadIntroduction"
          :isOpen="readIntroduction"
        />
        <mq-layout mq="desktop">
          <c-flex direction="column">
            <c-box v-if="chapter">
              <c-box
                mt="0.35vw"
                id="filter-collapse-container"
                :style="{
                  backgroundColor: colorMode === 'dark' ? 'black' : 'white',
                  position: verse === 'all' ? 'sticky' : '',
                  top: '0',
                  zIndex: '10',
                }"
              >
                <c-box
                  variant-color="blue"
                  @click="showFilters = !showFilters"
                  :class="`golden-bg`"
                  px="1vw"
                  border="0.25vw solid #c1a885"
                  cursor="pointer"
                  borderRadius="1vw"
                >
                  <c-flex align="center" justify="space-between">
                    <c-text mb="0 !important" fontWeight="bold">Filters</c-text>
                    <c-flex align="center">
                      <c-icon
                        w="2vw"
                        h="2vw"
                        :name="showFilters ? 'chevron-up' : 'chevron-down'"
                      />
                    </c-flex>
                  </c-flex>
                </c-box>
                <c-collapse
                  :starting-height="0"
                  :is-open="showFilters"
                  pt="0.5vw"
                  style="overflow-y: visible !important"
                >
                  <c-flex direction="column" w="100%" v-if="showFilters">
                    <c-flex w="100%" :justify="'space-evenly'" mb="0.75vw">
                      <c-flex
                        :w="'80%'"
                        :style="{
                          transition: '1s',
                        }"
                      >
                        <c-flex direction="column" mr="2" w="40%">
                          <c-flex w="100%">
                            <c-text class="filter-label">Chapters</c-text>
                            <c-icon
                              name="chevron-down"
                              size="1.45vw"
                              :color="colorMode === 'dark' ? 'white' : 'black'"
                            />
                          </c-flex>
                          <dropdown-menu
                            :withDropdownCloser="true"
                            :class="[
                              colorMode == 'dark' ? 'box-dark' : '',
                              'filter-container custom-select',
                            ]"
                          >
                            <button slot="trigger">
                              {{ `${chapter.chapter_id}. ${chapter.name}` }}
                            </button>
                            <ul slot="body">
                              <li
                                v-for="(chapterFilter, index) in chapters"
                                :key="`${chapterFilter.chapter_id}-${index}`"
                                :class="
                                  chapter.chapter_id ===
                                  chapterFilter.chapter_id
                                    ? 'golden-bg'
                                    : ''
                                "
                              >
                                <c-box
                                  dropdown-closer
                                  class="filter-value"
                                  @click="
                                    chapterIdFilters = String(
                                      String(chapterFilter.chapter_id)
                                    )
                                  "
                                >
                                  {{
                                    `${chapterFilter.chapter_id}. ${chapterFilter.name}`
                                  }}
                                </c-box>
                              </li>
                            </ul>
                          </dropdown-menu>
                        </c-flex>
                        <c-flex
                          direction="column"
                          mx="2"
                          :w="verse === 'all' ? '20%' : '15%'"
                        >
                          <c-flex align="center">
                            <c-text class="filter-label">Verse No</c-text>
                            <c-icon
                              name="chevron-down"
                              size="1.45vw"
                              :color="colorMode === 'dark' ? 'light' : 'black'"
                            />
                          </c-flex>
                          <dropdown-menu
                            :withDropdownCloser="true"
                            :class="[
                              colorMode == 'dark' ? 'box-dark' : '',
                              'filter-container custom-select',
                            ]"
                          >
                            <button
                              slot="trigger"
                              style="text-transform: capitalize"
                            >
                              {{
                                verse.split(" - ")[0] === verse.split(" - ")[1]
                                  ? verse.split(" - ")[0]
                                  : verse
                              }}
                            </button>
                            <ul slot="body">
                              <li :class="verse === 'all' ? 'golden-bg' : ''">
                                <c-box
                                  dropdown-closer
                                  class="filter-value"
                                  @click="
                                    () => {
                                      verse = 'all';
                                    }
                                  "
                                >
                                  All
                                </c-box>
                              </li>
                              <li
                                v-for="(item, index) in chapter.verses"
                                :key="`verse-${item.id}-${index}`"
                                :class="
                                  verse === getVerseGroupFromVerseId(item.id)
                                    ? 'golden-bg'
                                    : ''
                                "
                              >
                                <c-box
                                  dropdown-closer
                                  class="filter-value"
                                  @click="
                                    () => {
                                      verse = getVerseGroupFromVerseId(item.id);
                                    }
                                  "
                                >
                                  {{ item.id }}
                                </c-box>
                              </li>
                            </ul>
                          </dropdown-menu>
                        </c-flex>
                        <c-flex
                          direction="column"
                          mx="2"
                          :w="verse === 'all' ? '20%' : '15%'"
                        >
                          <c-flex align="center">
                            <c-text class="filter-label">Language</c-text>
                            <c-icon
                              name="chevron-down"
                              size="1.45vw"
                              :color="colorMode === 'dark' ? 'light' : 'black'"
                            />
                          </c-flex>
                          <dropdown-menu
                            :withDropdownCloser="true"
                            :class="[
                              colorMode == 'dark' ? 'box-dark' : '',
                              'filter-container custom-select',
                            ]"
                          >
                            <button
                              slot="trigger"
                              style="text-transform: uppercase"
                            >
                              {{ languageFilter }}
                            </button>
                            <ul slot="body">
                              <li
                                v-for="(i, index) in Object.keys(
                                  this.chapter.languages
                                )"
                                :key="`${i}-${index}`"
                                :class="languageFilter === i ? 'golden-bg' : ''"
                              >
                                <c-box
                                  style="text-transform: uppercase"
                                  dropdown-closer
                                  class="filter-value"
                                  @click="languageFilter = i"
                                >
                                  {{ i }}
                                </c-box>
                              </li>
                            </ul>
                          </dropdown-menu>
                        </c-flex>
                        <c-flex
                          direction="column"
                          mx="2"
                          :w="verse === 'all' ? '20%' : '15%'"
                        >
                          <c-flex align="center">
                            <c-text class="filter-label">Font Size</c-text>
                            <c-icon
                              name="chevron-down"
                              size="1.45vw"
                              :color="colorMode === 'dark' ? 'light' : 'black'"
                            />
                          </c-flex>
                          <dropdown-menu
                            :withDropdownCloser="true"
                            :class="[
                              colorMode == 'dark' ? 'box-dark' : '',
                              'filter-container custom-select',
                            ]"
                          >
                            <button slot="trigger">
                              {{ fontSize }}
                            </button>
                            <ul slot="body">
                              <li
                                v-for="(item, index) in fontSizes"
                                :key="`${item.value}-${index}`"
                                :class="
                                  fontSize === item.value ? 'golden-bg' : ''
                                "
                              >
                                <c-box
                                  dropdown-closer
                                  class="filter-value"
                                  @click="fontSize = item.value"
                                >
                                  {{ item.value }}
                                </c-box>
                              </li>
                            </ul>
                          </dropdown-menu>
                        </c-flex>
                        <c-flex
                          direction="column"
                          mx="2"
                          w="15%"
                          v-if="verse !== 'all'"
                        >
                          <c-flex align="center">
                            <c-text class="filter-label">View Options</c-text>
                            <c-icon
                              name="chevron-down"
                              size="1.45vw"
                              :color="colorMode === 'dark' ? 'light' : 'black'"
                            />
                          </c-flex>

                          <dropdown-menu
                            :withDropdownCloser="true"
                            :class="[
                              colorMode == 'dark' ? 'box-dark' : '',
                              'filter-container custom-select',
                            ]"
                          >
                            <button
                              slot="trigger"
                              style="text-transform: capitalize"
                            >
                              {{ view }}
                            </button>
                            <ul slot="body">
                              <li
                                v-for="item in [
                                  'paragraph',
                                  'parallel',
                                  'full chapter',
                                ]"
                                :key="item"
                                :class="view === item ? 'golden-bg' : ''"
                              >
                                <c-box
                                  dropdown-closer
                                  class="filter-value"
                                  @click="view = item"
                                  style="text-transform: capitalize"
                                >
                                  {{ item }}
                                </c-box>
                              </li>
                            </ul>
                          </dropdown-menu>
                        </c-flex>
                      </c-flex>

                      <c-link
                        v-if="
                          introduction && Object.keys(introduction).length > 0
                        "
                        w="20%"
                        ml="2"
                        mt="1.45vw"
                        display="flex"
                        :class="[
                          colorMode === 'dark' ? 'box-dark' : '',
                          'filter-container filter-value',
                        ]"
                        @click="openReadIntroduction"
                      >
                        Read Introduction
                      </c-link>
                    </c-flex>
                    <c-flex mb="0.75vw" w="100%" v-if="audio">
                      <c-flex
                        direction="column"
                        :w="
                          introduction && Object.keys(introduction).length > 0
                            ? '100%'
                            : '100%'
                        "
                      >
                        <c-flex
                          :class="[
                            colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container',
                          ]"
                          w="100%"
                          h="100%"
                        >
                          <c-text class="filter-value">Audio</c-text>
                          <c-flex
                            w="80%"
                            textAlign="center"
                            h="100%"
                            justify="space-evenly"
                            align="center"
                          >
                            <av-circle
                              :outline-width="0"
                              :outline-meter-space="5"
                              :playtime="true"
                              :cors-anonym="true"
                              :audio-src="audio"
                              :canv-class="'commentary-visual'"
                              style="height: 100%; width: 100%"
                              class="audio-player-chapter"
                            />
                            <!-- <c-text fontSize="1vw" fontWeight="bold" v-else>
                        No Audio Available
                      </c-text> -->
                          </c-flex>
                        </c-flex>
                      </c-flex>
                    </c-flex>
                    <hr
                      style="
                        border-width: 2px;
                        z-index: 100;
                        border-color: #c1a885;
                      "
                    />
                  </c-flex>
                </c-collapse>
              </c-box>
              <c-flex direction="column" px="0.5vw" my="0.25vw">
                <c-flex
                  align="center"
                  :pt="
                    verse === 'all'
                      ? chapter.verse_title
                        ? '1.25vw'
                        : 0
                      : '0.35vw'
                  "
                  position="relative"
                >
                  <c-heading
                    class="heading"
                    mb="0 !important"
                    v-if="verse !== 'all'"
                  >
                    Verse
                    {{
                      verse.split(" - ")[0] === verse.split(" - ")[1]
                        ? verse.split(" - ")[1]
                        : verse
                    }}
                  </c-heading>
                  <c-text
                    align="center"
                    fontWeight="bold"
                    color="#c1a885"
                    position="absolute"
                    alignSelf="center"
                    w="100%"
                    v-if="
                      chapter.verse_title &&
                      (isFirstVerseGroup || verse === 'all')
                    "
                    :class="languageFilter"
                  >
                    {{ chapter.verse_title }}
                  </c-text>
                </c-flex>

                <c-flex w="100%" mt="0.25vw">
                  <c-flex
                    :style="
                      view === 'parallel' ? { width: '50%' } : { width: '100%' }
                    "
                  >
                    <VerseContent
                      :verses="verses"
                      :fontSize="fontSize"
                      languageKey="name"
                      :languageLabel="languageFilter"
                      :verseGroupList="
                        verse === 'all' ? this.chapter.verse_group_list : []
                      "
                      :pr="view === 'parallel' ? '1.25vw' : '0'"
                      :commentary="
                        verse === 'all' ? this.chapter.commentary : {}
                      "
                      :direction="textDirection"
                      :verse="verse"
                    />
                  </c-flex>
                  <c-divider
                    v-show="view === 'parallel'"
                    orientation="vertical"
                    borderWidth="5px"
                    borderColor="#c1a885"
                    opacity="1"
                  />
                  <c-flex w="50%" v-show="view === 'parallel'">
                    <VerseContent
                      :verses="verses"
                      :fontSize="fontSize"
                      :languageKey="'parallel_text'"
                      :languageLabel="'ar'"
                      pl="1.25vw"
                      :direction="parallelTextDirection"
                      :verse="verse"
                    />
                  </c-flex>
                </c-flex>
              </c-flex>
              <c-flex mb="1vw">
                <verse-quick-actions
                  :goToBeginningOfSurah="goToBeginningOfSurah"
                  :goToNextVerse="goToNextVerse"
                  :goToPreviousVerse="goToPreviousVerse"
                  :isLastVerseGroup="isLastVerseGroup"
                  :isFirstVerseGroup="isFirstVerseGroup"
                  :isFirstChapter="isFirstChapter"
                  :isLastChapter="isLastChapter"
                  :previousChapterName="previousChapterName"
                  :nextChapterName="nextChapterName"
                  :nextVerseGroup="nextVerseGroup"
                  :previousVerseGroup="previousVerseGroup"
                  :verse="verse"
                />
              </c-flex>
              <commentary
                v-if="
                  verse !== 'all' &&
                  commentary &&
                  (commentary.audio || commentary.text)
                "
                :commentary="commentary"
                :fontSize="fontSize"
                :verseGroup="verse"
              />

              <c-flex my="1vw" class="related-media-collapsible-container">
                <c-flex
                  v-if="isLoadingRelatedMedia"
                  justify="center"
                  align="center"
                  w="100%"
                  textAlign="center"
                >
                  <c-text mr="1vw" color="#c1a885" fontWeight="bolder">
                    Loading Related Media
                  </c-text>
                  <c-box> <loading size="md" thickness="3px" /></c-box>
                </c-flex>
                <related-media
                  v-else-if="
                    (verse !== 'all' ||
                      chapter.verse_group_list.length === 1) &&
                    relatedMedia &&
                    relatedMedia.length > 0 &&
                    !isLoadingRelatedMedia
                  "
                  :relatedMedia="relatedMedia"
                  :showSeeAll="true"
                  :seeAllRoute="handleSeeAllRelatedMedia"
                  :collapsable="true"
                  :verseGroup="this.verse"
                />
              </c-flex>
            </c-box>
          </c-flex>
        </mq-layout>
        <mq-layout mq="mobile">
          <c-flex
            direction="column"
            v-bind="mainStyles[colorMode]"
            v-if="chapter !== undefined"
          >
            <c-text
              :mt="verse !== 'all' ? '0.5vw' : '0'"
              align="center"
              fontWeight="bold"
              color="#c1a885"
              alignSelf="center"
              w="100%"
              fontSize="2.75vw"
              :class="languageFilter"
              v-if="
                chapter.verse_title && (isFirstVerseGroup || verse === 'all')
              "
            >
              {{ chapter.verse_title }}
            </c-text>

            <av-circle
              v-if="audio"
              :outline-width="0"
              :outline-meter-space="5"
              :playtime="true"
              :cors-anonym="true"
              :audio-src="audio"
              :canv-class="'commentary-visual'"
              style="height: 7.5vh; width: 100%"
              class="audio-player-chapter"
            />
            <VerseContent
              :verses="verses"
              :fontSize="fontSize"
              languageKey="name"
              :languageLabel="languageFilter"
              :verseGroupList="
                verse === 'all' ? this.chapter.verse_group_list : []
              "
              :pr="view === 'parallel' ? '1.25vw' : '0'"
              :commentary="verse === 'all' ? this.chapter.commentary : {}"
              :direction="textDirection"
              :verse="verse"
            />
            <verse-quick-actions
              :goToBeginningOfSurah="goToBeginningOfSurah"
              :goToNextVerse="goToNextVerse"
              :goToPreviousVerse="goToPreviousVerse"
              :isLastVerseGroup="isLastVerseGroup"
              :isFirstVerseGroup="isFirstVerseGroup"
              :isFirstChapter="isFirstChapter"
              :isLastChapter="isLastChapter"
              :previousChapterName="previousChapterName"
              :nextChapterName="nextChapterName"
              :nextVerseGroup="nextVerseGroup"
              :previousVerseGroup="previousVerseGroup"
              :verse="verse"
            />
            <commentary
              v-if="
                verse !== 'all' &&
                commentary &&
                (commentary.audio || commentary.text)
              "
              :commentary="commentary"
              :fontSize="fontSize"
              :verseGroup="verse"
            />
            <c-flex
              v-if="isLoadingRelatedMedia"
              justify="center"
              align="center"
              w="100%"
              textAlign="center"
            >
              <c-text mr="1vw" color="#c1a885" fontWeight="bolder">
                Loading Related Media
              </c-text>
              <c-box> <loading size="md" thickness="3px" /></c-box>
            </c-flex>
            <c-flex
              v-else-if="relatedMedia && relatedMedia.length > 0"
              class="related-media-collapsible-container"
            >
              <related-media
                :relatedMedia="relatedMedia"
                :showSeeAll="true"
                :seeAllRoute="handleSeeAllRelatedMedia"
                :collapsable="true"
                :verseGroup="this.verse"
              />
            </c-flex>
          </c-flex>
          <view-options
            :close="closeViewOptionsModal"
            :show="showViewOptions"
            :fontSize="fontSize"
            :fontSizes="fontSizes"
            :languages="chapter.languages"
            :language="languageFilter"
            :verse="verse"
            :view="view"
            :colorMode="colorMode"
            :chapter="chapter"
            :setLanguage="(value) => (languageFilter = value)"
            :setFontSize="setFontSize"
            :setView="(value) => (view = value)"
          />
          <chapter-options
            :close="closeChapterOptionsModal"
            :show="showChapterOptions"
            :chapters="chapters"
            :chapterIdFilters="chapterIdFilters"
            :setChapterId="setChapterId"
            :verse_group_list="this.chapter.verse_group_list"
            :setVerse="setVerse"
            :verse="verse"
            :openReadIntroduction="openReadIntroduction"
            :colorMode="colorMode"
            :chapter="chapter"
            :getVerseGroupFromVerseId="getVerseGroupFromVerseId"
          />
          <parallel-verse
            :close="
              () => {
                openMobileParallel = false;
                view = 'paragraph';
              }
            "
            :isOpen="openMobileParallel"
            :chapter="chapter"
            :fontSize="fontSize"
            :languageFilter="languageFilter"
            :parallelTextDirection="parallelTextDirection"
            :textDirection="textDirection"
            :verses="verses"
            :view="view"
            :verse="verse"
            :commentary="commentary"
            :goToBeginningOfSurah="goToBeginningOfSurah"
            :goToNextVerse="goToNextVerse"
            :goToPreviousVerse="goToPreviousVerse"
            :isLastVerseGroup="isLastVerseGroup"
            :isFirstVerseGroup="isFirstVerseGroup"
            :isFirstChapter="isFirstChapter"
            :isLastChapter="isLastChapter"
            :previousChapterName="previousChapterName"
            :nextChapterName="nextChapterName"
            :nextVerseGroup="nextVerseGroup"
            :previousVerseGroup="previousVerseGroup"
          />
        </mq-layout>
      </c-box>
    </c-box>
    <mq-layout mq="mobile" style="height: 10%">
      <c-simple-grid
        :columns="2"
        w="100%"
        h="100%"
        class="bottom-nav-container"
        :backgroundColor="'white'"
      >
        <c-flex
          justify="center"
          align="center"
          borderRight="1px solid black"
          position="relative"
        >
          <c-image
            :src="require('@/assets/icons/book.png')"
            size="40%"
            objectFit="contain"
            position="absolute"
            zIndex="1000"
            left="2vw"
          />
          <button-outline
            text="Chapters"
            :click="showChapterOptionsModal"
            :backgroundColor="'black !important'"
            :style="{ 'z-index': '10000', width: '100%', height: '100%' }"
          />
        </c-flex>
        <c-flex justify="center" align="center" position="relative">
          <c-image
            :src="require('@/assets/icons/chapter.png')"
            size="40%"
            objectFit="contain"
            position="absolute"
            zIndex="1000"
            left="2vw"
          />
          <button-outline
            text="Options"
            :click="showViewOptionsModal"
            :style="{ 'z-index': '10000', width: '100%', height: '100%' }"
          />
        </c-flex>
      </c-simple-grid>
    </mq-layout>
  </c-box>
</template>

<style>
@import "./styles.css";
</style>

<script>
import { CFlex, CAccordionIcon } from "@chakra-ui/vue";
import Loading from "@/components/CustomComponents/Loading.vue";
import VerseContent from "@/components/CustomComponents/VerseContent/index.vue";
import RelatedMedia from "../../../CustomComponents/RelatedMedia.vue";
import ShareDownloadBookmark from "../../../CustomComponents/ShareDownloadBookmark.vue";
import ReadIntroduction from "../../../CustomComponents/Modals/ReadIntroduction.vue";
import { FONT_SIZES, MAIN_STYLES } from "../../../../constants/index";
import { mapGetters } from "vuex";
import { getRelatedMedia } from "../../../../apis";
import Commentary from "../../../CustomComponents/Commentary.vue";
import ButtonOutline from "../../../CustomComponents/ButtonOutline.vue";
import CustomButton from "../../../CustomComponents/CustomButton.vue";
import ViewOptions from "../../MobileView/ViewOptions.vue";
import VerseQuickActions from "../../../CustomComponents/VerseQuickActions.vue";
import ChapterOptions from "../../MobileView/ChapterOptions.vue";
import { updateParams } from "../../../../helper";
import SubscribeForm from "../../../CustomComponents/SubscribeForm.vue";
import "v-dropdown-menu/dist/v-dropdown-menu.css"; // Base style, required.
import { isMobile } from "mobile-device-detect";
import ParallelVerse from "../../MobileView/ParallelVerse.vue";

export default {
  name: "Verse",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  props: [
    "chapter",
    "groupId",
    "getChapter",
    "language",
    "setLanguage",
    "isLoadingChapter",
  ],
  data: function () {
    return {
      showViewOptions: "closed",
      showChapterOptions: "closed",
      readIntroduction: false,
      verse: "all",
      view: "paragraph",
      mainStyles: MAIN_STYLES,
      isLoadingRelatedMedia: false,
      relatedMedia: [],
      fontSize: "16px",
      fontSizes: FONT_SIZES,
      showFilters: false,
      isMobile,
      openMobileParallel: false,
    };
  },
  components: {
    CFlex,
    SubscribeForm,
    CAccordionIcon,
    Loading,
    VerseContent,
    ShareDownloadBookmark,
    VerseQuickActions,
    RelatedMedia,
    ButtonOutline,
    ReadIntroduction,
    ShareDownloadBookmark,
    Commentary,
    CustomButton,
    ViewOptions,
    ChapterOptions,
    ParallelVerse,
    VerseQuickActions,
    ButtonOutline,
  },
  computed: {
    ...mapGetters([
      "introduction",
      "chapters",
      "chapterId",
      "savedGroupId",
      "savedLanguage",
    ]),
    nextVerseGroup() {
      if (this.chapter && this.chapter.verse_group_list) {
        const currentGroup = Number(this.verse.split(" - ")[1]);
        const i = this.chapter.verse_group_list.indexOf(currentGroup);
        return `${currentGroup + 1} - ${this.chapter.verse_group_list[i + 1]}`;
      }
      return "";
    },
    previousVerseGroup() {
      if (this.chapter && this.chapter.verse_group_list) {
        const currentGroup = Number(this.verse.split(" - ")[0]) - 1;
        const i = this.chapter.verse_group_list.indexOf(currentGroup);
        return `${
          i ? this.chapter.verse_group_list[i - 1] : 1
        } - ${currentGroup}`;
      }
      return "";
    },
    nextChapterName() {
      return (
        !this.isLastChapter && this.chapters[Number(this.chapterIdFilters)].name
      );
    },
    previousChapterName() {
      return (
        !this.isFirstChapter &&
        this.chapters[Number(this.chapterIdFilters) - 2].name
      );
    },
    isFirstChapter() {
      return Number(this.chapterIdFilters) === 1;
    },
    isLastChapter() {
      return Number(this.chapterIdFilters) === this.chapters.length;
    },
    isLastVerseGroup() {
      return (
        this.verse !== "all" &&
        Number(this.verse.split(" - ")[1]) ===
          this.chapter.verse_group_list[
            this.chapter.verse_group_list.length - 1
          ]
      );
    },
    isFirstVerseGroup() {
      return (
        this.verse !== "all" &&
        Number(this.verse.split(" - ")[1]) === this.chapter.verse_group_list[0]
      );
    },
    parallelTextDirection() {
      return this.chapter.languages["ar"].direction;
    },
    textDirection() {
      console.log("This.language: ", this.languageFilter);
      return this.chapter.languages[this.languageFilter].direction;
    },

    languageFilter: {
      get() {
        return this.language;
      },
      set(newVal) {
        this.showFilters = false;
        this.setLanguage(newVal);
        return newVal;
      },
    },
    chapterIdFilters: {
      get() {
        return this.chapterId;
      },
      set(newId) {
        updateParams(this.$router, {
          chapterId: newId,
          groupId: "all",
        });
        return newId;
      },
    },
    audio: {
      get() {
        if (this.verse !== "all") {
          const audio =
            this.chapter.audio_file &&
            this.chapter.audio_file[this.verse.split(" - ")[1]];
          return audio || undefined;
        } else {
          return this.chapter.chapter_audio;
        }
      },
      set(newAudio) {
        return newAudio;
      },
    },
    commentary() {
      try {
        if (this.verse === "all") {
          return undefined;
        }
        return this.chapter.commentary[this.verse.split(" - ")[1]];
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },

    verses() {
      if (this.verse === "all") {
        return this.chapter.verses;
      } else {
        const indexes = this.verse.split(" - ");
        return this.chapter.verses.slice(
          Number(indexes[0]) - 1,
          Number(indexes[1])
        );
      }
    },
    colorMode() {
      return this.$chakraColorMode();
    },
  },
  created() {
    this.saveChapterToStore(this.chapter);
    this.verse = this.getVerseGroupFromVerseId(this.groupId);
  },
  watch: {
    chapter(newVal, oldVal) {
      this.saveChapterToStore(newVal);
      this.saveChapterToStore(newVal);
      this.scrollToTop();
    },
    view(newVal, oldVal) {
      if (this.isMobile) {
        newVal === "parallel" && (this.openMobileParallel = true);
        this.showViewOptions === "open" && this.closeViewOptionsModal();
      }
      newVal === "full chapter" && (this.verse = "all");
    },
    chapterIdFilters(newVal, oldVal) {
      this.verse = "all";
      this.scrollToTop();
      this.showFilters = false;
    },
    language(newVal, oldVal) {
      if (this.isMobile) {
        this.showViewOptions === "open" && this.closeViewOptionsModal();
      }
      this.$store.dispatch("loadChapters", [undefined, undefined, newVal]);
      this.storeLangaugeToStore(newVal);
      this.getChapter(this.chapterIdFilters, undefined, newVal);
    },

    groupId(newVal, oldVal) {
      console.log("New val here: ", newVal);
      this.verse !== "all" &&
        newVal !== this.verse.split(" - ")[1] &&
        (this.verse = this.getVerseGroupFromVerseId(newVal));
    },
    verse(newVerse, oldVerse) {
      if (
        (this.savedGroupId !== newVerse) === "all"
          ? newVerse
          : newVerse.split(" - ")[1]
      ) {
        this.$store.dispatch("saveGroup", [
          newVerse === "all" ? newVerse : newVerse.split(" - ")[1],
        ]);
      }

      ((newVerse !== "all" &&
        (this.view !== "paragraph" || this.view !== "parallel")) ||
        (newVerse === "all" && this.view !== "paragraph")) &&
        (this.view = "paragraph");
      updateParams(this.$router, {
        chapterId: this.chapterId,
        groupId: newVerse === "all" ? newVerse : newVerse.split(" - ")[1],
      });
      if (newVerse !== oldVerse) {
        this.scrollToTop();
      }
      this.handleGetRelatedMedia(newVerse);
    },
  },

  methods: {
    saveChapterToStore(chapter) {
      if (String(chapter.chapter_id) !== this.chapterId) {
        this.$store.dispatch("saveChapter", [chapter]);
      }
    },
    storeLangaugeToStore(language) {
      if (this.savedLanguage !== this.language) {
        this.$store.dispatch("saveLanguage", [language]);
      }
    },
    handleGetRelatedMedia(newVerse = undefined) {
      const verse = newVerse || this.verse;
      if (verse === "all" && this.chapter.verse_group_list.length > 1) {
        // this.view !== "paragraph" && (this.view = "paragraph");
        this.relatedMedia !== [] && (this.relatedMedia = []);
      } else if (
        (verse !== "all" && this.chapter.verse_group_list.length > 1) ||
        (verse === "all" && this.chapter.verse_group_list.length === 1)
      ) {
        this.isLoadingRelatedMedia = true;
        getRelatedMedia(
          this.chapterIdFilters,
          this.chapter.verse_group_list.length === 1
            ? this.chapter.verse_group_list[0]
            : this.verse.split(" - ")[1]
        )
          .then((result) => (this.relatedMedia = result.data))
          .catch((error) => {
            console.log("error while fetching related media: ", error);
            this.relatedMedia = undefined;
          })
          .finally(() => (this.isLoadingRelatedMedia = false));
      }
    },
    handleSeeAllRelatedMedia() {
      this.$router.push({
        name: "Media",
        params: {
          chapterId: this.chapterIdFilters,
          groupId: this.verse !== "all" ? this.verse.split(" - ")[1] : "all",
        },
      });
    },
    scrollToTop() {
      if (isMobile) {
        var myDiv = document.getElementById("content-scroll");
        myDiv && (myDiv.scrollTop = 0);
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    getVerseGroupFromVerseId(id) {
      if (id) {
        if (id !== "all") {
          for (var i = 0; i < this.chapter.verse_group_list.length; i++) {
            if (this.chapter.verse_group_list[i] >= id) {
              return `${
                i === 0 ? 1 : this.chapter.verse_group_list[i - 1] + 1
              } - ${this.chapter.verse_group_list[i]}`;
            }
          }
        }
        return "all";
      }
    },
    getVerseValueFromVerseGroupId(id) {
      // Remove this function
      if (id !== "all") {
        const i = this.chapter.verse_group_list.indexOf(Number(id));
        return i === 0
          ? `1 - ${id}`
          : `${this.chapter.verse_group_list[i - 1] + 1} - ${id}`;
      }
      return id;
    },
    setFontSize(value) {
      this.fontSize = value;
    },

    setChapterId(id) {
      this.chapterIdFilters = id;
    },
    setVerse(id) {
      this.verse = id;
      this.showChapterOptions && this.closeChapterOptionsModal();
    },
    goToFullChapter() {
      this.verse = "all";
    },
    goToNextVerse() {
      if (this.verse === "all" && !this.isLastChapter) {
        return this.goToNextChapter();
      }
      if (!this.isLastVerseGroup) {
        const activeGroup = Number(this.verse.split(" - ")[1]);
        const indexOfActiveGroup =
          this.chapter.verse_group_list.indexOf(activeGroup);
        if (indexOfActiveGroup > -1) {
          this.verse = `${activeGroup + 1} - ${
            this.chapter.verse_group_list[indexOfActiveGroup + 1]
          }`;
        }
      } else if (!this.isLastChapter) {
        this.goToNextChapter();
      }
    },
    goToPreviousVerse() {
      if (this.verse === "all" && !this.isFirstChapter) {
        return this.goToPreviousChapter();
      }
      if (!this.isFirstVerseGroup) {
        const activeGroup = Number(this.verse.split(" - ")[0]) - 1;
        const indexOfActiveGroup =
          this.chapter.verse_group_list.indexOf(activeGroup);
        if (indexOfActiveGroup > -1) {
          this.verse = `${
            indexOfActiveGroup > 0
              ? this.chapter.verse_group_list[indexOfActiveGroup - 1] + 1
              : 1
          } - ${activeGroup}`;
        }
      } else if (this.isFirstVerseGroup) {
        this.goToFullChapter();
      } else if (!this.isFirstChapter || this.verse === "all") {
        this.goToPreviousChapter();
      }
    },
    goToNextChapter() {
      !this.isLastChapter &&
        (this.chapterIdFilters = Number(this.chapterIdFilters) + 1);
    },
    goToPreviousChapter() {
      !this.isFirstChapter &&
        (this.chapterIdFilters = Number(this.chapterIdFilters) - 1);
    },

    goToBeginningOfSurah() {
      this.verse = `1 - ${this.chapter.verse_group_list[0]}`;
    },
    showChapterOptionsModal() {
      this.showChapterOptions = "open";
    },
    closeChapterOptionsModal() {
      this.showChapterOptions = "closed";
    },
    showViewOptionsModal() {
      this.showViewOptions = "open";
    },
    closeViewOptionsModal() {
      this.showViewOptions = "closed";
    },
    openReadIntroduction() {
      if (this.introduction && Object.keys(this.introduction).length > 0) {
        this.readIntroduction = true;
        this.showChapterOptions && this.closeChapterOptionsModal();
      }
    },
    closeReadIntroduction() {
      this.readIntroduction = false;
    },
  },
};
</script>
