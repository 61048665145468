<template>
  <c-box h="100%">
    <loading v-if="isLoadingArticles" />
    <c-box v-else-if="!isLoadingArticles">
      <Header />
      <c-box class="container">
        <c-heading class="heading">{{ mediaType }}</c-heading>
        <mq-layout mq="desktop">
          <c-simple-grid :columns="3" :spacing="'3.5vw'" mt="1vw">
            <tile
              v-for="(i, index) in articles"
              :key="index"
              :media="i"
              v-bind:handleClick="handleReadMore"
            />
          </c-simple-grid>
          <infinite-loading @infinite="loadMore" v-if="true">
            <div slot="no-more">No more message</div>
            <div
              slot="no-results"
              style="margin-top: 2vw; color: #c1a885; font-weight: bold"
            >
              You've reached the end
            </div>
          </infinite-loading>
        </mq-layout>
        <mq-layout mq="mobile">
          <c-simple-grid :columns="2" :spacing="'3vw'">
            <c-box v-for="(i, index) in articles" :key="index" mb="5vw">
              <tile :media="i" />
            </c-box>
          </c-simple-grid>
          <infinite-loading @infinite="loadMore" v-if="true">
            <div slot="no-more">No more message</div>
            <div
              slot="no-results"
              style="margin-top: 5vw; margin-bottom: 5vw; color: #c1a885"
            >
              You have reached the end
            </div>
          </infinite-loading>
        </mq-layout>
      </c-box>
      <Footer />
    </c-box>
    <audio-video
      :close="closeAudioVideo"
      :media="audioVideoItem"
      :isOpen="showAudioVideoModal"
    />
  </c-box>
</template>
<script>
import { getRelatedMediaAll } from "../apis";
import Footer from "../components/CustomComponents/Footer.vue";
import Header from "../components/CustomComponents/Header.vue";
import Loading from "../components/CustomComponents/Loading.vue";
import RelatedMedia from "../components/CustomComponents/RelatedMedia.vue";
import Tile from "../components/CustomComponents/Tile.vue";
import InfiniteLoading from "vue-infinite-loading";
import AudioVideo from "../components/CustomComponents/Modals/AudioVideo.vue";

export default {
  components: {
    Loading,
    RelatedMedia,
    Header,
    Footer,
    Tile,
    InfiniteLoading,
    AudioVideo,
  },
  name: "MediaSubPage",
  data() {
    return {
      isLoadingArticles: true,
      articles: undefined,
      chapterId: undefined,
      groupId: undefined,
      mediaType: this.$route.params.mediaType,
      count: 0,
      pageNo: 1,
      busy: false,
      audioVideoItem: undefined,
      showAudioVideoModal: false,
    };
  },
  computed: {
    hasMore() {
      return this.articles && this.articles.length < this.count;
    },
  },
  methods: {
    async getArticles() {
      return await getRelatedMediaAll(
        this.chapterId || 1,
        this.groupId || 7,
        this.mediaType,
        this.pageNo
      )
        .then((result) => {
          return {
            count: result.data[`total_${this.mediaType}`],
            articles: result.data[this.mediaType],
          };
        })
        .catch((error) => {
          console.log("error while loading: ", error);
        })
        .finally(
          () =>
            (this.isLoadingArticles = true && (this.isLoadingArticles = false))
        );
    },
    loadMore($state) {
      console.log("Load more here: ");
      this.busy = true;
      this.pageNo = this.pageNo + 1;
      console.log("This hasmore: ", this.hasMore);

      this.getArticles()
        .then((result) => {
          this.articles = this.articles.concat(result.articles);
          console.log(this.articles.length < this.count);
          $state.complete();
        })
        .catch((error) => {
          console.log("error while fetching articles: ", error);
          this.busy = false;
        });
    },
    handleReadMore(item) {
      this.type &&
        !item.type &
          (item.type = this.type.charAt(0).toUpperCase() + this.type.slice(1));
      if (
        (this.type && (this.type === "audio" || this.type === "video")) ||
        item.type === "audio" ||
        item.type === "video"
      ) {
        this.openAudioVideo(item);
      }
    },
    openAudioVideo(item) {
      this.audioVideoItem = item;
      this.showAudioVideoModal = true;
    },
    closeAudioVideo() {
      this.audioVideoItem = undefined;
      this.showAudioVideoModal = false;
    },
  },
  created() {
    this.getArticles().then((result) => {
      this.count = result.count;
      this.articles = result.articles;
    });
  },
};
</script>
