<template>
  <vue-simple-suggest
    :list="getList"
    :filter-by-query="false"
    value-attribute="details"
    display-attribute="details"
    ref="suggestComponent"
    :debounce="500"
    :min-length="3"
    @request-start="onRequestStart"
    @request-done="onRequestDone"
    @request-failed="onRequestFailed"
    @suggestion-click="onSuggestClick"
    @select="onSuggestSelect"
    class="asdad"
    pattern="\w+"
    :preventHide="true"
  >
    <input
      type="search"
      placeholder="Search chapter, verse"
      v-model="searchString"
      :style="{
        border: 'none',
        backgroundColor: colorMode === 'dark' ? 'black' : 'white',
        color: colorMode === 'dark' ? 'white' : 'black',
      }"
    />
    <c-spinner
      v-if="loading"
      id="search-spinner"
      speed="0.65s"
      empty-color="green.200"
      color="vue.500"
      thickness="3px"
    />

    <div class="suggestion-item" slot="suggestion-item" slot-scope="scope">
      <c-text>
        Chapter
        <span>{{ ` ${scope.suggestion.chapter_id}` }}</span>
        Verse
        <span>{{ ` ${scope.suggestion.verse_number}` }}</span>
        <span>{{ ` : ${scope.suggestion.title}` }}</span>
      </c-text>
      <c-text v-html="boldenSuggestion(scope)" />
    </div>
  </vue-simple-suggest>
</template>
<style>
@import "./styles.css";
</style>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import { search } from "../../../apis";
import { CSpinner } from "@chakra-ui/vue";

export default {
  name: "Search",
  props: ["colorMode", "close"],
  components: { VueSimpleSuggest, CSpinner },
  data: function () {
    return {
      loading: false,
      page: 0,
      searchString: "",
      identifier: +new Date(),
    };
  },
  methods: {
    onSuggestClick(suggest, e) {
      const verseGroup = Number(suggest.verse_group.split(" - ")[1]);
      const { chapterId, groupId } = this.$router.history.current.params;
      console.log("Current Params Chapter ID: ", chapterId);
      console.log("Current Params group id: ", groupId);
      console.log("Verse group here: ", verseGroup);
      console.log("Chapter id here: ", suggest.chapter_id);
      (Number(chapterId) != Number(suggest.chapter_id) ||
        String(groupId) !== String(verseGroup)) &&
        this.$router.push({
          name: "Read Quran",
          params: {
            chapterId: suggest.chapter_id,
            groupId: verseGroup,
          },
        });
      this.close();
    },
    onSuggestSelect(suggest) {
      console.log("Suggest here: ", suggest);
    },
    onRequestStart(value) {
      this.loading = true;
      console.log("Request started: ", value);
    },
    onRequestDone(e) {
      this.loading = false;
      console.log("Request done: ", e);
    },
    onRequestFailed(e) {
      this.loading = false;
      console.log("Request failed: ", e);
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = suggestion.details;
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|\.]/gm).filter((t) => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    },
    getList(inputValue) {
      return new Promise((resolve, reject) => {
        search(inputValue, this.page, 10).then((result) =>
          resolve(result.data.results)
        );
      });
    },
  },
};
</script>
