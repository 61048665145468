export default {
    breakpoints: ["30em", "48em", "62em", "80em"],
    fonts: {
        heading: "Verdana, serif",
        body: "Verdana, serif",
        mono: "Verdana, monospace",
    },
    colors: {
        palettes: {
            background: "#fff",
            primary: "#10558C",
            primaryGradTop: "#27367B",
            primaryGradBottom: "#0272C4",
            labelLightGrey: "grey",
            gold: "#c1a885",
        },
        buttons: {
            primary: {
                color1: "#0373C4",
                opacity: "0.8",
            },
            danger: {
                color1: "#FF3610",
                color2: "#27367B",
                opacity: "0.8",
            },
        },
    },
};