var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-drawer',{attrs:{"isOpen":_vm.open,"placement":"left","on-close":_vm.close}},[_c('c-drawer-overlay'),_c('c-drawer-content',{attrs:{"w":"75vw"}},[_c('c-drawer-header',{attrs:{"justifyContent":"space-between","display":"flex"}},[_vm._v(" Logo "),_c('c-close-button',{on:{"click":_vm.close}})],1),_c('c-drawer-body',[(_vm.user && _vm.user.user_detail)?_c('c-flex',{attrs:{"direction":"column","align":"center","justify":"center"}},[_c('c-avatar',{attrs:{"name":((_vm.user.user_detail.first_name) + " " + (_vm.user.user_detail.last_name)),"src":_vm.image,"size":"xl"}}),_c('c-text',{attrs:{"textAlign":"center","fontWeight":"bold","mt":"1vw"}},[_vm._v(" "+_vm._s(((_vm.user.user_detail.first_name) + " " + (_vm.user.user_detail.last_name)))+" ")])],1):_vm._e(),_c('c-flex',{attrs:{"align":"center","justify":"center","my":"5vw"}},[_c('custom-button',{attrs:{"fontSize":"3vw !important","click":!_vm.isUserLoggedIn ? _vm.handleGoToLogin : _vm.openLogoutAlert,"text":!_vm.isUserLoggedIn ? 'Login' : 'Logout',"width":'90% !important',"height":'10vw !important',"background":'#c1a885'}})],1),_vm._l(([
          { title: 'Home', route: '/', show: true },
          {
            title: 'Read Quran',
            route: ("/chapters/" + (_vm.chapterId || '1') + "/verse/" + _vm.savedGroupId),
            show: true,
          },
          { title: 'Media', route: '/media', show: true },
          { title: 'Profile', route: '/profile', show: _vm.isUserLoggedIn } ]),function(i,index){return _c('c-box',{key:index,attrs:{"my":"5vw"}},[(i.show)?_c('c-link',{class:("link " + (_vm.currentRouteName.includes(i.title) ? 'active-link' : '')),attrs:{"as":"router-link","to":i.route,"textDecoration":"none !important"}},[_vm._v(" "+_vm._s(i.title)+" ")]):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }