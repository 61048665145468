<template>
  <div class="home">
    <Header />
    <forgot-password />
  </div>
</template>

<script>
import Header from "../components/CustomComponents/Header.vue";
import ForgotPassword from "../components/Layout/Desktopview/ForgotPassword.vue";

export default {
  name: "ForgotPasswordPage",
  components: {
    Header,
    ForgotPassword,
  },
};
</script>
