export const ACTION_TYPES = {
    CHAPTERS: {
        SAVE_CHAPTERS_REQUEST: "SAVE_CHAPTERS_REQUEST",
        SAVE_CHAPTERS_SUCCESS: "SAVE_CHAPTERS_SUCCESS",
        SAVE_CHAPTERS_FAILURE: "SAVE_CHAPTERS_FAILURE",
    },
    RELATED_MEDIA: {
        SAVE_RELATED_MEDIA_SUCCESS: "SAVE_RELATED_MEDIA_SUCCESS",
        SAVE_RELATED_MEDIA_FAILURE: "SAVE_RELATED_MEDIA_FAILURE",
    },
    LOGIN: {
        LOGIN_REQUEST: "LOGIN_REQUEST",
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        LOGIN_FAILURE: "LOGIN_FAILURE",
        LOGIN_CLEAR: "LOGIN_CLEAR",
    },
    SIGNUP: {
        SIGNUP_REQUEST: "SIGNUP_REQUEST",
        SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
        SIGNUP_FAILURE: "SIGNUP_FAILURE",
        SIGNUP_CLEAR: "SIGNUP_CLEAR",
    },
    FORGOT_PASSOWRD: {
        FORGOT_PASSOWRD_REQUEST: "FORGOT_PASSOWRD_REQUEST",
        FORGOT_PASSOWRD_SUCCESS: "FORGOT_PASSOWRD_SUCCESS",
        FORGOT_PASSOWRD_FAILURE: "FORGOT_PASSOWRD_FAILURE",
        FORGOT_PASSOWRD_CLEAR: "FORGOT_PASSOWRD_CLEAR",
    },
    BOOKMARKS: {
        GET_BOOKMARKS_REQUEST: "GET_BOOKMARKS_REQUEST",
        GET_BOOKMARKS_SUCCESS: "GET_BOOKMARKS_SUCCESS",
        GET_BOOKMARKS_FAILURE: "GET_BOOKMARKS_FAILURE",
        ADD_BOOKMARKS_REQUEST: "ADD_BOOKMARKS_REQUEST",
        ADD_BOOKMARKS_SUCCESS: "ADD_BOOKMARKS_SUCCESS",
        ADD_BOOKMARKS_FAILURE: "ADD_BOOKMARKS_FAILURE",
        REMOVE_BOOKMARKS_REQUEST: "REMOVE_BOOKMARKS_REQUEST",
        REMOVE_BOOKMARKS_SUCCESS: "REMOVE_BOOKMARKS_SUCCESS",
        REMOVE_BOOKMARKS_FAILURE: "REMOVE_BOOKMARKS_FAILURE",
    },
    PROFILE: {
        GET_PROFILE_DETAILS_REQUEST: "GET_PROFILE_DETAILS_REQUEST",
        GET_PROFILE_DETAILS_SUCCESS: "GET_PROFILE_DETAILS_SUCCESS",
        GET_PROFILE_DETAILS_FAILURE: "GET_PROFILE_DETAILS_FAILURE",
    },
    LOGOUT: { LOGOUT_REQUEST: "LOGOUT_REQUEST" },
    SAVE_CHAPTER_ID: "SAVE_CHAPTER_ID",
    SAVE_GROUP_ID: "SAVE_GROUP_ID",
    SAVE_LANGUAGE: "SAVE_LANGUAGE",
};