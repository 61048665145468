<template>
  <c-button
    w="100%"
    variant-color="gray"
    :p="isMobile ? 5 : 6"
    :borderRadius="isMobile ? 10 : 12"
  >
    <c-image
      :src="require(`@/assets/${link}.png`)"
      :size="isMobile ? '22.5px' : '30px'"
      :mx="isMobile ? 1 : 2"
    />
    <c-text
      :fontSize="isMobile ? '80%' : '14px'"
      mx="2"
      fontColor="#333337"
      textTransform="capitalize"
    >
      {{ link }}
    </c-text>
  </c-button>
</template>

<script>
import { CButton, CImage } from "@chakra-ui/vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "HelloWorld",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  props: ["link", "text"],
  data: function () {
    return { isMobile };
  },
  components: {
    CButton,
    CImage,
  },
};
</script>
