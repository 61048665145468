<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :closeOnOverlayClick="false"
    size="full"
  >
    <c-modal-content
      class="parallel-mobile"
      v-scroll-lock="isOpen"
      style="overflow-x: scroll; overflow-y: scroll; overflow: scroll"
    >
      <c-modal-header>
        <c-heading :class="`heading ${languageFilter}`">
          {{ `${chapter.chapter_id}. ${chapter.name}` }}
        </c-heading>
        <c-modal-close-button style="top: 92.5% !important" />
      </c-modal-header>
      <c-modal-body style="overflow-x: scroll; padding-right: 1rem !important">
        <c-heading :class="`heading ${languageFilter}`">
          Verse {{ verse }}
        </c-heading>
        <c-flex py="4vw" pr="2.75vw">
          <c-flex flex="5">
            <c-flex flex="1">
              <VerseContent
                :verses="verses"
                :fontSize="fontSize"
                languageKey="name"
                :languageLabel="languageFilter"
                :verseGroupList="
                  verse === 'all' ? this.chapter.verse_group_list : []
                "
                :commentary="verse === 'all' ? this.chapter.commentary : {}"
                :direction="textDirection"
              />
            </c-flex>
            <c-divider
              orientation="horizontal"
              borderWidth="5px"
              borderColor="#c1a885"
              opacity="1"
            />
            <c-flex flex="1">
              <VerseContent
                :verses="verses"
                :fontSize="fontSize"
                :languageKey="'parallel_text'"
                :languageLabel="'ar'"
                :direction="parallelTextDirection"
                :padding="'3vw 0vw'"
              />
            </c-flex>
          </c-flex>

          <!-- <c-flex flex="1"> </c-flex> -->
        </c-flex>
        <!-- <verse-quick-actions
          :goToBeginningOfSurah="goToBeginningOfSurah"
          :goToNextVerse="goToNextVerse"
          :goToPreviousVerse="goToPreviousVerse"
          :isLastVerseGroup="isLastVerseGroup"
          :isFirstVerseGroup="isFirstVerseGroup"
          :isFirstChapter="isFirstChapter"
          :isLastChapter="isLastChapter"
          :previousChapterName="previousChapterName"
          :nextChapterName="nextChapterName"
          :nextVerseGroup="nextVerseGroup"
          :previousVerseGroup="previousVerseGroup"
          :verse="verse"
        /> -->
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import VerseContent from "@/components/CustomComponents/VerseContent/index.vue";
import Commentary from "../../CustomComponents/Commentary.vue";
import VerseQuickActions from "../../CustomComponents/VerseQuickActions.vue";

export default {
  name: "ParallelVerse",
  inject: ["$chakraColorMode"],
  props: [
    "isOpen",
    "close",
    "chapter",
    "view",
    "textDirection",
    "languageFilter",
    "parallelTextDirection",
    "fontSize",
    "verses",
    "verse",
    "commentary",
    "goToBeginningOfSurah",
    "goToNextVerse",
    "goToPreviousVerse",
    "isLastVerseGroup",
    "isFirstVerseGroup",
    "isFirstChapter",
    "isLastChapter",
    "nextChapterName",
    "previousChapterName",
    "nextVerseGroup",
    "previousVerseGroup",
  ],
  data() {
    return {};
  },
  components: { VerseContent, Commentary, VerseQuickActions },
};
</script>
