<template>
  <c-box h="100%">
    <div class="home">
      <Header />
      <c-heading class="heading" textAlign="center" py="27.5vh">
        Home Page
      </c-heading>
      <Footer />
    </div>
  </c-box>
</template>

<script>
import Footer from "@/components/CustomComponents/Footer.vue";
import Header from "../components/CustomComponents/Header.vue";

export default {
  name: "Home",

  components: {
    Footer,
    Header,
  },
};
</script>
