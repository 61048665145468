import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import actions from "./actions";
import { ACTION_TYPES } from "../constants/action-types";
import { isAccesTokenExpired } from "../helper";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    storage: window.localStorage,
    reducer: (state) => ({
        chapters: state.chapters,
        isLoadingChapters: state.isLoadingChapters,
        mode: state.mode,
        introduction: state.introduction,
        isLoggingIn: state.isLoggingIn,
        isSigningUp: state.isSigningUp,
        user: state.user,
        forgettingPasswordError: state.forgettingPasswordError,
        isForgettingPassword: state.isForgettingPassword,
        forgotPasswordSuccess: state.forgotPasswordSuccess,
        signupError: state.signupError,
        chapterId: state.chapterId,
        savedChapter: state.savedChapter,
        savedGroupId: state.savedGroupId,
        savedLanguage: state.savedLanguage,
        bookmarks: state.bookmarks,
        isFetchingBookmarks: state.isFetchingBookmarks,
        fetchBookmarksError: state.fetchBookmarksError,
        addingObjectToBookmarks: state.addingObjectToBookmarks,
        addToBookmarksError: state.addToBookmarksError,
        removingObjectFromBookmarks: state.removingObjectFromBookmarks,
        removeFromBookmarksError: state.removeFromBookmarksError,
        isFetchingProfileDetails: state.isFetchingProfileDetails,
    }),
});

export default new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    state: {
        chapters: undefined,
        isLoadingChapters: false,
        introduction: {},
        loggedIn: false,
        isLoggingIn: false,
        loginError: undefined,
        isSigningUp: false,
        signupError: undefined,
        isForgettingPassword: false,
        forgettingPasswordError: undefined,
        user: undefined,
        chapterId: "1",
        savedGroupId: "all",
        forgotPasswordSuccess: undefined,
        bookmarks: undefined,
        isFetchingBookmarks: false,
        fetchBookmarksError: undefined,
        addingObjectToBookmarks: undefined,
        addToBookmarksError: undefined,
        removingObjectFromBookmarks: undefined,
        removeFromBookmarksError: undefined,
        isFetchingProfileDetails: undefined,
        savedChapter: undefined,
        savedLanguage: "en",
    },
    getters: {
        chapters: (state) => state.chapters,
        isLoadingChapters: (state) => state.isLoadingChapters,
        introduction: (state) => state.introduction,
        isUserLoggedIn: (state) => {
            const loggedIn =
                state.user &&
                state.user.access_token &&
                !isAccesTokenExpired(state.user.access_token);
            !loggedIn && (state.user = undefined);
            return loggedIn;
        },
        isLoggingIn: (state) => state.isLoggingIn,
        isSigningUp: (state) => state.isSigningUp,
        user: (state) => state.user,
        loginError: (state) => state.loginError,
        isForgettingPassword: (state) => state.isForgettingPassword,
        forgettingPasswordError: (state) => state.forgettingPasswordError,
        signupError: (state) => state.signupError,
        chapterId: (state) => state.chapterId,
        savedGroupId: (state) => state.savedGroupId,
        forgotPasswordSuccess: (state) => state.forgotPasswordSuccess,
        bookmarks: (state) => state.bookmarks,
        isFetchingBookmarks: (state) => state.isFetchingBookmarks,
        fetchBookmarksError: (state) => state.fetchBookmarksError,
        addingObjectToBookmarks: (state) => state.addingObjectToBookmarks,
        addToBookmarksError: (state) => state.addToBookmarksError,
        removingObjectFromBookmarks: (state) => state.removingObjectFromBookmarks,
        removeFromBookmarksError: (state) => state.removeFromBookmarksError,
        isFetchingProfileDetails: (state) => state.isFetchingProfileDetails,
        savedChapter: (state) => state.savedChapter,
        savedLanguage: (state) => state.savedLanguage,
    },
    actions,
    mutations: {
        SAVE_CHAPTERS_REQUEST(state) {
            state.isLoadingChapters = true;
        },
        SAVE_CHAPTERS_SUCCESS(state, data) {
            state.chapters = data.chapters;
            state.introduction = data.read_introduction[0];
            state.isLoadingChapters = false;
        },
        SAVE_CHAPTERS_FAILURE(state) {
            state.isLoadingChapters = false;
        },
        [ACTION_TYPES.LOGIN.LOGIN_REQUEST](state) {
            state.loginError = undefined;
            state.isLoggingIn = true;
        },
        [ACTION_TYPES.LOGIN.LOGIN_SUCCESS](state, user) {
            state.loginError = undefined;
            state.user = user;
            state.isLoggingIn = false;
        },
        [ACTION_TYPES.LOGIN.LOGIN_FAILURE](state, error) {
            state.loginError = error;
            state.isLoggingIn = false;
        },
        [ACTION_TYPES.SIGNUP.SIGNUP_REQUEST](state) {
            state.signupError = undefined;
            state.isSigningUp = true;
        },
        [ACTION_TYPES.SIGNUP.SIGNUP_SUCCESS](state, user) {
            state.signupError = undefined;
            state.user = user;
            state.isSigningUp = false;
        },
        [ACTION_TYPES.SIGNUP.SIGNUP_FAILURE](state, error) {
            state.signupError = error;
            state.isSigningUp = false;
        },
        [ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_REQUEST](state) {
            state.forgettingPasswordError = undefined;
            state.isForgettingPassword = true;
            state.forgotPasswordSuccess = undefined;
        },
        [ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_SUCCESS](state, data) {
            state.forgettingPasswordError = undefined;
            state.isForgettingPassword = false;
            state.forgotPasswordSuccess = true;
        },
        [ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_FAILURE](state, error) {
            state.forgettingPasswordError = error;
            state.isForgettingPassword = false;
            state.forgotPasswordSuccess = false;
        },
        [ACTION_TYPES.LOGOUT.LOGOUT_REQUEST](state) {
            state.user = undefined;
        },
        [ACTION_TYPES.LOGIN.LOGIN_CLEAR](state) {
            state.isLoggingIn = false;
            state.loginError = false;
        },
        [ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_CLEAR](state) {
            state.isForgettingPassword = false;
            state.forgettingPasswordError = undefined;
            state.forgotPasswordSuccess = undefined;
        },
        [ACTION_TYPES.SIGNUP.SIGNUP_CLEAR](state) {
            state.isSigningUp = false;
            state.signupError = false;
        },
        [ACTION_TYPES.SAVE_CHAPTER_ID](state, chapter) {
            state.chapterId = chapter.chapter_id;
            state.savedChapter = chapter;
        },
        [ACTION_TYPES.SAVE_GROUP_ID](state, savedGroupId) {
            state.savedGroupId = savedGroupId;
        },
        [ACTION_TYPES.SAVE_LANGUAGE](state, language) {
            state.savedLanguage = language;
        },
        [ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_REQUEST](state) {
            state.isFetchingBookmarks = true;
            state.fetchBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_SUCCESS](state, bookmarks) {
            state.bookmarks = bookmarks;
            state.isFetchingBookmarks = false;
            state.fetchBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_FAILURE](state, error) {
            state.isFetchingBookmarks = false;
            state.fetchBookmarksError = error;
        },
        [ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_REQUEST](state, obj) {
            state.addingObjectToBookmarks = obj;
            state.addToBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_SUCCESS](state, obj) {
            const newBookmarks = Object.assign([], state.bookmarks || []);
            newBookmarks.push(obj);
            state.bookmarks = newBookmarks;
            state.addingObjectToBookmarks = undefined;
            state.addToBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_FAILURE](state, error) {
            state.addToBookmarksError = error;
            state.addingObjectToBookmarks = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_REQUEST](state, obj) {
            state.removingObjectFromBookmarks = obj;
            state.removeFromBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_SUCCESS](state) {
            const newBookmarks = Object.assign([], state.bookmarks || []);
            const indexOfBookmark = newBookmarks.findIndex(
                (el) =>
                el.chapter_id === state.removingObjectFromBookmarks.chapter_id &&
                el.group_id === state.removingObjectFromBookmarks.group_id &&
                el.language === state.removingObjectFromBookmarks.language
            );
            console.log("Index of bookmark: ", indexOfBookmark);
            if (indexOfBookmark !== -1) {
                newBookmarks.splice(indexOfBookmark, 1);
            }
            state.bookmarks = newBookmarks;
            console.log("New Bookmarks here: ", newBookmarks);
            state.removingObjectFromBookmarks = undefined;
            state.removeFromBookmarksError = undefined;
        },
        [ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_FAILURE](state, error) {
            state.removeFromBookmarksError = error;
            state.removingObjectFromBookmarks = undefined;
        },
        [ACTION_TYPES.PROFILE.GET_PROFILE_DETAILS_REQUEST](state) {
            state.isFetchingProfileDetails = true;
        },
        [ACTION_TYPES.PROFILE.GET_PROFILE_DETAILS_SUCCESS](state, user) {
            state.isFetchingProfileDetails = undefined;
            state.user.user_detail = user;
        },
        [ACTION_TYPES.PROFILE.GET_PROFILE_DETAILS_FAILURE](state) {
            state.isFetchingProfileDetails = undefined;
        },
    },
});