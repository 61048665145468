<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :closeOnOverlayClick="false"
    zIndex="10000 !important"
    is-centered
    size="lg"
  >
    <c-modal-content p="0vw !important">
      <c-modal-header>Edit Profile Image</c-modal-header>
      <c-modal-close-button />
      <c-modal-body style="padding: 2vw 1vw">
        <c-box minHeight="100%">
          <UploadImages
            :max="1"
            maxError="Max files exceed"
            style="margin: 0 !important"
            :clearAll="undefined"
            @changed="handleFilesChanged"
          />

          <!-- <file-selector
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
            "
            accept-extensions=".jpg,.jpeg,.png"
            :multiple="false"
            :max-file-size="5 * 1024 * 1024"
            @changed="handleFilesChanged"
          >
            <c-box
              v-if="!image"
              style="
                border: 5px solid #c1a885;
                border-radius: 1vw;
                padding: 3vw 3vw;
                cursor: pointer;
                text-align: center;
              "
            >
              Click to Select Image File Or Drag Image Here
              <c-text align="center" color="#747474" pt="3vw">
                Allowed File Extensions: .JPG, .JPEG, .PNG
              </c-text>
            </c-box>
            <c-box v-else>
              <c-image :src="require(image)" w="10vw" h="10vw" />
            </c-box>
          </file-selector> -->
        </c-box>
        <c-box mt="2vw">
          <custom-button
            :text="'Upload'"
            :click="handleUpload"
            :disabled="!image"
            :isLoading="isUpdatingImage"
          />
        </c-box>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>
<style>
.imgsPreview {
  display: flex;
  justify-content: center;
}
.clearButton,
.plus {
  display: none;
}
</style>

<script>
import CustomButton from "./CustomButton.vue";
import UploadImages from "vue-upload-drop-images";
import { updateProfileImage } from "../../apis";

export default {
  components: { CustomButton, UploadImages },
  name: "ImageUploader",
  props: ["isOpen", "close"],
  data() {
    return { image: undefined, isUpdatingImage: false };
  },
  methods: {
    handleFilesValidated(result, files) {
      console.log("Validation result: " + result);
    },

    handleFilesChanged(files) {
      console.log(files[0]);
      this.image = files[0];
    },
    handleUpload() {
      this.isUpdatingImage = true;
      updateProfileImage(this.image)
        .then((result) => {
          console.log("Result here: ", result);
        })
        .finally(() => (this.isUpdatingImage = false));
    },
  },
};
</script>
