var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',{attrs:{"h":"100%","minHeight":!_vm.isMobile ? '90vh' : ''}},[_c('c-box',_vm._b({attrs:{"h":_vm.isMobile ? '90%' : '100%',"overflow":_vm.isMobile ? 'scroll' : '',"id":"content-scroll","minHeight":!_vm.isMobile ? '90vh' : ''}},'c-box',_vm.mainStyles[_vm.colorMode],false),[_c('mq-layout',{staticStyle:{"padding-top":"2vw"},attrs:{"mq":"mobile"}},[_c('share-download-bookmark',{attrs:{"chapter":_vm.chapter,"language":_vm.language,"verseGroup":_vm.verse,"textDirection":_vm.textDirection}})],1),_c('c-box',{staticClass:"container"},[_c('c-flex',{attrs:{"justify":"space-between","align":"center"}},[_c('c-heading',{class:"heading"},[_vm._v(" "+_vm._s(((_vm.chapter.chapter_id) + ". "))+" "),_c('span',{class:_vm.languageFilter},[_vm._v(_vm._s(("" + (_vm.chapter.name))))])]),_c('mq-layout',{attrs:{"mq":"desktop"}},[_c('share-download-bookmark',{attrs:{"chapter":_vm.chapter,"language":_vm.languageFilter,"verseGroup":_vm.verse,"textDirection":_vm.textDirection}})],1)],1),_c('read-introduction',{attrs:{"close":_vm.closeReadIntroduction,"isOpen":_vm.readIntroduction}}),_c('mq-layout',{attrs:{"mq":"desktop"}},[_c('c-flex',{attrs:{"direction":"column"}},[(_vm.chapter)?_c('c-box',[_c('c-box',{style:({
                backgroundColor: _vm.colorMode === 'dark' ? 'black' : 'white',
                position: _vm.verse === 'all' ? 'sticky' : '',
                top: '0',
                zIndex: '10',
              }),attrs:{"mt":"0.35vw","id":"filter-collapse-container"}},[_c('c-box',{class:"golden-bg",attrs:{"variant-color":"blue","px":"1vw","border":"0.25vw solid #c1a885","cursor":"pointer","borderRadius":"1vw"},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between"}},[_c('c-text',{attrs:{"mb":"0 !important","fontWeight":"bold"}},[_vm._v("Filters")]),_c('c-flex',{attrs:{"align":"center"}},[_c('c-icon',{attrs:{"w":"2vw","h":"2vw","name":_vm.showFilters ? 'chevron-up' : 'chevron-down'}})],1)],1)],1),_c('c-collapse',{staticStyle:{"overflow-y":"visible !important"},attrs:{"starting-height":0,"is-open":_vm.showFilters,"pt":"0.5vw"}},[(_vm.showFilters)?_c('c-flex',{attrs:{"direction":"column","w":"100%"}},[_c('c-flex',{attrs:{"w":"100%","justify":'space-evenly',"mb":"0.75vw"}},[_c('c-flex',{style:({
                        transition: '1s',
                      }),attrs:{"w":'80%'}},[_c('c-flex',{attrs:{"direction":"column","mr":"2","w":"40%"}},[_c('c-flex',{attrs:{"w":"100%"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Chapters")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"1.45vw","color":_vm.colorMode === 'dark' ? 'white' : 'black'}})],1),_c('dropdown-menu',{class:[
                            _vm.colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container custom-select' ],attrs:{"withDropdownCloser":true}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(((_vm.chapter.chapter_id) + ". " + (_vm.chapter.name)))+" ")]),_c('ul',{attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.chapters),function(chapterFilter,index){return _c('li',{key:((chapterFilter.chapter_id) + "-" + index),class:_vm.chapter.chapter_id ===
                                chapterFilter.chapter_id
                                  ? 'golden-bg'
                                  : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.chapterIdFilters = String(
                                    String(chapterFilter.chapter_id)
                                  )}}},[_vm._v(" "+_vm._s(((chapterFilter.chapter_id) + ". " + (chapterFilter.name)))+" ")])],1)}),0)])],1),_c('c-flex',{attrs:{"direction":"column","mx":"2","w":_vm.verse === 'all' ? '20%' : '15%'}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Verse No")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"1.45vw","color":_vm.colorMode === 'dark' ? 'light' : 'black'}})],1),_c('dropdown-menu',{class:[
                            _vm.colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container custom-select' ],attrs:{"withDropdownCloser":true}},[_c('button',{staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.verse.split(" - ")[0] === _vm.verse.split(" - ")[1] ? _vm.verse.split(" - ")[0] : _vm.verse)+" ")]),_c('ul',{attrs:{"slot":"body"},slot:"body"},[_c('li',{class:_vm.verse === 'all' ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function () {
                                    _vm.verse = 'all';
                                  }}},[_vm._v(" All ")])],1),_vm._l((_vm.chapter.verses),function(item,index){return _c('li',{key:("verse-" + (item.id) + "-" + index),class:_vm.verse === _vm.getVerseGroupFromVerseId(item.id)
                                  ? 'golden-bg'
                                  : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function () {
                                    _vm.verse = _vm.getVerseGroupFromVerseId(item.id);
                                  }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)})],2)])],1),_c('c-flex',{attrs:{"direction":"column","mx":"2","w":_vm.verse === 'all' ? '20%' : '15%'}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Language")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"1.45vw","color":_vm.colorMode === 'dark' ? 'light' : 'black'}})],1),_c('dropdown-menu',{class:[
                            _vm.colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container custom-select' ],attrs:{"withDropdownCloser":true}},[_c('button',{staticStyle:{"text-transform":"uppercase"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.languageFilter)+" ")]),_c('ul',{attrs:{"slot":"body"},slot:"body"},_vm._l((Object.keys(
                                this.chapter.languages
                              )),function(i,index){return _c('li',{key:(i + "-" + index),class:_vm.languageFilter === i ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",staticStyle:{"text-transform":"uppercase"},attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.languageFilter = i}}},[_vm._v(" "+_vm._s(i)+" ")])],1)}),0)])],1),_c('c-flex',{attrs:{"direction":"column","mx":"2","w":_vm.verse === 'all' ? '20%' : '15%'}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Font Size")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"1.45vw","color":_vm.colorMode === 'dark' ? 'light' : 'black'}})],1),_c('dropdown-menu',{class:[
                            _vm.colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container custom-select' ],attrs:{"withDropdownCloser":true}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.fontSize)+" ")]),_c('ul',{attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.fontSizes),function(item,index){return _c('li',{key:((item.value) + "-" + index),class:_vm.fontSize === item.value ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.fontSize = item.value}}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)}),0)])],1),(_vm.verse !== 'all')?_c('c-flex',{attrs:{"direction":"column","mx":"2","w":"15%"}},[_c('c-flex',{attrs:{"align":"center"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("View Options")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"1.45vw","color":_vm.colorMode === 'dark' ? 'light' : 'black'}})],1),_c('dropdown-menu',{class:[
                            _vm.colorMode == 'dark' ? 'box-dark' : '',
                            'filter-container custom-select' ],attrs:{"withDropdownCloser":true}},[_c('button',{staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.view)+" ")]),_c('ul',{attrs:{"slot":"body"},slot:"body"},_vm._l(([
                                'paragraph',
                                'parallel',
                                'full chapter' ]),function(item){return _c('li',{key:item,class:_vm.view === item ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",staticStyle:{"text-transform":"capitalize"},attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.view = item}}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),0)])],1):_vm._e()],1),(
                        _vm.introduction && Object.keys(_vm.introduction).length > 0
                      )?_c('c-link',{class:[
                        _vm.colorMode === 'dark' ? 'box-dark' : '',
                        'filter-container filter-value' ],attrs:{"w":"20%","ml":"2","mt":"1.45vw","display":"flex"},on:{"click":_vm.openReadIntroduction}},[_vm._v(" Read Introduction ")]):_vm._e()],1),(_vm.audio)?_c('c-flex',{attrs:{"mb":"0.75vw","w":"100%"}},[_c('c-flex',{attrs:{"direction":"column","w":_vm.introduction && Object.keys(_vm.introduction).length > 0
                          ? '100%'
                          : '100%'}},[_c('c-flex',{class:[
                          _vm.colorMode == 'dark' ? 'box-dark' : '',
                          'filter-container' ],attrs:{"w":"100%","h":"100%"}},[_c('c-text',{staticClass:"filter-value"},[_vm._v("Audio")]),_c('c-flex',{attrs:{"w":"80%","textAlign":"center","h":"100%","justify":"space-evenly","align":"center"}},[_c('av-circle',{staticClass:"audio-player-chapter",staticStyle:{"height":"100%","width":"100%"},attrs:{"outline-width":0,"outline-meter-space":5,"playtime":true,"cors-anonym":true,"audio-src":_vm.audio,"canv-class":'commentary-visual'}})],1)],1)],1)],1):_vm._e(),_c('hr',{staticStyle:{"border-width":"2px","z-index":"100","border-color":"#c1a885"}})],1):_vm._e()],1)],1),_c('c-flex',{attrs:{"direction":"column","px":"0.5vw","my":"0.25vw"}},[_c('c-flex',{attrs:{"align":"center","pt":_vm.verse === 'all'
                    ? _vm.chapter.verse_title
                      ? '1.25vw'
                      : 0
                    : '0.35vw',"position":"relative"}},[(_vm.verse !== 'all')?_c('c-heading',{staticClass:"heading",attrs:{"mb":"0 !important"}},[_vm._v(" Verse "+_vm._s(_vm.verse.split(" - ")[0] === _vm.verse.split(" - ")[1] ? _vm.verse.split(" - ")[1] : _vm.verse)+" ")]):_vm._e(),(
                    _vm.chapter.verse_title &&
                    (_vm.isFirstVerseGroup || _vm.verse === 'all')
                  )?_c('c-text',{class:_vm.languageFilter,attrs:{"align":"center","fontWeight":"bold","color":"#c1a885","position":"absolute","alignSelf":"center","w":"100%"}},[_vm._v(" "+_vm._s(_vm.chapter.verse_title)+" ")]):_vm._e()],1),_c('c-flex',{attrs:{"w":"100%","mt":"0.25vw"}},[_c('c-flex',{style:(_vm.view === 'parallel' ? { width: '50%' } : { width: '100%' })},[_c('VerseContent',{attrs:{"verses":_vm.verses,"fontSize":_vm.fontSize,"languageKey":"name","languageLabel":_vm.languageFilter,"verseGroupList":_vm.verse === 'all' ? this.chapter.verse_group_list : [],"pr":_vm.view === 'parallel' ? '1.25vw' : '0',"commentary":_vm.verse === 'all' ? this.chapter.commentary : {},"direction":_vm.textDirection,"verse":_vm.verse}})],1),_c('c-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'parallel'),expression:"view === 'parallel'"}],attrs:{"orientation":"vertical","borderWidth":"5px","borderColor":"#c1a885","opacity":"1"}}),_c('c-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'parallel'),expression:"view === 'parallel'"}],attrs:{"w":"50%"}},[_c('VerseContent',{attrs:{"verses":_vm.verses,"fontSize":_vm.fontSize,"languageKey":'parallel_text',"languageLabel":'ar',"pl":"1.25vw","direction":_vm.parallelTextDirection,"verse":_vm.verse}})],1)],1)],1),_c('c-flex',{attrs:{"mb":"1vw"}},[_c('verse-quick-actions',{attrs:{"goToBeginningOfSurah":_vm.goToBeginningOfSurah,"goToNextVerse":_vm.goToNextVerse,"goToPreviousVerse":_vm.goToPreviousVerse,"isLastVerseGroup":_vm.isLastVerseGroup,"isFirstVerseGroup":_vm.isFirstVerseGroup,"isFirstChapter":_vm.isFirstChapter,"isLastChapter":_vm.isLastChapter,"previousChapterName":_vm.previousChapterName,"nextChapterName":_vm.nextChapterName,"nextVerseGroup":_vm.nextVerseGroup,"previousVerseGroup":_vm.previousVerseGroup,"verse":_vm.verse}})],1),(
                _vm.verse !== 'all' &&
                _vm.commentary &&
                (_vm.commentary.audio || _vm.commentary.text)
              )?_c('commentary',{attrs:{"commentary":_vm.commentary,"fontSize":_vm.fontSize,"verseGroup":_vm.verse}}):_vm._e(),_c('c-flex',{staticClass:"related-media-collapsible-container",attrs:{"my":"1vw"}},[(_vm.isLoadingRelatedMedia)?_c('c-flex',{attrs:{"justify":"center","align":"center","w":"100%","textAlign":"center"}},[_c('c-text',{attrs:{"mr":"1vw","color":"#c1a885","fontWeight":"bolder"}},[_vm._v(" Loading Related Media ")]),_c('c-box',[_c('loading',{attrs:{"size":"md","thickness":"3px"}})],1)],1):(
                  (_vm.verse !== 'all' ||
                    _vm.chapter.verse_group_list.length === 1) &&
                  _vm.relatedMedia &&
                  _vm.relatedMedia.length > 0 &&
                  !_vm.isLoadingRelatedMedia
                )?_c('related-media',{attrs:{"relatedMedia":_vm.relatedMedia,"showSeeAll":true,"seeAllRoute":_vm.handleSeeAllRelatedMedia,"collapsable":true,"verseGroup":this.verse}}):_vm._e()],1)],1):_vm._e()],1)],1),_c('mq-layout',{attrs:{"mq":"mobile"}},[(_vm.chapter !== undefined)?_c('c-flex',_vm._b({attrs:{"direction":"column"}},'c-flex',_vm.mainStyles[_vm.colorMode],false),[(
              _vm.chapter.verse_title && (_vm.isFirstVerseGroup || _vm.verse === 'all')
            )?_c('c-text',{class:_vm.languageFilter,attrs:{"mt":_vm.verse !== 'all' ? '0.5vw' : '0',"align":"center","fontWeight":"bold","color":"#c1a885","alignSelf":"center","w":"100%","fontSize":"2.75vw"}},[_vm._v(" "+_vm._s(_vm.chapter.verse_title)+" ")]):_vm._e(),(_vm.audio)?_c('av-circle',{staticClass:"audio-player-chapter",staticStyle:{"height":"7.5vh","width":"100%"},attrs:{"outline-width":0,"outline-meter-space":5,"playtime":true,"cors-anonym":true,"audio-src":_vm.audio,"canv-class":'commentary-visual'}}):_vm._e(),_c('VerseContent',{attrs:{"verses":_vm.verses,"fontSize":_vm.fontSize,"languageKey":"name","languageLabel":_vm.languageFilter,"verseGroupList":_vm.verse === 'all' ? this.chapter.verse_group_list : [],"pr":_vm.view === 'parallel' ? '1.25vw' : '0',"commentary":_vm.verse === 'all' ? this.chapter.commentary : {},"direction":_vm.textDirection,"verse":_vm.verse}}),_c('verse-quick-actions',{attrs:{"goToBeginningOfSurah":_vm.goToBeginningOfSurah,"goToNextVerse":_vm.goToNextVerse,"goToPreviousVerse":_vm.goToPreviousVerse,"isLastVerseGroup":_vm.isLastVerseGroup,"isFirstVerseGroup":_vm.isFirstVerseGroup,"isFirstChapter":_vm.isFirstChapter,"isLastChapter":_vm.isLastChapter,"previousChapterName":_vm.previousChapterName,"nextChapterName":_vm.nextChapterName,"nextVerseGroup":_vm.nextVerseGroup,"previousVerseGroup":_vm.previousVerseGroup,"verse":_vm.verse}}),(
              _vm.verse !== 'all' &&
              _vm.commentary &&
              (_vm.commentary.audio || _vm.commentary.text)
            )?_c('commentary',{attrs:{"commentary":_vm.commentary,"fontSize":_vm.fontSize,"verseGroup":_vm.verse}}):_vm._e(),(_vm.isLoadingRelatedMedia)?_c('c-flex',{attrs:{"justify":"center","align":"center","w":"100%","textAlign":"center"}},[_c('c-text',{attrs:{"mr":"1vw","color":"#c1a885","fontWeight":"bolder"}},[_vm._v(" Loading Related Media ")]),_c('c-box',[_c('loading',{attrs:{"size":"md","thickness":"3px"}})],1)],1):(_vm.relatedMedia && _vm.relatedMedia.length > 0)?_c('c-flex',{staticClass:"related-media-collapsible-container"},[_c('related-media',{attrs:{"relatedMedia":_vm.relatedMedia,"showSeeAll":true,"seeAllRoute":_vm.handleSeeAllRelatedMedia,"collapsable":true,"verseGroup":this.verse}})],1):_vm._e()],1):_vm._e(),_c('view-options',{attrs:{"close":_vm.closeViewOptionsModal,"show":_vm.showViewOptions,"fontSize":_vm.fontSize,"fontSizes":_vm.fontSizes,"languages":_vm.chapter.languages,"language":_vm.languageFilter,"verse":_vm.verse,"view":_vm.view,"colorMode":_vm.colorMode,"chapter":_vm.chapter,"setLanguage":function (value) { return (_vm.languageFilter = value); },"setFontSize":_vm.setFontSize,"setView":function (value) { return (_vm.view = value); }}}),_c('chapter-options',{attrs:{"close":_vm.closeChapterOptionsModal,"show":_vm.showChapterOptions,"chapters":_vm.chapters,"chapterIdFilters":_vm.chapterIdFilters,"setChapterId":_vm.setChapterId,"verse_group_list":this.chapter.verse_group_list,"setVerse":_vm.setVerse,"verse":_vm.verse,"openReadIntroduction":_vm.openReadIntroduction,"colorMode":_vm.colorMode,"chapter":_vm.chapter,"getVerseGroupFromVerseId":_vm.getVerseGroupFromVerseId}}),_c('parallel-verse',{attrs:{"close":function () {
              _vm.openMobileParallel = false;
              _vm.view = 'paragraph';
            },"isOpen":_vm.openMobileParallel,"chapter":_vm.chapter,"fontSize":_vm.fontSize,"languageFilter":_vm.languageFilter,"parallelTextDirection":_vm.parallelTextDirection,"textDirection":_vm.textDirection,"verses":_vm.verses,"view":_vm.view,"verse":_vm.verse,"commentary":_vm.commentary,"goToBeginningOfSurah":_vm.goToBeginningOfSurah,"goToNextVerse":_vm.goToNextVerse,"goToPreviousVerse":_vm.goToPreviousVerse,"isLastVerseGroup":_vm.isLastVerseGroup,"isFirstVerseGroup":_vm.isFirstVerseGroup,"isFirstChapter":_vm.isFirstChapter,"isLastChapter":_vm.isLastChapter,"previousChapterName":_vm.previousChapterName,"nextChapterName":_vm.nextChapterName,"nextVerseGroup":_vm.nextVerseGroup,"previousVerseGroup":_vm.previousVerseGroup}})],1)],1)],1),_c('mq-layout',{staticStyle:{"height":"10%"},attrs:{"mq":"mobile"}},[_c('c-simple-grid',{staticClass:"bottom-nav-container",attrs:{"columns":2,"w":"100%","h":"100%","backgroundColor":'white'}},[_c('c-flex',{attrs:{"justify":"center","align":"center","borderRight":"1px solid black","position":"relative"}},[_c('c-image',{attrs:{"src":require('@/assets/icons/book.png'),"size":"40%","objectFit":"contain","position":"absolute","zIndex":"1000","left":"2vw"}}),_c('button-outline',{style:({ 'z-index': '10000', width: '100%', height: '100%' }),attrs:{"text":"Chapters","click":_vm.showChapterOptionsModal,"backgroundColor":'black !important'}})],1),_c('c-flex',{attrs:{"justify":"center","align":"center","position":"relative"}},[_c('c-image',{attrs:{"src":require('@/assets/icons/chapter.png'),"size":"40%","objectFit":"contain","position":"absolute","zIndex":"1000","left":"2vw"}}),_c('button-outline',{style:({ 'z-index': '10000', width: '100%', height: '100%' }),attrs:{"text":"Options","click":_vm.showViewOptionsModal}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }