import {
    bookmark,
    forgotPassword,
    getBookmarks,
    getChapters,
    getUser,
    login,
    signup,
} from "../apis";
import { ACTION_TYPES } from "../constants/action-types";

const loadChapters = ({ commit }, [chapterId, groupId, language]) => {
    commit(ACTION_TYPES.CHAPTERS.SAVE_CHAPTERS_REQUEST);
    getChapters(chapterId, groupId, language)
        .then((result) =>
            commit(ACTION_TYPES.CHAPTERS.SAVE_CHAPTERS_SUCCESS, result.data)
        )
        .catch(() => commit(ACTION_TYPES.CHAPTERS.SAVE_CHAPTERS_FAILURE));
};

const handleLogin = ({ commit }, [username, password]) => {
    commit(ACTION_TYPES.LOGIN.LOGIN_REQUEST);
    login(username, password)
        .then((result) => commit(ACTION_TYPES.LOGIN.LOGIN_SUCCESS, result.data))
        .catch((error) =>
            commit(ACTION_TYPES.LOGIN.LOGIN_FAILURE, error.data.error_message)
        );
};

const handleSignup = ({ commit }, [email, password, firstName, lastName, phone]) => {
    commit(ACTION_TYPES.SIGNUP.SIGNUP_REQUEST);
    signup(email, password, firstName, lastName, phone)
        .then((result) => commit(ACTION_TYPES.SIGNUP.SIGNUP_SUCCESS, result.data))
        .catch((error) =>
            commit(ACTION_TYPES.SIGNUP.SIGNUP_FAILURE, error.data.error_message)
        );
};

const handleForgotPassword = ({ commit }, [email]) => {
    commit(ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_REQUEST);
    forgotPassword(email)
        .then((result) =>
            commit(ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_SUCCESS, result.data)
        )
        .catch((error) =>
            commit(
                ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_FAILURE,
                (error && error.data && error.data.error_message) ||
                "Something went wrong"
            )
        );
};

const fetchBookmarks = ({ commit }) => {
    commit(ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_REQUEST);
    getBookmarks()
        .then((result) =>
            commit(ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_SUCCESS, result.data)
        )
        .catch((error) =>
            commit(
                ACTION_TYPES.BOOKMARKS.GET_BOOKMARKS_FAILURE,
                (error && error.data && error.data.error_message) ||
                "Something went wrong"
            )
        );
};

const addToBookmarks = ({ commit }, [chapterId, groupId, language]) => {
    commit(ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_REQUEST, {
        chapter_id: chapterId,
        group_id: groupId,
        language,
    });
    bookmark(chapterId, groupId, language, "add")
        .then((result) =>
            result.status === 200 ?
            commit(ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_SUCCESS, result.data[0]) :
            console.log("Some problem while adding to bookmarks: ", result)
        )
        .catch((error) =>
            commit(
                ACTION_TYPES.BOOKMARKS.ADD_BOOKMARKS_FAILURE,
                (error && error.data && error.data.error_message) ||
                "Something went wrong"
            )
        );
};

const removeFromBookmarks = ({ commit }, [chapterId, groupId, language]) => {
    commit(ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_REQUEST, {
        chapter_id: chapterId,
        group_id: groupId,
        language,
    });
    bookmark(chapterId, groupId, language, "remove")
        .then((result) =>
            result.status === 200 ?
            commit(ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_SUCCESS) :
            console.log("Some problem while adding to bookmarks: ", result)
        )
        .catch((error) =>
            commit(
                ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_FAILURE,
                (error && error.data && error.data.error_message) ||
                "Something went wrong"
            )
        );
};

const getProfileDetails = ({ commit }) => {
    commit(ACTION_TYPES.PROFILE.GET_PROFILE_DETAILS_REQUEST);
    getUser()
        .then((result) =>
            commit(ACTION_TYPES.PROFILE.GET_PROFILE_DETAILS_SUCCESS, result.data)
        )
        .catch((error) =>
            commit(
                ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARKS_FAILURE,
                (error && error.data && error.data.error_message) ||
                "Something went wrong"
            )
        );
};

const logout = ({ commit }) => commit(ACTION_TYPES.LOGOUT.LOGOUT_REQUEST);

const clearLoginReducer = ({ commit }) =>
    commit(ACTION_TYPES.LOGIN.LOGIN_CLEAR);

const clearSignupReducer = ({ commit }) =>
    commit(ACTION_TYPES.SIGNUP.SIGNUP_CLEAR);

const saveChapter = ({ commit }, [chapter]) => {
    commit(ACTION_TYPES.SAVE_CHAPTER_ID, chapter);
};

const saveGroup = ({ commit }, [groupId]) =>
    commit(ACTION_TYPES.SAVE_GROUP_ID, groupId);

const saveLanguage = ({ commit }, [language]) =>
    commit(ACTION_TYPES.SAVE_LANGUAGE, language);

const clearForgotPassword = ({ commit }) =>
    commit(ACTION_TYPES.FORGOT_PASSOWRD.FORGOT_PASSOWRD_CLEAR);

export default {
    loadChapters,
    handleLogin,
    handleSignup,
    handleForgotPassword,
    logout,
    clearLoginReducer,
    clearSignupReducer,
    saveChapter,
    saveGroup,
    saveLanguage,
    clearForgotPassword,
    fetchBookmarks,
    addToBookmarks,
    removeFromBookmarks,
    getProfileDetails,
};