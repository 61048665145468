<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :size="fullScreen ? 'full' : 'xl'"
    :closeOnOverlayClick="false"
    style="z-index: 10000"
  >
    <c-modal-content
      class="read-introduction-modal-content"
      :minHeight="fullScreen ? '100%' : 'max-content'"
      :m="fullScreen ? '0 !important' : ''"
      v-scroll-lock="isMobile && isOpen"
    >
      <c-modal-header>
        <c-flex justify="space-between">
          Quran Introduction
          <c-box @click="goFullScreen">
            <c-image
              :src="
                require(`@/assets/icons/${
                  fullScreen ? 'exit-fullscreen' : 'go-fullscreen'
                }.svg`)
              "
              class="full-screen-icon"
            />
          </c-box>
        </c-flex>
      </c-modal-header>
      <c-modal-close-button />
      <c-modal-body pt="1vw">
        <c-simple-grid :columns="isMobile ? 1 : 2">
          <c-box mr="0.75vw" :mt="isMobile ? '2vw' : '0'">
            <c-text fontWeight="bold" mb="0.5vw" class="filter-label">
              Heading
            </c-text>
            <dropdown-menu
              style="width: 100%"
              :withDropdownCloser="true"
              :class="[
                colorMode == 'dark' ? 'box-dark' : '',
                'filter-container custom-select',
              ]"
              @closed="headingOptionsOpen = false"
              @opened="headingOptionsOpen = true"
            >
              <button slot="trigger">
                {{ introductionHeadings[selectedIntroduction] }}
              </button>
              <ul slot="body" v-scroll-lock="headingOptionsOpen">
                <li
                  v-for="(element, index) in introductionHeadings"
                  :key="`${element}-${index}`"
                  :class="
                    introductionHeadings[selectedIntroduction] ===
                    introductionHeadings[index]
                      ? 'golden-bg'
                      : ''
                  "
                >
                  <c-box
                    dropdown-closer
                    class="filter-value"
                    @click="selectedIntroduction = index"
                  >
                    {{ element }}
                  </c-box>
                </li>
              </ul>
            </dropdown-menu>
          </c-box>
          <c-box style="width: 100%" :mt="isMobile ? '2vw' : '0'">
            <c-text fontWeight="bold" mb="0.5vw" class="filter-label"
              >Subheading</c-text
            >
            <dropdown-menu
              style="width: 100%"
              :withDropdownCloser="true"
              :class="[
                colorMode == 'dark' ? 'box-dark' : '',
                'filter-container custom-select',
              ]"
              @closed="subHeadingOptionsOpen = false"
              @opened="subHeadingOptionsOpen = true"
            >
              <button slot="trigger">
                {{
                  introduction[introductionHeadings[selectedIntroduction]][
                    subIntroduction
                  ].title
                }}
              </button>
              <ul slot="body" v-scroll-lock="subHeadingOptionsOpen">
                <li
                  v-for="(element, index) in introduction[
                    introductionHeadings[selectedIntroduction]
                  ].map((el) => el.title)"
                  :key="`${element}-${index}`"
                  :class="
                    introduction[introductionHeadings[selectedIntroduction]][
                      subIntroduction
                    ].title ===
                    introduction[introductionHeadings[selectedIntroduction]][
                      index
                    ].title
                      ? 'golden-bg'
                      : ''
                  "
                >
                  <c-box
                    dropdown-closer
                    class="filter-value"
                    @click="subIntroduction = index"
                  >
                    {{ element }}
                  </c-box>
                </li>
              </ul>
            </dropdown-menu>
          </c-box>
        </c-simple-grid>
        <div />
        <c-text
          class="introduction-container"
          overflow="auto"
          :p="isMobile ? '2.5vw 5vw' : '1vw 1.5vw'"
          :mt="isMobile ? '5vw' : '1vw'"
          borderRadius="1vw"
          textAlign="justify"
          lineHeight="180%"
          :minHeight="!isMobile && fullScreen ? '68.5vh' : ''"
          :maxHeight="!isMobile && fullScreen ? '68.5vh' : ''"
          v-html="
            introduction[introductionHeadings[selectedIntroduction]][
              subIntroduction
            ].content
          "
        />
        <c-flex justify="space-between" :my="isMobile ? '5vw' : '1.25vw'">
          <c-flex
            v-for="(toggle, index) in [
              { title: 'Previous', onClick: previous },
              { title: 'Next', onClick: next },
            ]"
            :width="isMobile ? '30vw' : '15vw'"
            :height="isMobile ? '10vw' : '3vw'"
            :key="index"
          >
            <button-outline :text="toggle.title" :click="toggle.onClick" />
          </c-flex>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<style>
.read-introduction-modal-content > section {
  min-width: 90vw;
}
@media only screen and (max-width: 600px) {
  .full-screen-icon {
    margin-right: 8.5vw;
    width: 4.5vw;
  }
}
@media only screen and (min-width: 601px) {
  .full-screen-icon {
    margin-right: 3vw;
    width: 1.75vw;
  }
}
</style>
<script>
import { CButton, CImage } from "@chakra-ui/vue";
import { mapGetters } from "vuex";
import ButtonOutline from "../ButtonOutline.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "ReadIntroduction",
  inject: ["$chakraColorMode", "$toggleColorMode"],
  props: ["isOpen", "close"],
  data: function () {
    return {
      selectedIntroduction: "0",
      subIntroduction: "0",
      heading: undefined,
      subHeading: undefined,
      fullScreen: true,
      isMobile,
      headingOptionsOpen: false,
      subHeadingOptionsOpen: false,
    };
  },
  computed: {
    ...mapGetters(["introduction"]),

    colorMode() {
      return this.$chakraColorMode();
    },
    introductionHeadings() {
      return Object.keys(this.introduction);
    },
  },
  methods: {
    goFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
    previous() {
      if (this.subIntroduction === "0") {
        const totalSubIntroductions =
          this.introduction[
            this.introductionHeadings[
              `${Number(this.selectedIntroduction) - 1}`
            ]
          ].length;
        this.selectedIntroduction =
          this.selectedIntroduction === "0"
            ? "0"
            : `${Number(this.selectedIntroduction) - 1}`;
      } else {
        this.subIntroduction = `${Number(this.subIntroduction) - 1}`;
      }
    },
    next() {
      const totalSubIntroductions =
        this.introduction[
          this.introductionHeadings[Number(this.selectedIntroduction)]
        ].length;
      if (Number(this.subIntroduction) < totalSubIntroductions - 1) {
        this.subIntroduction = `${Number(this.subIntroduction) + 1}`;
      } else {
        this.subIntroduction = "0";
        this.selectedIntroduction = `${Number(this.selectedIntroduction) + 1}`;
      }
    },
  },
  watch: {
    selectedIntroduction(newValue, oldValue) {
      newValue !== oldValue && (this.subIntroduction = "0");
    },
    isOpen(newValue, oldVal) {
      this.fullScreen = true;
    },
  },
  created: function () {
    this.heading = this.introductionHeadings[0];
    this.subHeading = this.introduction[this.introductionHeadings[0]][0].title;
  },
  components: {
    CButton,
    CImage,
    ButtonOutline,
  },
};
</script>
