var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-modal',{staticStyle:{"z-index":"10000"},attrs:{"is-open":_vm.isOpen,"on-close":_vm.close,"size":_vm.fullScreen ? 'full' : 'xl',"closeOnOverlayClick":false}},[_c('c-modal-content',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isMobile && _vm.isOpen),expression:"isMobile && isOpen"}],staticClass:"read-introduction-modal-content",attrs:{"minHeight":_vm.fullScreen ? '100%' : 'max-content',"m":_vm.fullScreen ? '0 !important' : ''}},[_c('c-modal-header',[_c('c-flex',{attrs:{"justify":"space-between"}},[_vm._v(" Quran Introduction "),_c('c-box',{on:{"click":_vm.goFullScreen}},[_c('c-image',{staticClass:"full-screen-icon",attrs:{"src":require(("@/assets/icons/" + (_vm.fullScreen ? 'exit-fullscreen' : 'go-fullscreen') + ".svg"))}})],1)],1)],1),_c('c-modal-close-button'),_c('c-modal-body',{attrs:{"pt":"1vw"}},[_c('c-simple-grid',{attrs:{"columns":_vm.isMobile ? 1 : 2}},[_c('c-box',{attrs:{"mr":"0.75vw","mt":_vm.isMobile ? '2vw' : '0'}},[_c('c-text',{staticClass:"filter-label",attrs:{"fontWeight":"bold","mb":"0.5vw"}},[_vm._v(" Heading ")]),_c('dropdown-menu',{class:[
              _vm.colorMode == 'dark' ? 'box-dark' : '',
              'filter-container custom-select' ],staticStyle:{"width":"100%"},attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.headingOptionsOpen = false},"opened":function($event){_vm.headingOptionsOpen = true}}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.introductionHeadings[_vm.selectedIntroduction])+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.headingOptionsOpen),expression:"headingOptionsOpen"}],attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.introductionHeadings),function(element,index){return _c('li',{key:(element + "-" + index),class:_vm.introductionHeadings[_vm.selectedIntroduction] ===
                  _vm.introductionHeadings[index]
                    ? 'golden-bg'
                    : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.selectedIntroduction = index}}},[_vm._v(" "+_vm._s(element)+" ")])],1)}),0)])],1),_c('c-box',{staticStyle:{"width":"100%"},attrs:{"mt":_vm.isMobile ? '2vw' : '0'}},[_c('c-text',{staticClass:"filter-label",attrs:{"fontWeight":"bold","mb":"0.5vw"}},[_vm._v("Subheading")]),_c('dropdown-menu',{class:[
              _vm.colorMode == 'dark' ? 'box-dark' : '',
              'filter-container custom-select' ],staticStyle:{"width":"100%"},attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.subHeadingOptionsOpen = false},"opened":function($event){_vm.subHeadingOptionsOpen = true}}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.introduction[_vm.introductionHeadings[_vm.selectedIntroduction]][ _vm.subIntroduction ].title)+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.subHeadingOptionsOpen),expression:"subHeadingOptionsOpen"}],attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.introduction[
                  _vm.introductionHeadings[_vm.selectedIntroduction]
                ].map(function (el) { return el.title; })),function(element,index){return _c('li',{key:(element + "-" + index),class:_vm.introduction[_vm.introductionHeadings[_vm.selectedIntroduction]][
                    _vm.subIntroduction
                  ].title ===
                  _vm.introduction[_vm.introductionHeadings[_vm.selectedIntroduction]][
                    index
                  ].title
                    ? 'golden-bg'
                    : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function($event){_vm.subIntroduction = index}}},[_vm._v(" "+_vm._s(element)+" ")])],1)}),0)])],1)],1),_c('div'),_c('c-text',{staticClass:"introduction-container",attrs:{"overflow":"auto","p":_vm.isMobile ? '2.5vw 5vw' : '1vw 1.5vw',"mt":_vm.isMobile ? '5vw' : '1vw',"borderRadius":"1vw","textAlign":"justify","lineHeight":"180%","minHeight":!_vm.isMobile && _vm.fullScreen ? '68.5vh' : '',"maxHeight":!_vm.isMobile && _vm.fullScreen ? '68.5vh' : ''},domProps:{"innerHTML":_vm._s(
          _vm.introduction[_vm.introductionHeadings[_vm.selectedIntroduction]][
            _vm.subIntroduction
          ].content
        )}}),_c('c-flex',{attrs:{"justify":"space-between","my":_vm.isMobile ? '5vw' : '1.25vw'}},_vm._l(([
            { title: 'Previous', onClick: _vm.previous },
            { title: 'Next', onClick: _vm.next } ]),function(toggle,index){return _c('c-flex',{key:index,attrs:{"width":_vm.isMobile ? '30vw' : '15vw',"height":_vm.isMobile ? '10vw' : '3vw'}},[_c('button-outline',{attrs:{"text":toggle.title,"click":toggle.onClick}})],1)}),1)],1)],1),_c('c-modal-overlay')],1)}
var staticRenderFns = []

export { render, staticRenderFns }