<template>
  <c-box v-if="article !== undefined">
    <Header />
    <c-image :src="article.banner_image" w="100%" objectFit="contain" />
    <c-box class="container">
      <c-box>
        <c-heading
          :fontSize="isMobile ? '5.5vw' : '2.5vw'"
          :pb="isMobile ? '1vw' : '0.75vw'"
        >
          {{ article.title }}
        </c-heading>
        <c-text
          v-if="article.author"
          :pb="isMobile ? '' : '1vw'"
          color="palettes.gold"
          fontWeight="bold"
        >
          By {{ article.author }}
        </c-text>
        <c-flex
          pb="1vw"
          :pt="isMobile ? '2vw' : ''"
          v-if="article.references && article.references.length > 0"
        >
          <c-text
            :fontSize="isMobile ? '3vw' : '1vw'"
            color="palettes.labelLightGrey"
            align="justify"
          >
            <span
              v-for="(i, index) in article.references"
              :key="index"
              mr="1vw"
            >
              <c-link
                :fontSize="isMobile ? '3vw' : '1vw'"
                fontWeight="bold"
                as="router-link"
                mr="0.5vw"
                color="blue !important"
                :to="`/chapters/${i.chapter_id}/verse/${
                  i.group.split(' - ')[1]
                }`"
              >
                {{ i.chapter_id }}:{{ i.verse_no }}
              </c-link>
              {{
                `${i.verse}${
                  index !== article.references.length - 1 ? ", " : ""
                }`
              }}
            </span>
          </c-text>
        </c-flex>
        <c-text
          v-if="article.sub_title"
          :pt="isMobile ? '3vw' : '1.5vw'"
          fontWeight="bold"
          :fontSize="isMobile ? '3.5vw' : '1.5vw'"
          color="palettes.gold"
          align="justify"
        >
          {{ article.sub_title }}
        </c-text>
        <c-box
          class="article-content"
          v-html="article.content.replace('<p>&nbsp;</p>', '')"
          py="1vw"
          text-align="justify"
        />
      </c-box>
    </c-box>
    <Footer />
  </c-box>
  <loading v-else />
</template>

<style>
.highlight {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .article-content {
    margin-top: 2vw;
  }
  .article-content > * {
    font-size: 3.5vw !important;
    line-height: 6.75vw;
  }
  .highlight {
    border-left: 5px solid #c1a885;
    font-size: 2.75vw !important;
    padding: 6vw 5vw;
    margin: 5vw 0vw;
    line-height: 5vw;
  }
}
@media only screen and (min-width: 601px) {
  .highlight {
    font-size: 1.15vw;
    border-left: 7.5px solid #c1a885;
    padding: 2vw;
    margin: 1.5vw 0vw;
  }
}
</style>

<script>
import { getArticle } from "../apis";
import Footer from "../components/CustomComponents/Footer.vue";
import Header from "../components/CustomComponents/Header.vue";
import Loading from "../components/CustomComponents/Loading.vue";
import { isMobile } from "mobile-device-detect";

export default {
  components: { Loading, Footer, Header },
  name: "ArticlePage",
  data() {
    return { article: undefined, isMobile };
  },
  created() {
    getArticle(this.$route.params.id).then(
      (result) => (this.article = result.data[0])
    );
  },
};
</script>
