<template>
  <c-box w="100%">
    <c-box v-if="collapsable">
      <c-box
        variant-color="blue"
        @click="show = !show"
        :class="`accordion-header ${show ? 'golden-bg' : 'golden-hover'}`"
      >
        <c-flex align="center" justify="space-between">
          <c-heading class="heading" mb="0 !important" pl="1">
            {{ title ? `${title}s` : "Related Media" }}
          </c-heading>
          <c-flex align="center">
            <c-text class="accordion-verse-container">
              {{ `Verse ${verseGroup}` }}
            </c-text>
            <c-flex class="hide-show-container">
              <c-text>
                {{ show ? "Hide" : "Show" }}
              </c-text>
              <c-icon
                :name="show ? 'chevron-up' : 'chevron-down'"
                mx="1"
                class="arrow-icon"
              />
            </c-flex>
          </c-flex>
        </c-flex>
      </c-box>
      <c-collapse :is-open="show" v-if="enable">
        <c-box :h="isMobile ? '69.5vw' : '31vw'">
          <c-flex align="center" justify="flex-end" mt="1vw" mb="0.5vw">
            <c-box
              v-if="showSeeAll"
              align="center"
              justify="center"
              class="see-all golden-hover"
              @click="seeAllRoute"
            >
              See All
              <c-icon class="arrow-icon" name="chevron-right" color="black" />
            </c-box>
          </c-flex>
          <vue-horizontal
            ref="horizontal"
            @scroll-debounce="onScrollDebounce"
            :displacement="displacement"
          >
            <tile
              v-for="(media, index) in relatedMedia"
              v-bind:key="media.title + index"
              v-bind:media="media"
              v-bind:handleClick="handleReadMore"
              :slider="true"
            />
          </vue-horizontal>
        </c-box>
      </c-collapse>
    </c-box>
    <c-flex direction="column" w="100%" v-else>
      <c-flex align="center" justify="space-between" mb="1vw">
        <c-heading class="heading">
          {{ title ? `${title}s` : "Related Media" }}
        </c-heading>
        <c-box
          v-if="showSeeAll"
          align="center"
          justify="center"
          class="see-all golden-hover"
          @click="seeAllRoute"
        >
          See All
          <c-icon
            name="chevron-right"
            size="1.35vw"
            color="black"
            mt="-0.15vw"
          />
        </c-box>
      </c-flex>
      <vue-horizontal
        ref="horizontal"
        @scroll-debounce="onScrollDebounce"
        :displacement="displacement"
      >
        <tile
          v-for="(media, index) in relatedMedia"
          v-bind:key="media.title + index"
          v-bind:media="media"
          v-bind:handleClick="handleReadMore"
          :slider="true"
        />
      </vue-horizontal>
    </c-flex>
    <audio-video
      :close="closeAudioVideo"
      :media="audioVideoItem"
      :isOpen="showAudioVideoModal"
    />
  </c-box>
</template>

<script>
function observeVisibility(element, callback) {
  const observer = new IntersectionObserver(
    (records) => {
      callback(records.find((record) => record.isIntersecting));
    },
    { rootMargin: "10% 0% 10% 0%", threshold: 1.0 }
  );
  observer.observe(element);
}
import VueHorizontal from "vue-horizontal";
import AudioVideo from "./Modals/AudioVideo.vue";
import Tile from "./Tile.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "RelatedMedia",
  props: [
    "relatedMedia",
    "showSeeAll",
    "title",
    "type",
    "seeAllRoute",
    "collapsable",
    "verseGroup",
  ],
  data: function () {
    return {
      audioVideoItem: undefined,
      showAudioVideoModal: false,
      hasPrev: false,
      hasNext: false,
      interval: null,
      displacement: 0.4,
      show: false,
      enable: false,
      isMobile,
    };
  },
  components: {
    VueHorizontal,
    Tile,
    AudioVideo,
  },
  mounted() {
    if (this.$refs.horizontal && this.$refs.horizontal.$el) {
      observeVisibility(this.$refs.horizontal.$el, (visible) => {
        if (visible) {
          this.startRelatedMediaScroll();
        } else {
          this.stopRelatedMediaScroll();
        }
      });
    }
  },
  watch: {
    showAudioVideoModal(newVal, _) {
      newVal === true
        ? this.stopRelatedMediaScroll()
        : this.startRelatedMediaScroll();
    },
    show(newVal, oldVal) {
      newVal === false && setTimeout(() => (this.enable = false), 100);
      newVal === true && (this.enable = true);
    },
  },
  destroyed() {
    this.stopRelatedMediaScroll();
  },

  methods: {
    stopRelatedMediaScroll() {
      clearInterval(this.interval);
    },
    startRelatedMediaScroll() {
      this.interval = setInterval(this.play, 3000);
    },
    onScrollDebounce({ hasNext, hasPrev }) {
      this.hasPrev = hasPrev;
      this.hasNext = hasNext;
    },
    play() {
      if (this.hasNext !== this.hasPrev) {
        this.forward = !this.forward;
      }

      if (this.forward && this.hasNext) {
        this.$refs.horizontal.next();
      } else if (!this.forward && this.hasPrev) {
        this.$refs.horizontal.prev();
      }

      // if (!this.hasNext && this.hasPrev) {
      //   this.$refs.horizontal.sc();
      //   this.displacement = 1.0;
      //   return;
      // }

      // if (this.hasNext) {
      //   this.$refs.horizontal.next();
      //   // After first nav, change displacement window to just 60%
      //   this.displacement = 0.2;
      // }
    },
    handleReadMore(item) {
      if (item.type === "audio" || item.type === "video") {
        this.openAudioVideo(item);
      } else if (item.type === "article") {
        this.$router.push({
          name: "ArticlePage",
          params: { id: "1683" },
        });
      }
    },
    openAudioVideo(item) {
      this.audioVideoItem = item;
      this.showAudioVideoModal = true;
    },
    closeAudioVideo() {
      this.audioVideoItem = undefined;
      this.showAudioVideoModal = false;
    },
  },
};
</script>
