<template>
  <c-box id="commentary-container">
    <c-box
      variant-color="blue"
      @click="show = !show"
      :class="`accordion-header ${show ? 'golden-bg' : 'golden-hover'}`"
    >
      <c-flex align="center" justify="space-between">
        <c-heading class="heading" mb="0 !important" pl="1">
          Commentary
        </c-heading>
        <c-flex align="center">
          <c-text class="accordion-verse-container">
            {{ `Verse ${verseGroup}` }}
          </c-text>
          <c-flex class="hide-show-container">
            <c-text>
              {{ show ? "Hide" : "Show" }}
            </c-text>
            <c-icon
              :name="show ? 'chevron-up' : 'chevron-down'"
              mx="1"
              class="arrow-icon"
            />
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>
    <c-collapse :is-open="show" v-if="enable">
      <c-box
        mx="1vw"
        mb="1vw"
        padding="1vw 1.25vw 0.25vw 1.25vw"
        boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
        :borderBottomLeftRadius="isMobile ? '4vw' : '1vw'"
        :borderBottomRightRadius="isMobile ? '4vw' : '1vw'"
      >
        <av-circle
          :style="{
            'margin-bottom': isMobile ? '4vw' : '1vw',
            'margin-top': isMobile ? '4vw' : '0',
          }"
          v-if="commentary.audio"
          :outline-width="0"
          :progress-width="5"
          :outline-meter-space="5"
          :playtime="true"
          :cors-anonym="true"
          :audio-src="commentary.audio"
          :canv-class="'commentary-visual'"
        />
        <c-text
          id="commentary-text"
          :fontSize="fontSize"
          v-html="commentary.text"
          textAlign="justify"
        />
      </c-box>
    </c-collapse>
  </c-box>
</template>

<style>
.accordion-header {
  border: 3px solid #c1a885;
  transition: 0.5s;
}

@media only screen and (min-width: 601px) {
  .accordion-header:hover {
    cursor: pointer;
  }
  .commentary-container {
    margin-top: 1vw;
  }
  #commentary-text {
    padding-right: 1vw;
    padding-left: 1vw;
    line-height: 180%;
    margin-bottom: 1vw;
  }
  .accordion-header {
    padding: 0.8vw 1vw;
    border-radius: 3vw;
  }
}
@media only screen and (max-width: 600px) {
  #commentary-container {
    margin-bottom: 1vw;
  }
  #commentary-text {
    padding-right: 5vw;
    padding-left: 5vw;
    line-height: 170%;
    margin-bottom: 3vw;
  }
  .accordion-header {
    padding: 3vw;
    border-radius: 5vw;
  }
}
.commentary-visual {
  display: none;
}
</style>
<script>
import { CButton, CDivider, CIcon } from "@chakra-ui/vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Commentary",
  props: ["commentary", "fontSize", "verseGroup"],
  data: function () {
    return { show: false, enable: false, isMobile };
  },
  watch: {
    fontSize(newVal, oldVal) {
      newVal !== oldVal && (this.show = false);
    },
    commentary(newValue, oldValue) {
      newValue !== oldValue && (this.show = false);
    },
    show(newVal, oldVal) {
      newVal === false && setTimeout(() => (this.enable = false), 1000);
      newVal === true && (this.enable = true);
    },
  },

  components: {
    CButton,
    CIcon,
    CDivider,
  },
};
</script>
