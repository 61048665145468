import { render, staticRenderFns } from "./ShareDownloadBookmark.vue?vue&type=template&id=1d7b643e&"
import script from "./ShareDownloadBookmark.vue?vue&type=script&lang=js&"
export * from "./ShareDownloadBookmark.vue?vue&type=script&lang=js&"
import style0 from "./ShareDownloadBookmark.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CSpinner: require('@chakra-ui/vue').CSpinner})
