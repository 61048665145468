<template>
  <c-alert-dialog
    :is-open="isOpen"
    :least-destructive-ref="$refs.cancelRef"
    :on-close="closeDialog"
    is-centered
  >
    <c-alert-dialog-overlay />
    <c-alert-dialog-content>
      <c-alert-dialog-header font-size="lg" font-weight="bold">
        {{ heading }}
      </c-alert-dialog-header>
      <c-alert-dialog-body>
        {{ description }}
      </c-alert-dialog-body>
      <c-alert-dialog-footer>
        <c-button ref="cancelRef" @click="onCancel">
          {{ cancelText }}
        </c-button>
        <c-button variantColor="red" @click="onSubmit" ml="3">
          {{ submitText }}
        </c-button>
      </c-alert-dialog-footer>
    </c-alert-dialog-content>
  </c-alert-dialog>
</template>
<script>
export default {
  name: "Alert",
  props: [
    "isOpen",
    "closeDialog",
    "heading",
    "description",
    "cancelText",
    "submitText",
    "onCancel",
    "onSubmit",
  ],
};
</script>
