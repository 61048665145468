var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swipe-modal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({
        background: _vm.colorMode === 'dark' ? 'black' : 'white',
      })},[_c('c-heading',{staticClass:"heading",style:({ color: _vm.colorMode === 'dark' ? 'white' : 'black' }),attrs:{"p":"15px","mb":"0 !important"}},[_vm._v(" Chapter Options ")])],1)]},proxy:true},{key:"body",fn:function(){return [_c('c-flex',{style:({ background: _vm.colorMode === 'dark' ? 'black' : 'white' }),attrs:{"direction":"column","pb":"10vw","px":"4vw"}},[_c('c-flex',{attrs:{"w":"100%","pt":"7.5vw"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Chapters")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"5vw","color":_vm.colorMode === 'dark' ? 'white' : 'black'}})],1),_c('dropdown-menu',{class:[
          _vm.colorMode == 'dark' ? 'box-dark' : '',
          'filter-container custom-select' ],attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.chapterOpen = false},"opened":function($event){_vm.chapterOpen = true}}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(((_vm.chapter.chapter_id) + ". " + (_vm.chapter.name)))+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.chapterOpen),expression:"chapterOpen"}],attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.chapters),function(chapterFilter,index){return _c('li',{key:((chapterFilter.chapter_id) + "-" + index),class:_vm.chapter.chapter_id === chapterFilter.chapter_id
                ? 'golden-bg'
                : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function () {
                  _vm.close();
                  _vm.setChapterId(String(chapterFilter.chapter_id));
                }}},[_vm._v(" "+_vm._s(((chapterFilter.chapter_id) + ". " + (chapterFilter.name)))+" ")])],1)}),0)]),_c('c-flex',{attrs:{"align":"center","pt":"7.5vw"}},[_c('c-text',{staticClass:"filter-label"},[_vm._v("Verse No")]),_c('c-icon',{attrs:{"name":"chevron-down","size":"5vw","color":_vm.colorMode === 'dark' ? 'white' : 'black'}})],1),_c('dropdown-menu',{class:[
          _vm.colorMode == 'dark' ? 'box-dark' : '',
          'filter-container custom-select' ],attrs:{"withDropdownCloser":true},on:{"closed":function($event){_vm.chapterOpen = false},"opened":function($event){_vm.chapterOpen = true}}},[_c('button',{staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.verse)+" ")]),_c('ul',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.chapterOpen),expression:"chapterOpen"}],attrs:{"slot":"body"},slot:"body"},[_c('li',{class:_vm.verse === 'all' ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function () {
                  _vm.setVerse('all');
                }}},[_vm._v(" All ")])],1),_vm._l((_vm.chapter.verses),function(item,index){return _c('li',{key:("verse-" + (item.id) + "-" + index),class:_vm.verse === _vm.getVerseGroupFromVerseId(item.id) ? 'golden-bg' : ''},[_c('c-box',{staticClass:"filter-value",attrs:{"dropdown-closer":""},on:{"click":function () {
                  _vm.setVerse(_vm.getVerseGroupFromVerseId(item.id));
                }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)})],2)]),_c('c-link',{class:[
          _vm.colorMode === 'dark' ? 'box-dark' : '',
          'filter-container filter-value' ],attrs:{"mt":"10vw","fontSize":"3vw !important","display":"flex"},on:{"click":_vm.openReadIntroduction}},[_vm._v(" Read Introduction ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('c-flex',{style:({
        background: _vm.colorMode === 'dark' ? 'black' : 'white',
        borderTop: '1px solid #cdcdcd',
      }),attrs:{"h":"17.5vw","p":"3.5vw"}},[_c('custom-button',{attrs:{"text":"Close","click":_vm.close}})],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})}
var staticRenderFns = []

export { render, staticRenderFns }