<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :closeOnOverlayClick="true"
    size="xl"
  >
    <c-modal-content>
      <c-modal-header>One Time Verification</c-modal-header>
      <c-modal-close-button />
      <c-modal-body>
        <c-text :my="isMobile ? '5vw' : '2vw'">
          Please enter the verification sent to
          <c-text style="color: #8d8888" as="i">{{ email }}</c-text>
        </c-text>
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="false"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
        <c-input
          v-model="password"
          :placeholder="'Password*'"
          type="password"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isPasswordValid"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a valid new password'"
          :show="!isPasswordValid"
        />
        <c-input
          v-model="confirmPassword"
          :placeholder="'Confirm Password*'"
          type="password"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isConfirmPasswordValid"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a re-enter the new password entered above'"
          :show="!isConfirmPasswordValid"
        />
        <c-link
          textAlign="center"
          display="block"
          m="auto"
          :mt="isMobile ? '4vw' : '2vw'"
          :mb="isMobile ? '4vw' : '0'"
          :fontSize="isMobile ? '3vw' : '1vw'"
          color="palettes.primary"
          @click="handleResendOTP"
        >
          Resend OTP
        </c-link>
        <c-box w="50%" m="auto" py="2vw">
          <custom-button text="Submit" :click="submit" :disabled="false" />
        </c-box>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<style>
.otp-input {
  font-size: 20px;
  margin: 0 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .otp-input {
    height: 12.5vw;
    width: 60%;
  }
}

@media only screen and (min-width: 601px) {
  .otp-input {
    height: 4vw;
    width: 50%;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<script>
import { CButton, CImage } from "@chakra-ui/vue";
import CustomButton from "../CustomButton.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "OneTimeVerification",
  props: ["isOpen", "close", "email", "handleSubmit", "handleResendOTP"],
  data() {
    return {
      otp: "",
      password: "",
      confirmPassword: "",
      isMobile,
    };
  },
  components: {
    CButton,
    CImage,
    CustomButton,
  },
  computed: {
    isPasswordValid() {
      return this.password.length > 0;
    },
    isConfirmPasswordValid() {
      return (
        this.confirmPassword.length > 0 &&
        this.confirmPassword === this.password
      );
    },
    isFormValid() {
      return this.otp && this.isPasswordValid && this.isConfirmPasswordValid;
    },
  },
  methods: {
    submit() {
      console.log("OTP here: ", this.otp);
      this.handleSubmit(this.otp, this.password);
    },
    handleOnComplete(value) {
      console.log("OTP completed: ", value);
      this.otp = value;
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>
