<template>
  <c-drawer :isOpen="open" placement="left" :on-close="close">
    <c-drawer-overlay />
    <c-drawer-content w="75vw">
      <c-drawer-header justifyContent="space-between" display="flex">
        Logo
        <c-close-button @click="close" />
      </c-drawer-header>
      <c-drawer-body>
        <c-flex
          v-if="user && user.user_detail"
          direction="column"
          align="center"
          justify="center"
        >
          <c-avatar
            :name="`${user.user_detail.first_name} ${user.user_detail.last_name}`"
            :src="image"
            size="xl"
          />
          <c-text textAlign="center" fontWeight="bold" mt="1vw">
            {{ `${user.user_detail.first_name} ${user.user_detail.last_name}` }}
          </c-text>
        </c-flex>
        <c-flex align="center" justify="center" my="5vw">
          <custom-button
            fontSize="3vw !important"
            :click="!isUserLoggedIn ? handleGoToLogin : openLogoutAlert"
            :text="!isUserLoggedIn ? 'Login' : 'Logout'"
            :width="'90% !important'"
            :height="'10vw !important'"
            :background="'#c1a885'"
          />
        </c-flex>
        <c-box
          my="5vw"
          v-for="(i, index) in [
            { title: 'Home', route: '/', show: true },
            {
              title: 'Read Quran',
              route: `/chapters/${chapterId || '1'}/verse/${savedGroupId}`,
              show: true,
            },
            { title: 'Media', route: '/media', show: true },
            { title: 'Profile', route: '/profile', show: isUserLoggedIn },
          ]"
          :key="index"
        >
          <c-link
            v-if="i.show"
            :class="`link ${
              currentRouteName.includes(i.title) ? 'active-link' : ''
            }`"
            as="router-link"
            :to="i.route"
            textDecoration="none !important"
          >
            {{ i.title }}
          </c-link>
        </c-box>
      </c-drawer-body>
      <!-- <c-drawer-footer> </c-drawer-footer> -->
    </c-drawer-content>
  </c-drawer>
</template>

<script>
import {
  CDrawer,
  CDrawerBody,
  CDrawerFooter,
  CDrawerHeader,
  CDrawerOverlay,
  CDrawerContent,
  CDrawerCloseButton,
} from "@chakra-ui/vue";
import { mapGetters } from "vuex";
import CustomButton from "../../CustomComponents/CustomButton.vue";
export default {
  name: "Drawer",
  props: ["open", "close", "handleGoToLogin", "openLogoutAlert"],
  computed: {
    ...mapGetters(["isUserLoggedIn", "user", "chapterId", "savedGroupId"]),

    currentRouteName() {
      return this.$route.name;
    },
    image() {
      console.log("User image: ", this.user.user_detail.user_image);
      return this.user.user_detail.user_image;
    },
  },
  components: {
    CDrawer,
    CDrawerBody,
    CDrawerFooter,
    CDrawerHeader,
    CDrawerOverlay,
    CDrawerContent,
    CDrawerCloseButton,
    CustomButton,
  },
};
</script>
