<template>
  <c-box
    class="profile-details-container bookmarks-container"
    h="100%"
    v-if="!isFetchingBookmarks"
  >
    <c-heading class="heading">Bookmarks</c-heading>
    <mq-layout
      mq="desktop"
      :style="{
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        height: bookmarks && bookmarks.length > 0 ? '' : '100%',
      }"
    >
      <c-simple-grid
        :columns="3"
        my="2vw"
        :spacing="'1vw'"
        v-if="bookmarks && bookmarks.length > 0"
        w="100%"
      >
        <c-box
          v-for="(bookmark, index) in bookmarks"
          :key="index"
          class="introduction-container bookmark"
          borderRadius="1vw"
          py="1.25vw"
          px="0.75vw"
          @click="
            handleClickBookmark(
              bookmark.chapter_id,
              bookmark.group !== 'all'
                ? bookmark.group.split(' - ')[1]
                : bookmark.group
            )
          "
        >
          <c-flex
            v-for="i in [
              {
                title: 'Chapter',
                value: `${bookmark.chapter_id}. ${bookmark.chapter_name}`,
              },
              {
                title: 'Verse',
                value: bookmark.group,
                textTransform: 'capitalize',
              },
              {
                title: 'Language',
                value: bookmark.language,
                textTransform: 'uppercase',
              },
            ]"
            :key="i.tite"
            pt="0.35vw"
          >
            <c-text w="30%" fontWeight="bold">{{ i.title }}</c-text>
            <c-text
              w="70%"
              :textTransform="i.textTransform"
              class="bookmark-value"
            >
              {{ i.value }}
            </c-text>
          </c-flex>
          <c-flex justify="space-between" align="center" pt="0.5vw">
            <c-box @click="handleRemoveBookmark($event, bookmark)">
              <c-image
                :src="require(`@/assets/icons/garbage.png`)"
                class="icons-sbd"
                id="bookmark-action-icon"
              />
            </c-box>
            <c-image
              :src="require(`@/assets/icons/right-arrow.png`)"
              class="icons-sbd"
              id="bookmark-action-icon"
            />
          </c-flex>
        </c-box>
      </c-simple-grid>
      <c-text v-else color="palettes.labelLightGrey">No Bookmarks</c-text>
    </mq-layout>
    <mq-layout
      mq="mobile"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <c-simple-grid
        :columns="1"
        my="5vw"
        :spacing="'2.5vw'"
        v-if="bookmarks && bookmarks.length > 0"
        w="100%"
      >
        <c-box
          v-for="(bookmark, index) in bookmarks"
          :key="index"
          class="introduction-container bookmark"
          borderRadius="1vw"
          py="1vw"
          px="2vw"
          w="100%"
          @click="
            handleClickBookmark(
              bookmark.chapter_id,
              bookmark.group !== 'all'
                ? bookmark.group.split(' - ')[1]
                : bookmark.group
            )
          "
        >
          <c-flex
            v-for="i in [
              {
                title: 'Chapter',
                value: `${bookmark.chapter_id}. ${bookmark.chapter_name}`,
              },
              {
                title: 'Verse',
                value: bookmark.group,
                textTransform: 'capitalize',
              },
              {
                title: 'Language',
                value: bookmark.language,
                textTransform: 'uppercase',
              },
            ]"
            :key="i.tite"
          >
            <c-text w="25%" fontWeight="bold">{{ i.title }}</c-text>
            <c-text
              w="75%"
              :textTransform="i.textTransform"
              class="bookmark-value"
              >{{ i.value }}
            </c-text>
          </c-flex>
          <c-flex justify="space-between" align="center">
            <c-box @click="handleRemoveBookmark($event, bookmark)">
              <c-image
                :src="require(`@/assets/icons/garbage.png`)"
                class="icons-sbd"
                id="bookmark-action-icon"
              />
            </c-box>
            <c-image
              :src="require(`@/assets/icons/right-arrow.png`)"
              class="icons-sbd"
              id="bookmark-action-icon"
            />
          </c-flex>
        </c-box>
      </c-simple-grid>
      <c-text
        v-else
        color="palettes.labelLightGrey"
        h="55vh"
        d="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="3vw !important"
      >
        No Bookmarks
      </c-text>
    </mq-layout>
  </c-box>
  <loading v-else />
</template>
<style>
.bookmark-value {
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.bookmark {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: 0.5s;
}

.bookmark:hover {
  transform: scale(1.035);
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .bookmarks-container p {
    font-size: 2.25vw;
    margin-bottom: 1vw;
  }
  .bookmark {
    min-height: 25vw;
  }
  #bookmark-action-icon {
    width: 3.5vw;
    height: 3.5vw;
    margin-right: 2vw;
  }
}
@media only screen and (min-width: 601px) {
  .bookmarks-container p {
    font-size: 0.85vw;
    margin-bottom: 0.5vw;
  }
  .bookmark {
    min-height: 8.5vw;
  }
  #bookmark-action-icon {
    width: 1.15vw;
    height: 1.15vw;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Loading from "./Loading.vue";

export default {
  components: { Loading },
  name: "Bookmarks",
  methods: {
    handleRemoveBookmark(event, bookmark) {
      event.stopPropagation();
      console.log("remove bookmark: ", bookmark, " ", [
        bookmark.chapter_id,
        bookmark.group === "all"
          ? "all"
          : Number(bookmark.group.split(" - ")[1]),
        bookmark.language,
      ]);
      this.$store.dispatch("removeFromBookmarks", [
        bookmark.chapter_id,
        bookmark.group === "all"
          ? "all"
          : Number(bookmark.group.split(" - ")[1]),
        bookmark.language,
      ]);
    },
    handleClickBookmark(chapterId, groupId) {
      this.$router.push({
        name: "Read Quran",
        params: { chapterId, groupId },
      });
    },
  },
  computed: {
    ...mapGetters(["isFetchingBookmarks", "bookmarks", "fetchBookmarksError"]),
  },
};
</script>
