<template>
  <c-flex align="center" justify="center" h="100%" w="100%">
    <c-flex direction="column" w="100%">
      <c-heading class="heading">Create an Account</c-heading>
      <c-text mb="1vw">
        Already have an account?
        <c-link color="palettes.primary" as="router-link" to="/login">
          Login
        </c-link>
      </c-text>
      <c-flex
        justify="space-between"
        w="100%"
        :my="isMobile ? '2vw' : '0.15vw'"
      >
        <c-flex w="48.5%">
          <social-button link="google" />
        </c-flex>
        <c-flex w="48.5%">
          <social-button link="facebook" />
        </c-flex>
      </c-flex>
      <c-flex direction="column" class="auth-form-container">
        <c-input
          v-model="firstName"
          :placeholder="'First Name*'"
          type="text"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isFirstNameValid"
          error-border-color="red.300"
        />
        <error :error="'Please enter a first name'" :show="!isFirstNameValid" />
        <c-input
          v-model="lastName"
          :placeholder="'Last Name*'"
          type="text"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isLastNameValid"
          error-border-color="red.300"
        />
        <error :error="'Please enter a last name'" :show="!isLastNameValid" />

        <c-input
          v-model="email"
          :placeholder="'Email Address*'"
          type="email"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isEmailValid"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a valid email address'"
          :show="!isEmailValid"
        />

        <c-input
          v-model="password"
          :placeholder="'Password*'"
          type="password"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isPasswordValid"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a valid password'"
          :show="!isPasswordValid"
        />
        <c-input
          v-model="confirmPassword"
          :placeholder="'Confirm Password*'"
          type="password"
          variant="flushed"
          class="auth-input"
          :is-invalid="!isValidConfirmPassword"
          error-border-color="red.300"
        />
        <error
          :error="'Please enter a re-enter the password entered above'"
          :show="!isValidConfirmPassword"
        />
      </c-flex>
      <c-flex class="auth-button-container">
        <custom-button
          text="Create Account"
          :click="handleSignup"
          :isLoading="isSigningUp"
          :disabled="!isFormValid"
        />
      </c-flex>
      <!-- <c-text>
        By clicking create account I agree that I have read and accepted the
        Terms of Use and Privacy Policy
      </c-text> -->
    </c-flex>
  </c-flex>
</template>

<script>
import { CFlex, CHeading } from "@chakra-ui/vue";
import CustomButton from "./CustomButton.vue";
import { mapGetters } from "vuex";
import Error from "./Error.vue";
import { validateEmail } from "../../helper";
import { isMobile } from "mobile-device-detect";
import SocialButton from "./SocialButton.vue";

export default {
  name: "SignupForm",
  data: function () {
    return {
      email: "",
      password: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      isMobile,
    };
  },
  computed: {
    ...mapGetters(["isSigningUp"]),
    isFirstNameValid() {
      return this.firstName.length > 0;
    },
    isLastNameValid() {
      return this.lastName.length > 0;
    },
    isEmailValid() {
      return this.email.length > 0 && validateEmail(this.email);
    },
    isPasswordValid() {
      return this.password.length > 0;
    },
    isValidConfirmPassword() {
      return (
        this.confirmPassword.length > 0 &&
        this.confirmPassword === this.password
      );
    },
    isFormValid() {
      return (
        this.isFirstNameValid &&
        this.isLastNameValid &&
        this.isEmailValid &&
        this.isPasswordValid &&
        this.isValidConfirmPassword
      );
    },
  },
  components: {
    CFlex,
    CHeading,
    CustomButton,
    Error,
    SocialButton,
  },

  created() {
    this.$store.dispatch("clearSignupReducer");
  },
  methods: {
    handleSignup() {
      this.$store.dispatch("handleSignup", [
        this.email,
        this.password,
        this.firstName,
        this.lastName,
        this.phone,
      ]);
    },
  },
};
</script>
