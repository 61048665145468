var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"direction":"column","pb":"0.5vw","pr":_vm.pr || '0',"pl":_vm.pl || '0',"w":"100%"}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mt":_vm.isMobile ? '0.65vw' : '0.5vw',"mb":_vm.isMobile ? '1.5vw' : '0.5vw'}},[(_vm.isMobile && _vm.verse !== 'all')?_c('c-text',{attrs:{"fontWeight":"semibold","py":"0.5vw","direction":_vm.direction}},[_vm._v(" "+_vm._s(_vm.verse !== "all" ? ("Verse " + _vm.verse) : "")+" ")]):_vm._e(),_c('c-text',{attrs:{"as":"u","fontWeight":"semibold","textTransform":"uppercase"}},[_vm._v(" "+_vm._s(this.languageLabel)+" ")])],1),(!_vm.verseGroupList || _vm.verseGroupList.length === 0)?_c('c-text',{attrs:{"direction":_vm.direction,"textAlign":"justify","textJustify":"inter-word","lineHeight":((Number(_vm.fontSize.substring(0, 2) + (_vm.isMobile ? 1 : 1)) *
      (_vm.isMobile ? 1.15 : 1.25)) + "%")}},_vm._l((this.verses),function(verse,index){return _c('span',{key:index + verse[_vm.key],attrs:{"direction":_vm.direction}},[(_vm.direction !== 'rtl')?_c('sup',{staticStyle:{"margin-right":"0.1vw"},style:({
          'font-size': '90%',
        })},[_vm._v(" "+_vm._s(verse.id)+" ")]):_vm._e(),_c('span',{class:_vm.languageLabel,style:({
          'font-size':
            _vm.languageKey === 'parallel_text' || _vm.languageLabel !== 'en'
              ? ((Number(_vm.fontSize.substring(0, 2)) + 7) + "px")
              : _vm.fontSize,
        })},[_vm._v(" "+_vm._s(verse[_vm.key])+" ")]),(_vm.direction === 'rtl')?_c('sup',{staticStyle:{"margin-left":"0.1vw"},style:({
          'font-size': '90%',
        })},[_vm._v(" "+_vm._s(verse.id)+" ")]):_vm._e(),((_vm.verseGroupList || []).includes(index))?_c('div',{staticStyle:{"margin-bottom":"1vw"}}):_vm._e()])}),0):_c('c-box',_vm._l((_vm.verseGroupList),function(verseGroup,index){return _c('c-box',{key:verseGroup,class:_vm.mouseOver === index && _vm.verseCommentary
          ? "verse-group-content-hover"
          : '',staticStyle:{"position":"relative"},attrs:{"mb":index === _vm.verseGroupList.length - 1 ? 0 : '45px'},on:{"mouseover":function($event){return _vm.onMouseOver(index)},"mouseout":function($event){return _vm.onMouseOut()},"click":function($event){return _vm.onClick(index)}}},[(_vm.mouseOver === index && _vm.verseCommentary)?_c('c-flex',{staticStyle:{"border-top-left-radius":"1vw","border-top-right-radius":"1vw"},attrs:{"position":"absolute","right":"0","color":"white","background":"linear-gradient(#348dcf, #27367b)","top":_vm.isMobile ? '-5.5vw' : '-2vw',"py":"5px","px":"1vw","fontSize":_vm.isMobile ? '75%' : '85%',"align":"center","h":_vm.isMobile ? '5.5vw' : '2vw'}},[_c('c-image',{attrs:{"src":require('@/assets/icons/commentary-hover.png'),"w":_vm.isMobile ? '2.75vw' : '1.25vw',"h":_vm.isMobile ? '2.75vw' : '1.25vw',"mr":_vm.isMobile ? '2vw' : '1vw',"objectFit":"cover"}}),_vm._v(" Read Commentary ")],1):_vm._e(),_c('c-text',{attrs:{"textAlign":"justify","lineHeight":((Number(_vm.fontSize.substring(0, 2) + (_vm.isMobile ? 1 : 1)) *
          (_vm.isMobile ? 1.15 : 1.25)) + "%"),"direction":_vm.direction},domProps:{"innerHTML":_vm._s(
          _vm.verses
            .slice(
              index === 0 ? 0 : _vm.verseGroupList[index - 1],
              _vm.verseGroupList[index]
            )
            .map(
              function (el) { return ("<sup style='font-size:85%;margin-right:0.15vw;margin-left:0.15vw;'>" + (el.id) + "</sup><span class='" + _vm.languageLabel + "' style='font-size:" + (_vm.languageLabel !== 'en'
                    ? ((Number(_vm.fontSize.substring(0, 2)) + 7) + "px")
                    : _vm.fontSize) + "'> " + (el.name) + "</span>"); }
            )
            .join(' ')
        )}})],1)}),1),_c('chapter-commentary',{attrs:{"close":_vm.hideCommentaryInFullChapter,"isOpen":_vm.isChapterCommentaryOpen,"commentary":_vm.verseCommentary,"lineHeight":((Number(_vm.fontSize.substring(0, 2) + (_vm.isMobile ? 1 : 1)) *
      (_vm.isMobile ? 1.15 : 1.25)) + "%")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }