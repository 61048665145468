import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=be82c5b4&"
import script from "./Alert.vue?vue&type=script&lang=js&"
export * from "./Alert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CButton: require('@chakra-ui/vue').CButton, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
