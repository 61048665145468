import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=3ab33f1f&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CCloseButton: require('@chakra-ui/vue').CCloseButton, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CAvatar: require('@chakra-ui/vue').CAvatar, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
